import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { getClientById, getCompaniesAndBranchCountByGroup, getGroupById } from "../../lib/usersBEClient";
import { Companies, Group } from "../../types/BaseTypes";
import { getConsultiveGroupById } from "../../lib/governanceClient";

export interface ConsultiveGroup {
   _id: string;
   companies: string[];
   updatedAt: string;
}

export interface ComplaintsGroup {}

interface IClientConfigContext {
   getClientInfo: Function;
   refreshClientInfo: Function;
   clientInfo: Companies;
   isLoading: boolean;
   setClientInfo: Function;
   group: Group;
   setGroup: Function;
   getGroupInfo: Function;
   refreshGroupInfo: Function;
   consultiveGroup: ConsultiveGroup;
   complaintsGroup: any[];
   isEditing: number;
   setIsEditing: Function;
}
export const ClientConfigContext = createContext<IClientConfigContext>({
   clientInfo: null,
   refreshClientInfo: () => {},
   getClientInfo: () => {},
   isLoading: true,
   setClientInfo: () => {},
   group: null,
   getGroupInfo: () => {},
   refreshGroupInfo: () => {},
   setGroup: () => {},
   consultiveGroup: null,
   complaintsGroup: [],
   isEditing: null,
   setIsEditing: () => {},
});

export const ClientConfigProvider = ({ children }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [clientInfo, setClientInfo] = useState<Companies>();
   const [group, setGroup] = useState<Group>();
   const [consultiveGroup, setConsultiveGroup] = useState<ConsultiveGroup>();
   const [complaintsGroup, setComplaintsGroup] = useState([]);
   const [isEditing, setIsEditing] = useState<number>(null);

   async function refreshClientInfo() {
      if (clientInfo._id !== "") {
         const clientInfoResponse = await getClientById(clientInfo._id);
         const clientInfoData = clientInfoResponse.data;
         setClientInfo(clientInfoData);
      }
   }
   async function getClientInfo(clientId: string) {
      setIsLoading(true);
      const clientInfoData = await getClientById(clientId);

      if (!clientInfoData.data.company_details.primaryColor) {
         for (const service of clientInfoData.data.company_details.servicesDetails) {
            if (service.details?.platformId) {
               const color = service.details.platformId.color;
               clientInfoData.data.company_details.primaryColor = color;
            }
         }
         if (!clientInfoData.data.company_details.primaryColor)
            clientInfoData.data.company_details.primaryColor = "#162c44";
      }
      if (!clientInfoData.data.company_details.secondaryColor) {
         clientInfoData.data.company_details.secondaryColor = "#5d6d7c";
      }

      setClientInfo(clientInfoData.data);
      setIsLoading(false);
   }

   async function getGroupInfo(groupId: string) {
      setIsLoading(true);
      const groupInfoResponse = await getGroupById(groupId);
      const groupInfoData = groupInfoResponse;
      setGroup(groupInfoData);
      setIsLoading(false);
   }

   async function refreshGroupInfo() {
      if (group._id !== "") {
         const groupInfoResponse = await getGroupById(group._id);
         setGroup(groupInfoResponse);
      }
   }

   const getConsultiveData = useCallback(async () => {
      if (group) {
         const data = await getConsultiveGroupById(group._id);
         if (data) setConsultiveGroup(data);
      }
   }, [setConsultiveGroup, group]);

   const getComplaintsData = useCallback(async () => {
      if (group) {
         const data = await getCompaniesAndBranchCountByGroup(group._id);
         if (data) setComplaintsGroup(data);
      }
   }, [setComplaintsGroup, group]);

   useEffect(() => {
      getConsultiveData();
      getComplaintsData();
   }, [getConsultiveData, getComplaintsData, group]);

   const values = useMemo(() => {
      return {
         setGroup,
         getClientInfo,
         clientInfo,
         refreshClientInfo,
         isLoading,
         setClientInfo,
         group,
         getGroupInfo,
         refreshGroupInfo,
         consultiveGroup,
         complaintsGroup,
         isEditing,
         setIsEditing,
      };
   }, [clientInfo, isLoading, group, consultiveGroup, complaintsGroup, isEditing]);

   return <ClientConfigContext.Provider value={values}>{children}</ClientConfigContext.Provider>;
};
