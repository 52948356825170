import { useContext } from "react";
import { ClientCreationContext } from "../../../../context/client/clientCreationContext";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

const typeOptions = [
   { value: "0", text: "Seleccionar" },
   { value: "natural", text: "Persona Física" },
   { value: "legal", text: "Persona Moral" },
   { value: "group", text: "Grupo empresarial" },
   { value: "office", text: "Despacho" },
];

const ClientTypeSelection = ({ setClientSelection }) => {
   const { selectedClient, setSelectedClient } = useContext(ClientCreationContext);

   const onChange = (event) => {
      setSelectedClient({ type: event.target.value, data: null });
      setClientSelection(false);
   };

   return (
      <Box sx={{ flex: 1 }}>
         <Box
            sx={{
               border: 1,
               borderColor: "#E0E0E0",
               borderBottomRightRadius: 2,
               borderBottomLeftRadius: 2,
            }}
         >
            <Box
               sx={{
                  p: 4,
                  borderTop: 1,
                  borderColor: "#E0E0E0",
                  display: "flex",
                  columnGap: 2,
                  alignItems: "center",
               }}
            >
               <Typography>Tipo de cliente:</Typography>
               <FormControl sx={{ minWidth: 300 }}>
                  <InputLabel id="type">Seleccionar</InputLabel>
                  <Select labelId="type" id="type" value={selectedClient?.type} label="Seleccionar" onChange={onChange}>
                     {typeOptions.map((option, index) => (
                        <MenuItem
                           key={index}
                           value={option.value}
                           sx={{ display: option.text === "Seleccionar" ? "none" : "block" }}
                        >
                           {option.text}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Box>
         </Box>
      </Box>
   );
};

export default ClientTypeSelection;
