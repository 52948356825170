import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Button, CircularProgress } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";
import { SnackBarContext } from "../../../context/snackBarContext";
import Toolbar from "@mui/material/Toolbar";
import { ConfirmationModal } from "../../ConfirmationModal";
import {
   useDeleteCompanyInvoice,
   UseInvoicesByCompanyId,
   UseLastCompanyInvoice,
   useUpdateInvoice,
} from "../../../lib/usersBEClient";
import { ClientConfigContext } from "../../../context/client/clientConfigContext";
import { modifyInvoice } from "../../../lib/Validations/inputSchemas";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../../lib/s3Client";
import { CustomInputFiles } from "../../Inputs/CustomInputFiles";

interface ModifyInvoiceDrawerProps {
   setOpen: Function;
   invoiceId: string;
   invoiceData: any;
   setRefetch: Function;
   file1: string;
   file2: string;
   file1Name: string;
   file2Name: string;
}

export const ModifyInvoiceDrawer = (props: ModifyInvoiceDrawerProps) => {
   const [uploadedInvoiceBlob1, setUploadedInvoiceBlob1] = React.useState<File>();
   const [uploadedInvoiceBlob2, setUploadedInvoiceBlob2] = React.useState<File>();
   const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = React.useState(false);
   const inputRef = React.useRef(null);
   const { showSnackBar } = React.useContext(SnackBarContext);
   const { clientInfo, group } = React.useContext(ClientConfigContext);
   const { mutate: deleteInvoice, isLoading: deleteLoading } = useDeleteCompanyInvoice();
   const { mutate: updateInvoice, isLoading: updateLoading } = useUpdateInvoice(props.invoiceId);
   const [isLoading, setIsLoading] = useState<boolean>(false);

   const handleUploadFiles = async (values) => {
      const urlInvoice1 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `invoices/${clientInfo ? clientInfo._id : group.invoiceCompany._id}/${
            values.startPeriod.split("-")[0]
         }/${values.startPeriod.split("-")[1]}`,
         name: uploadedInvoiceBlob1.name,
      });
      const urlInvoice2 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `invoices/${clientInfo ? clientInfo._id : group.invoiceCompany._id}/${
            values.startPeriod.split("-")[0]
         }/${values.startPeriod.split("-")[1]}`,
         name: uploadedInvoiceBlob2.name,
      });
      await uploadFileToS3(urlInvoice1.data, uploadedInvoiceBlob1);
      await uploadFileToS3(urlInvoice2.data, uploadedInvoiceBlob2);
   };

   const handleSubmit = async (values) => {
      if (!uploadedInvoiceBlob1 && !uploadedInvoiceBlob2) {
         return showSnackBar("Favor de adjuntar archivos correspondientes", true);
      }
      if (values.startPeriod >= values.endPeriod) {
         showSnackBar("La fecha de inicio no puede ser mayor o igual a la fecha de cierre", true);
         setIsLoading(false);
         return;
      }
      updateInvoice(
         {
            name: values.name,
            startPeriod: values.startPeriod,
            endPeriod: values.endPeriod,
            file1: uploadedInvoiceBlob1.name,
            file2: uploadedInvoiceBlob2.name,
         },
         {
            onSuccess: async () => {
               props.setRefetch(true);
               await handleUploadFiles(values);
               showSnackBar("Los datos se han modificado", false);
               await UseInvoicesByCompanyId(clientInfo ? clientInfo._id : group.invoiceCompany._id);
               await UseLastCompanyInvoice(clientInfo ? clientInfo._id : group.invoiceCompany._id);
               setTimeout(() => {
                  props.setOpen(false);
               }, 1000);
            },
            onError: () => {
               showSnackBar("Error al modificar los datos", true);
               setUploadedInvoiceBlob1(null);
               setUploadedInvoiceBlob2(null);
               inputRef.current.value = null;
            },
         }
      );
   };

   const fetchFiles = async () => {
      setIsLoading(true);
      const file1 = await fetch(props.file1).then(async (data) => {
         const newBlob = await data.blob();
         const filePdf = new File([newBlob], props.file1Name, { type: "application/pdf" });
         return filePdf;
      });
      const file2 = await fetch(props.file2).then(async (data) => {
         const newBlob = await data.blob();
         const fileXml = new File([newBlob], props.file2Name, { type: "text/xml" });
         return fileXml;
      });
      setUploadedInvoiceBlob1(file1);
      setUploadedInvoiceBlob2(file2);
      setIsLoading(false);
   };

   useEffect(() => {
      fetchFiles();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleMasterUserDelete = async () => {
      deleteInvoice(props.invoiceId, {
         onSuccess: async () => {
            showSnackBar("Factura eliminada", false);
            await UseLastCompanyInvoice(clientInfo ? clientInfo._id : group.invoiceCompany._id);
            await UseInvoicesByCompanyId(clientInfo ? clientInfo._id : group.invoiceCompany._id);
            props.setRefetch(true);
            setTimeout(() => {
               setOpenDeleteConfirmationModal(false);
               props.setOpen(false);
            }, 1000);
         },
         onError: () => {
            showSnackBar("Error al eliminar la factura", true);
         },
      });
   };

   return (
      <>
         <Toolbar />
         <Box display="flex" maxWidth={450} width={420} mt={1}>
            <Box
               zIndex={2}
               height="100%"
               position="fixed"
               display="flex"
               alignItems="center"
               onClick={() => props.setOpen(false)}
               sx={{
                  "&:hover": {
                     bgcolor: "#2d435710",
                  },
               }}
            >
               <ChevronRightIcon fontSize="small" />
            </Box>
            <Box flex={1} ml={2.5}>
               <Box px={2} py={1} position="fixed" width="100%" zIndex={1}>
                  <Typography variant="h5">Modificar factura</Typography>
                  <Divider flexItem sx={{ my: 2 }} />
               </Box>
               <Box pr={1} py={1} pl={0.5} mt={8}>
                  <Formik
                     initialValues={{
                        name: props.invoiceData.name,
                        startPeriod: props.invoiceData.startPeriod.split("T")[0],
                        endPeriod: props.invoiceData.endPeriod.split("T")[0],
                     }}
                     onSubmit={async (values) => {
                        await handleSubmit(values);
                     }}
                     validationSchema={modifyInvoice}
                  >
                     {(formProps: FormikProps<any>) => (
                        <Form>
                           <Box m={2}>
                              <InputTextField
                                 variant="standard"
                                 multiline
                                 size="small"
                                 name="name"
                                 id="name"
                                 type="text"
                                 label="Nombre de factura"
                                 fullWidth
                                 value={formProps.values.name}
                              />
                           </Box>
                           <Box m={2}>
                              <Typography>Fecha de inicio</Typography>
                              <InputTextField
                                 fullWidth
                                 variant="standard"
                                 size="small"
                                 name="startPeriod"
                                 id="startPeriod"
                                 type="date"
                                 value={formProps.values.startPeriod}
                              />
                           </Box>
                           <Box m={2}>
                              <Typography>Fecha de cierre</Typography>
                              <InputTextField
                                 fullWidth
                                 variant="standard"
                                 size="small"
                                 name="endPeriod"
                                 id="endPeriod"
                                 type="date"
                                 value={formProps.values.endPeriod}
                              />
                           </Box>
                           <Box m={2}>
                              <Box display="flex" flexDirection={"column"} justifyContent={"flex-start"}>
                                 <Typography>Factura</Typography>
                                 {!isLoading ? (
                                    <CustomInputFiles
                                       file1={uploadedInvoiceBlob1}
                                       setFile1={setUploadedInvoiceBlob1}
                                       file2={uploadedInvoiceBlob2}
                                       setFile2={setUploadedInvoiceBlob2}
                                    />
                                 ) : (
                                    <CircularProgress sx={{ alignSelf: "center" }} />
                                 )}
                              </Box>
                           </Box>
                           <Divider sx={{ mb: 1 }} />
                           <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                              <>
                                 <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                       setOpenDeleteConfirmationModal(true);
                                    }}
                                 >
                                    {!deleteLoading ? "Eliminar" : <CircularProgress size={24} color="success" />}
                                 </Button>
                                 <Button variant="contained" type="submit">
                                    {!updateLoading ? "Guardar" : <CircularProgress size={24} color="success" />}
                                 </Button>
                              </>
                           </Box>
                        </Form>
                     )}
                  </Formik>
               </Box>
            </Box>
         </Box>
         <ConfirmationModal
            open={openDeleteConfirmationModal}
            setOpen={setOpenDeleteConfirmationModal}
            title="Eliminar factura"
            body={
               <Typography>
                  {`¿Estás seguro de eliminar a ${props.invoiceData.name}?`}
                  <Typography>Sus datos serán borrados permanentemente.</Typography>
               </Typography>
            }
            onConfirm={handleMasterUserDelete}
         />
      </>
   );
};
