import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addResolution } from "../../../../lib/lecosyBackendClient";
import {
   Box,
   Button,
   Grid,
   MenuItem,
   Typography,
   CircularProgress,
   InputAdornment,
   Divider,
   Container,
} from "@mui/material";
import RateReviewIcon from "@mui/icons-material/RateReview";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import StoreIcon from "@mui/icons-material/Store";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { complaintResolution } from "../../../../lib/DocmunetsTemplates/AcknowledgmentTemplates";
import { Form, Formik } from "formik";
import {
   complaintResolutionScreenSchema,
   SugestionResolutionScreenSchema,
} from "../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { InputSelectField } from "../../../../components/Inputs/InputSelectField";
import { InputCheckBox } from "../../../../components/Inputs/InputCheckBox";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { toISOString } from "../../../../const/globalConst";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UserContext } from "../../../../context/UserContext";

const clasificationsOptions = [
   "Posible comisión de un hecho delictivo",
   "Violación de derechos humanos",
   "Violación a las políticas internas y códigos de ética de la organización",
   "No es violatoria a ningún hecho contrario a la ley o a las políticas de la organización",
];

export const ComplaintResolutionScreen = () => {
   const { witnesses, complaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);
   const date = new Date();
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);
   const handleSendForm = async (values, actions) => {
      setIsLoading(true);
      const dd = complaintResolution(
         complaint.reportNumber,
         complaint.complainerDetails
            ? complaint.complainerDetails.name + " " + complaint.complainerDetails.lastName
            : `${(complaint.type || "denuncia") === "queja" ? "Quejoso anónimo" : "anónimo"}`,
         complaint.clasification,
         values.offenderFacts,
         values.offenderEvidence,
         values.clasificationComplaint,
         values.fundamentals,
         values.measures,
         values.dateTime,
         values.organization,
         "",
         "",
         "",
         witnesses,
         [],
         complaint?.type || "denuncia",
         values.address
      );
      try {
         await addResolution(complaint.reportNumber, values.measures, values.clasificationComplaint);
         showSnackBar("Datos correctos", false);
         setIsLoading(false);
         actions.resetForm();
      } catch (error) {
         showSnackBar("Hubo un error, favor de volver a intentarlo", true);
         setIsLoading(false);
      }
      const pdfDocGenerator = pdfMake.createPdf(dd);
      pdfDocGenerator.open();
      setTimeout(() => navigate(-1), 1000);
   };
   const validationSchema =
      complaint?.type === "denuncia" ? complaintResolutionScreenSchema : SugestionResolutionScreenSchema;
   return (
      <Box p={2}>
         <TitleAppBar head={`Resolución de la ${complaint?.type || "denuncia"}`} isScheduleAppointment={false} />
         <Container maxWidth="xl">
            <Formik
               initialValues={{
                  offenderFacts: "",
                  organization: "",
                  address: "",
                  offenderEvidence: "",
                  clasificationComplaint: "",
                  fundamentals: "",
                  measures: "",
                  queryBank: false,
                  complainantName: "",
                  dateTime: toISOString(date),
               }}
               validateOnChange={false}
               validationSchema={validationSchema}
               onSubmit={handleSendForm}
            >
               <Form>
                  <Box
                     display={"flex"}
                     sx={{ px: 4, py: 1, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}
                  >
                     <Box
                        sx={{
                           width: "100%",
                           backgroundColor: "#FFFFFF",
                           border: 1,
                           borderRadius: 2,
                           borderColor: "#8A95A0",
                        }}
                     >
                        <Grid container>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={500} variant="body1">
                                    {`Reporte de la ${complaint.type || "denuncia"}`}
                                 </Typography>
                                 <Typography variant="subtitle1">{complaint.reportNumber}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={500} variant="body1" sx={{ textAlign: "center" }}>
                                    {`Nombre del ${
                                       (complaint.type || "denuncia") !== "denuncia" ? "quejoso" : "denunciante"
                                    }`}
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {complaint.complaintType === "anonima"
                                       ? "Anónimo"
                                       : `${complaint.complainerDetails.name} ${complaint.complainerDetails.lastName}`}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    fullWidth
                                    name="dateTime"
                                    id="datetime-local"
                                    label="Fecha y hora"
                                    type="datetime-local"
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                 />
                              </Box>
                           </Grid>
                        </Grid>
                        <Divider sx={{ mx: 2 }} />
                        <Grid container>
                           <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 DATOS DE LA EMPRESA
                              </Typography>
                           </Grid>
                           <Grid item xs={6}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    name="organization"
                                    label="Empresa"
                                    id="organization"
                                    type="text"
                                    fullWidth={true}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <StoreIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                 />
                              </Box>
                           </Grid>
                           <Grid item xs={6}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    name="address"
                                    label="Dirección"
                                    id="address"
                                    type="text"
                                    fullWidth={true}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <LocationOnIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                 />
                              </Box>
                           </Grid>
                        </Grid>
                        <Divider sx={{ mx: 2 }} />
                        <div>
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    RELACIÓN DE LOS HECHOS
                                 </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="offenderFacts"
                                       label="Hechos declarados"
                                       id="offenderFacts"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <RateReviewIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="offenderEvidence"
                                       label="Pruebas"
                                       id="offenderEvidence"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <StickyNote2Icon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    RESOLUCIÓN
                                 </Typography>
                              </Grid>
                              {(complaint.type || "denuncia") === "denuncia" && (
                                 <Grid item xs={4}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputSelectField
                                          labelId="state-select-label"
                                          id="clasification"
                                          name="clasificationComplaint"
                                          label="La denuncia se considera como"
                                          variant="standard"
                                          fullWidth={true}
                                       >
                                          {clasificationsOptions.map((a) => (
                                             <MenuItem value={a} key={a}>
                                                {a}
                                             </MenuItem>
                                          ))}
                                       </InputSelectField>
                                    </Box>
                                 </Grid>
                              )}
                              <Grid
                                 item
                                 xs={(complaint.type || "denuncia") !== "denuncia" ? 9 : 8}
                                 sx={{ margin: (complaint.type || "denuncia") !== "denuncia" ? "0 auto" : "initial" }}
                              >
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="fundamentals"
                                       label="Fundamentando su determinación en razón a los siguientes criterios"
                                       id="fundamentals"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                        </div>
                        <Divider sx={{ mx: 2 }} />
                        <Grid container>
                           <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 MEDIDAS
                              </Typography>
                           </Grid>
                           <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                              <Grid item xs={9}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="measures"
                                       label="Se ordena la imposición de las siguientes medidas"
                                       id="measures"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Box>
                     <Box display={"flex"} sx={{ width: "100%", justifyContent: "flex-end" }}>
                        <Button variant="contained" sx={{ mt: 2 }} type="submit">
                           {isLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                        </Button>
                     </Box>
                  </Box>
               </Form>
            </Formik>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   p: 2,
};
