import * as React from "react";
import Accordion from "@mui/material/Accordion";
import { Box, MenuItem } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputTextField } from "../Inputs/InputTextField";
import { Form, Formik } from "formik";
import { GetReceiptByCompanyId, GetReceiptWithoutFile, useUploadReceipt } from "../../lib/usersBEClient";
import { SnackBarContext } from "../../context/snackBarContext";
import { InputSelectField } from "../Inputs/InputSelectField";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { uploadReceipt } from "../../lib/Validations/inputSchemas";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../lib/s3Client";
import InfoIcon from "@mui/icons-material/Info";
import { CustomInputFiles } from "../Inputs/CustomInputFiles";
import { LoadingButton } from "../LoadingButton";

interface UploadReceiptAccordionProps {
   setRefetch: Function;
   companyId: string;
}

export default function UploadReceiptAccordion(props: UploadReceiptAccordionProps) {
   const [uploadedReceiptBlob1, setUploadedReceiptBlob1] = React.useState<File>();
   const [uploadedReceiptBlob2, setUploadedReceiptBlob2] = React.useState<File>();
   const [currentDate, setCurrentDate] = React.useState<number>();
   const [isLoading, setIsLoading] = React.useState(false);
   const { showSnackBar } = React.useContext(SnackBarContext);
   const [seed, setSeed] = React.useState(1);
   const { clientInfo, group } = React.useContext(ClientConfigContext);
   const {
      data: receiptData,
      isLoading: receiptLoading,
      refetch: receiptRefetch,
   } = GetReceiptWithoutFile(props.companyId);
   const [receiptId, setReceiptId] = React.useState();
   const { mutate: receiptUpload } = useUploadReceipt(receiptId);
   const { refetch } = GetReceiptByCompanyId(clientInfo ? clientInfo._id : group.invoiceCompany._id);

   React.useEffect(() => {
      const date = new Date().getTime();
      setCurrentDate(date);
   }, []);

   const handleUploadFiles = async (values) => {
      const urlReceipt1 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `receipts/${clientInfo ? clientInfo._id : group.invoiceCompany._id}/${
            values.paymentDate.split("-")[0]
         }/${values.paymentDate.split("-")[1]}`,
         name: uploadedReceiptBlob1.name,
      });
      const urlReceipt2 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `receipts/${clientInfo ? clientInfo._id : group.invoiceCompany._id}/${
            values.paymentDate.split("-")[0]
         }/${values.paymentDate.split("-")[1]}`,
         name: uploadedReceiptBlob2.name,
      });
      await uploadFileToS3(urlReceipt1.data, uploadedReceiptBlob1);
      await uploadFileToS3(urlReceipt2.data, uploadedReceiptBlob2);
   };

   const handleSubmit = async (values) => {
      setIsLoading(true);
      if (!uploadedReceiptBlob1 || !uploadedReceiptBlob2) {
         setIsLoading(false);
         return showSnackBar("Favor de adjuntar documentos", true);
      }
      if (new Date(values.paymentDate).getTime() >= currentDate) {
         setIsLoading(false);
         return showSnackBar("La fecha de pago no puede ser igual o mayor al dia actual", true);
      }
      receiptUpload(
         {
            name: values.name,
            paymentDate: values.paymentDate,
            file1: uploadedReceiptBlob1.name,
            file2: uploadedReceiptBlob2.name,
         },
         {
            onSuccess: async () => {
               await handleUploadFiles(values);
               setIsLoading(false);
               showSnackBar("Complemento de pago registrado con éxito", false);
               values.name = "";
               values.paymentPeriod = "";
               values.paymentDate = "";
               setUploadedReceiptBlob1(null);
               setUploadedReceiptBlob2(null);
               setSeed(Math.random());
               receiptRefetch();
               refetch();
            },
            onError: () => {
               setIsLoading(false);
               showSnackBar("Error al registrar el complemento de pago", true);
            },
         }
      );
   };
   return (
      <Box pt={2}>
         <Accordion>
            <AccordionSummary
               sx={{ width: "100%", height: "70px" }}
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography fontWeight={800}>Subir complemento de pago</Typography>
            </AccordionSummary>
            <Formik
               initialValues={{ name: "", paymentDate: "" }}
               onSubmit={handleSubmit}
               validationSchema={uploadReceipt}
            >
               {!receiptLoading && receiptData ? (
                  <Form>
                     <AccordionDetails key={seed}>
                        <Box display={"flex"} alignItems={"baseline"} justifyContent={"space-between"} mx={11}>
                           <InputTextField
                              id="name"
                              name="name"
                              label="Nombre del complemento de pago"
                              type="text"
                              size="small"
                              sx={{ width: "250px", mt: "5px" }}
                           />
                           <InputSelectField
                              label="Seleccionar factura"
                              labelId="paymentPeriod"
                              id="paymentPeriod"
                              fullWidth
                              name="paymentPeriod"
                              variant={"outlined"}
                              disabled={receiptData.length ? false : true}
                              sx={{ width: "250px", height: "40px", mt: "5px" }}
                           >
                              {receiptData.map((receipt, index) => {
                                 return (
                                    <MenuItem key={index} value={receipt._id} onClick={() => setReceiptId(receipt._id)}>
                                       {receipt.paymentPeriod.name}
                                    </MenuItem>
                                 );
                              })}
                           </InputSelectField>

                           <Box display={"flex"} columnGap={1} alignItems={"center"}>
                              <Typography>Fecha de pago</Typography>
                              <InputTextField size="small" name="paymentDate" id="paymentDate" type="date" />
                           </Box>
                        </Box>
                        {receiptData.length > 0 ? (
                           <>
                              <Box display="flex" alignItems="center" mt={4} justifyContent={"center"}>
                                 <CustomInputFiles
                                    file1={uploadedReceiptBlob1}
                                    setFile1={setUploadedReceiptBlob1}
                                    file2={uploadedReceiptBlob2}
                                    setFile2={setUploadedReceiptBlob2}
                                 />
                              </Box>
                           </>
                        ) : (
                           <>
                              <Box
                                 display={"flex"}
                                 flexDirection={"row"}
                                 justifyContent={"center"}
                                 alignItems={"center"}
                                 my={4}
                              >
                                 <InfoIcon sx={{ mr: 1, color: "#16c44" }} />
                                 <Typography>No se encontraron facturas</Typography>
                              </Box>
                           </>
                        )}
                        <Box display={"flex"} justifyContent={"flex-end"} mx={11}>
                           <LoadingButton label={"Guardar"} isLoading={isLoading} />
                        </Box>
                     </AccordionDetails>
                  </Form>
               ) : (
                  <>
                     <Box display={"flex"} justifyContent={"center"} p={6}>
                        <Typography color="initial">No hay facturas disponibles</Typography>
                     </Box>
                  </>
               )}
            </Formik>
         </Accordion>
      </Box>
   );
}
