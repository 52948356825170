import { useContext, useEffect, useMemo, useState } from "react";
import { HeadersProps } from "../../components/ListComponent";
import { getChargeByGovernUsers, getGoberningBodiesByCompany } from "../../lib/governanceClient";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { getUserDataGB } from "../../lib/usersBEClient";

export interface SeriesInterface {
   title: string;
   nominalValue: number;
}

const useGetMembersInfo = (hideFullInfo: boolean) => {
   const [members, setMembers] = useState([]);
   const [governanceBody, setGovernanceBody] = useState<any>(null);
   const [series, setSeries] = useState<SeriesInterface[]>([]);
   const { clientInfo } = useContext(ClientConfigContext);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getGovernance = async () => {
         const response = await getGoberningBodiesByCompany(clientInfo._id);
         if (!response) return
         const body = response.find((body) => body.title === "Asamblea de accionistas");
         if (body) {
            const users = body.users;
            const ids = users.map((e) => e.user);
            const chargesIds = users.map((e) => e.charge);
            const cleanArray = ids.filter((a, b) => ids.indexOf(a) === b);
            const chargeData = await getChargeByGovernUsers(chargesIds);
            const userData = await getUserDataGB(cleanArray);

            let completePorfile = [];
            for (const user of users) {
               completePorfile.push({
                  ...chargeData.find((e) => e._id === user.charge),
                  ...userData.find((e) => e._id === user.user),
                  ...user,
               });
            }

            let usersData = [];
            for (const element of body.users) {
               const elementUser = element._id;
               usersData.push({
                  ...completePorfile.find((e) => e._id === elementUser),
               });
            }

            setMembers(usersData.filter((user) => user.chargeName === "Accionista"));
            setGovernanceBody(body);
            setSeries(
               body.series.sort((a, b) => {
                  let titleA = a.title.toLowerCase();
                  let titleB = b.title.toLowerCase();
                  if (titleA < titleB) {
                     return -1;
                  }
                  if (titleA > titleB) {
                     return 1;
                  }
                  return 0;
               })
            );
         }
      };
      if (clientInfo?._id) getGovernance();
   }, [clientInfo]);

   const formatedMembers = useMemo(() => {
      if (governanceBody) {
         let totalCompanyCapital = 0;
         members.map((member) => {
            //sum of all member´s capitals
            return (totalCompanyCapital =
               totalCompanyCapital +
               series.reduce((sum, serie) => {
                  const action = member.actions.find((action) => action.title === serie.title);
                  if (action) {
                     return sum + serie.nominalValue * action.sharesAmount;
                  }
                  return sum;
               }, 0));
         });
         if (members.length === 0) {
            setIsLoading(false);
            return [];
         }
         return members.map((member) => {
            const actions = series.map((serie) => {
               const action = member.actions.find((action) => action.title === serie.title);
               return {
                  [serie.title]: action ? action.sharesAmount : 0,
               };
            });
            const mergedActions = !hideFullInfo && Object.assign({}, ...actions);
            const totalCapital = series.reduce((sum, serie) => {
               const action = member.actions.find((action) => action.title === serie.title);
               if (action) {
                  return sum + serie.nominalValue * action.sharesAmount;
               }
               return sum;
            }, 0);
            setIsLoading(false);

            return {
               name: `${member.firstName} ${member.lastName}`,
               founder: member.founder,
               propertyPercentage: !totalCapital ? 0 : ((totalCapital * 100) / totalCompanyCapital).toFixed(1) ?? 0,
               ...mergedActions,
               totalActions: actions.reduce((sum, action) => sum + Object.values(action)[0], 0),
               socialCapital: totalCapital,
               votes: member.actions.reduce((sum, action) => sum + action.votes, 0) || 0,
            };
         });
      }
      setIsLoading(false);

      //eslint-disable-next-line
   }, [governanceBody, members, series]);

   const seriesheaders: HeadersProps[] = useMemo(() => {
      const seriesHeaders: HeadersProps[] =
         (!hideFullInfo &&
            series.map((serie) => {
               return {
                  headerTitle: `Acciones ${serie.title}`,
                  headerPosition: "center",
                  bodyPosition: "center",
                  type: "number",
               };
            })) ||
         [];

      return [
         {
            headerTitle: "Socio / accionista",
            headerPosition: "center",
            bodyPosition: "left",
            type: "text",
         },
         {
            headerTitle: "Fundadores",
            headerPosition: "center",
            bodyPosition: "center",
            type: "checkIcon",
         },
         {
            headerTitle: "Porcentaje de propiedad",
            headerPosition: "center",
            bodyPosition: "center",
            type: "percentage",
         },
         ...seriesHeaders,
         {
            headerTitle: "Total acciones",
            headerPosition: "center",
            bodyPosition: "center",
            type: "number",
         },
         {
            headerTitle: "Capital social",
            headerPosition: "center",
            bodyPosition: "center",
            type: "currency",
         },
         {
            headerTitle: "Número de votos",
            headerPosition: "center",
            bodyPosition: "center",
            type: "number",
         },
      ];
   }, [series]);

   return { formatedMembers, members, seriesheaders, series, isLoading };
};

export default useGetMembersInfo;
