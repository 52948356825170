import { useContext, useState } from "react";
import { Box, Grid, Typography, Button, Drawer, Divider, Popover } from "@mui/material";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { ModifyClientInvoiceInfoDrawer } from "./Drawer/ModifyClientInvoiceInfoDrawer";
import { getDateFromISODate } from "../../const/globalConst";
import { UserContext } from "../../context/UserContext";
import { Info } from "@mui/icons-material";

export const InvoiceGroupInfoComponent = () => {
   const { group } = useContext(ClientConfigContext);
   const [openEditInvoiceDrawer, setOpenEditInvoiceDrawer] = useState(false);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Clientes");
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);

   return (
      <>
         <Box bgcolor="white" sx={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }} boxShadow={2} p={1}>
            {group.invoiceCompany ? (
               <Box m={1} display={"flex"} flexDirection={"column"}>
                  <Grid container rowSpacing={4} columnSpacing={6} px={1}>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>RFC:</Typography>
                        <Typography>
                           {group.invoiceCompany.invoice_details.rfc
                              ? group.invoiceCompany.invoice_details.rfc
                              : "No contiene datos"}
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>Denominación o razón social:</Typography>
                        <Typography>
                           {group.invoiceCompany.invoice_details.denomination
                              ? group.invoiceCompany.invoice_details.denomination
                              : "No contiene datos"}
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>Avenida o calle y número:</Typography>
                        <Typography>
                           {group.invoiceCompany.invoice_details.address
                              ? group.invoiceCompany.invoice_details.address
                              : "No contiene datos"}
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>Colonia:</Typography>
                        <Typography>
                           {group.invoiceCompany.invoice_details.suburb
                              ? group.invoiceCompany.invoice_details.suburb
                              : "No contiene datos"}
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>Correo electrónico:</Typography>
                        {group.invoiceCompany.invoice_details.email ? (
                           group.invoiceCompany.invoice_details.email.length > 25 ? (
                              <>
                                 <Typography
                                    noWrap
                                    aria-owns={open ? "mouse-over-popover" : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                 >
                                    {group.invoiceCompany.invoice_details.email
                                       ? group.invoiceCompany.invoice_details.email
                                       : "No contiene datos"}
                                 </Typography>
                                 <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                       pointerEvents: "none",
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                       vertical: "bottom",
                                       horizontal: "left",
                                    }}
                                    transformOrigin={{
                                       vertical: "top",
                                       horizontal: "left",
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                 >
                                    <Typography sx={{ p: 1 }}>{group.invoiceCompany.invoice_details.email}</Typography>
                                 </Popover>
                              </>
                           ) : (
                              <Typography>{group.invoiceCompany.invoice_details.email}</Typography>
                           )
                        ) : (
                           <Typography noWrap>No cuenta con correo</Typography>
                        )}
                     </Grid>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>Teléfono:</Typography>
                        <Typography>
                           {group.invoiceCompany.invoice_details.phone
                              ? group.invoiceCompany.invoice_details.phone
                              : "No contiene datos"}
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>Código postal:</Typography>
                        <Typography>
                           {group.invoiceCompany.invoice_details.zipCode
                              ? group.invoiceCompany.invoice_details.zipCode
                              : "No contiene datos"}
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>Régimen fiscal:</Typography>
                        <Typography>
                           {group.invoiceCompany.invoice_details.regime
                              ? group.invoiceCompany.invoice_details.regime
                              : "No contiene datos"}
                        </Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <Typography fontWeight={600}>Monto a facturar:</Typography>
                        <Typography>
                           {group.invoiceCompany.invoice_details.amount
                              ? `$${group.invoiceCompany.invoice_details.amount} antes de iva`
                              : "No contiene datos"}
                        </Typography>
                     </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                     <Typography fontSize={14}>
                        Ultima modificación:
                        {group.invoiceCompany.invoice_details.updatedAt
                           ? getDateFromISODate(group.invoiceCompany.invoice_details.updatedAt)
                           : null}
                     </Typography>
                     {!access ? (
                        <Button
                           variant="contained"
                           onClick={() => {
                              setOpenEditInvoiceDrawer(true);
                           }}
                        >
                           Modificar información
                        </Button>
                     ) : null}
                  </Box>
               </Box>
            ) : (
               <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
                  <Info sx={{ fontSize: 60, color: "gray" }} />
                  <Typography sx={{ color: "gray" }}>Emisión de facturas de forma individual</Typography>
               </Box>
            )}
         </Box>
         <Drawer
            anchor="right"
            open={openEditInvoiceDrawer}
            hideBackdrop
            onClose={() => {
               setOpenEditInvoiceDrawer(false);
            }}
            sx={{ maxWidth: 450 }}
         >
            {openEditInvoiceDrawer ? (
               <ModifyClientInvoiceInfoDrawer
                  setOpen={setOpenEditInvoiceDrawer}
                  open={openEditInvoiceDrawer}
                  clientInfo={group.invoiceCompany.invoice_details}
                  group
               />
            ) : (
               <></>
            )}
         </Drawer>
      </>
   );
};
