import { useCallback, useContext, useEffect, useState } from "react";
import { getManyCompaniesById, GetManyGroupsById, updateOfficeLicences } from "../../../lib/usersBEClient";
import { Companies } from "../../../types/BaseTypes";
import { ClientConfigContext } from "../../../context/client/clientConfigContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { Box, CircularProgress, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "../../LoadingButton";
import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";

const OfficeInfoTab = () => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { clientInfo } = useContext(ClientConfigContext);
   const [isLoading, setIsLoading] = useState(false);

   const selectedCompany = clientInfo;
   const [totalLicenses, setTotalLicenses] = useState<number>(selectedCompany?.company_details?.office?.userLimit || 5);
   const [licensesUsed, setLicensesUsed] = useState<number>(0);
   const [officeClientCount, setOfficeClientCount] = useState(null);

   const loadInformation = useCallback(async () => {
      setIsLoading(true);
      setTotalLicenses(selectedCompany?.company_details?.office?.userLimit || 5);
      const adminCount = selectedCompany?.company_details?.admin ? 1 : 0;
      const userCount = selectedCompany?.company_details?.users?.length || 0;

      let clientCount: number = 0;
      const clientCompaniesIds = selectedCompany?.company_details?.office?.companies || [];
      if (clientCompaniesIds.length > 0) {
         const companiesFound: Companies[] = await getManyCompaniesById(clientCompaniesIds);
         for (const company of companiesFound) {
            const clientUserCount = company?.company_details?.users?.length;
            const adminClientCount = company?.company_details?.admin ? 1 : 0;
            clientCount += clientUserCount + adminClientCount;
         }
      }

      setLicensesUsed(adminCount + userCount + clientCount);

      const [officeCompanies, officeGroups] = await Promise.all([
         getManyCompaniesById(selectedCompany?.company_details?.office?.companies),
         GetManyGroupsById(selectedCompany?.company_details?.office?.companies),
      ]);
      setOfficeClientCount({ companies: officeCompanies?.length, groups: officeGroups?.length });

      setIsLoading(false);
   }, [selectedCompany]);

   useEffect(() => {
      loadInformation();
   }, [loadInformation]);

   const handleSubmit = async () => {
      try {
         await updateOfficeLicences(selectedCompany._id, totalLicenses);
         showSnackBar("Licencias actualizadas correctamente", false);
      } catch (error) {
         showSnackBar("Error al actualizar licencias", false);
      }
   };

   return (
      <Box bgcolor="white" sx={{ borderRadius: 1, px: 2, py: 1 }} boxShadow={2}>
         {isLoading ? (
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", my: 2 }}>
               <CircularProgress />
            </Box>
         ) : (
            <>
               <Box display={"flex"} flexDirection={"column"} sx={{ mx: 4, my: 2, width: "100%" }}>
                  <Grid container px={1}>
                     <Grid item xs={6}>
                        <Typography fontWeight={600}>Empresas registradas:</Typography>
                        <Typography>{officeClientCount?.companies || 0}</Typography>
                     </Grid>
                     <Grid item xs={6}>
                        <Typography fontWeight={600}>Grupos registrados:</Typography>
                        <Typography>{officeClientCount?.groups || 0}</Typography>
                     </Grid>
                     {/* <Grid item xs={4}>
                        <Typography fontWeight={600}>Clientes activos:</Typography>
                        <Typography>4</Typography>
                     </Grid> */}
                  </Grid>
               </Box>
               <Divider sx={{ my: 4, mx: 2 }} />
               <Stack
                  direction={"row"}
                  sx={{
                     bgcolor: "#EFEFEF",
                     borderRadius: 2,
                     mx: 2,
                     px: 2,
                     py: 1,
                     my: 1,
                     justifyContent: "space-between",
                  }}
               >
                  <Stack>
                     <Typography fontSize={14} fontWeight={600}>
                        Licencias en uso
                     </Typography>
                     <Typography fontSize={14} textAlign={"center"}>
                        {licensesUsed}
                     </Typography>
                  </Stack>
                  <Stack>
                     <Typography fontSize={14} fontWeight={600}>
                        Licencias totales
                     </Typography>
                     <Typography fontSize={14} textAlign={"center"}>
                        {totalLicenses}
                     </Typography>
                  </Stack>
                  <Stack>
                     <Typography fontSize={14} fontWeight={600}>
                        Licencias Extra
                     </Typography>
                     <Typography fontSize={14} textAlign={"center"}>
                        <IconButton
                           size="small"
                           onClick={() => {
                              if (totalLicenses > 5) setTotalLicenses(totalLicenses - 1);
                           }}
                        >
                           <RemoveCircleRounded sx={{ color: "#162c44" }} />
                        </IconButton>
                        <IconButton
                           size="small"
                           onClick={() => {
                              setTotalLicenses(totalLicenses + 1);
                           }}
                        >
                           <AddCircleRounded sx={{ color: "#162c44" }} />
                        </IconButton>
                     </Typography>
                  </Stack>
               </Stack>

               <Box sx={{ mt: 2, mx: 2, display: "flex", justifyContent: "flex-end" }}>
                  <LoadingButton onClick={handleSubmit} label={"Guardar"} isLoading={false} />
               </Box>
            </>
         )}
      </Box>
   );
};

export default OfficeInfoTab;
