import { Box, Typography, Button, InputAdornment, Stack } from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { useContext, useEffect } from "react";
import { InvoiceDetails } from "../../../../../types/BaseTypes";
import { ClientConfigContext } from "../../../../../context/client/clientConfigContext";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { CreateInvoiceDetails, UpdateInvoiceDetails } from "../../../../../lib/usersBEClient";
import { addInvoiceDetailsSchema } from "../../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { LoadingButton } from "../../../../LoadingButton";

interface AdminDetailsComponentProps {
   handleCancel: Function;
   setValue: Function;
   invoiceDetails?: InvoiceDetails;
   changeAmount?: Function;
   amountForGroup?: number;
   setSelectedClient?: Function;
}

export const InvoicesDetailsComponentOld = (props: AdminDetailsComponentProps) => {
   const { clientInfo, isLoading, refreshClientInfo, group } = useContext(ClientConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate, isLoading: isLoadingCreate } = CreateInvoiceDetails();
   const { mutate: updateMutate, isLoading: isLoadingUpdate } = UpdateInvoiceDetails();
   const handleSubmit = async (values) => {
      try {
         if (clientInfo.invoice_details) {
            updateMutate(
               {
                  invoice_details: {
                     denomination: values.denomination,
                     rfc: values.rfc,
                     city: values.city,
                     state: values.state,
                     address: values.address,
                     suburb: values.suburb,
                     phone: values.phone,
                     zipCode: values.zipCode,
                     regime: values.regime,
                     amount: parseFloat(values.amount).toFixed(2).toString(),
                     email: values.email,
                  },
                  id: clientInfo.invoice_details._id,
               },
               {
                  onError: (error: any) => {
                     showSnackBar("Error al actualizar detalles de facturación", true);
                  },
                  onSuccess: async () => {
                     showSnackBar("Los detalles de facturación fueron actualizados correctamente.", false);
                     props.setValue(3);
                     await refreshClientInfo();
                  },
               }
            );
         } else {
            mutate(
               {
                  invoice_details: {
                     denomination: values.denomination,
                     rfc: values.rfc,
                     city: values.city,
                     state: values.state,
                     address: values.address,
                     suburb: values.suburb,
                     phone: values.phone,
                     zipCode: values.zipCode,
                     regime: values.regime,
                     amount: parseFloat(values.amount).toFixed(2).toString(),
                     email: values.email,
                  },
                  companyId: clientInfo._id,
               },
               {
                  onError: (error: any) => {
                     showSnackBar("Error al agregar detalles de facturación", true);
                  },
                  onSuccess: async () => {
                     showSnackBar("Los detalles de facturación fueron agregados correctamente.", false);
                     props.setValue(3);
                     await refreshClientInfo();
                  },
               }
            );
         }
      } catch (error) {
         console.log({ error });
         return;
      }
   };

   const FormObserver: React.FC = () => {
      const { values } = useFormikContext();
      useEffect(() => {
         const valuess: any = values;
         if (props.changeAmount) {
            props.changeAmount(valuess.amount);
         }
      }, [values]);

      return null;
   };

   const initialValuesFormik = () => {
      if (props.invoiceDetails) {
         if (props.amountForGroup && props.amountForGroup > 0) {
            return {
               ...props.invoiceDetails,
               amount: props.amountForGroup,
            };
         } else {
            return {
               ...props.invoiceDetails,
            };
         }
      } else {
         return {
            denomination:
               !isLoading && clientInfo?.person_details.businessName ? clientInfo.person_details.businessName : "",
            rfc: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.rfc : "",
            address: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.address : "",
            suburb: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.suburb : "",
            phone: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.phone : "",
            regime: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.regime : "",
            zipCode: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.zipCode : "",
            state: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.state : "",
            city: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.city : "",
            amount:
               !isLoading && clientInfo?.invoice_details && !props.amountForGroup
                  ? parseFloat(clientInfo.invoice_details.amount).toFixed(2).toString()
                  : "",
            email: !isLoading && clientInfo?.invoice_details ? clientInfo.invoice_details.email : "",
         };
      }
   };
   return (
      <Box flex={1}>
         <Formik
            initialValues={initialValuesFormik()}
            validationSchema={addInvoiceDetailsSchema}
            onSubmit={handleSubmit}
         >
            {(propsFormik) => (
               <Form>
                  <FormObserver />
                  <Box sx={{ border: !props.amountForGroup ? 1 : 0, borderColor: "#E0E0E0", borderRadius: 2, flex: 1 }}>
                     {!props.amountForGroup ? (
                        <Box sx={{ p: 2 }}>
                           <Typography fontWeight={600} variant="h6">
                              Registro de Información para facturación
                           </Typography>
                        </Box>
                     ) : null}
                     <Box
                        sx={{
                           p: 4,
                           borderTop: 1,
                           borderColor: "#E0E0E0",
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                           maxHeight: "420px",
                           overflowY: props.amountForGroup ? "hidden" : "auto",
                        }}
                     >
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={
                                 props.invoiceDetails
                                    ? props.invoiceDetails.denomination
                                    : propsFormik.values.denomination
                              }
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="denomination"
                              id="denomination"
                              type="text"
                              disabled
                              label="Denominación o razón social"
                              fullWidth={true}
                           />
                           <InputTextField
                              value={props.invoiceDetails ? props.invoiceDetails.rfc : propsFormik.values.rfc}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="rfc"
                              id="rfc"
                              type="text"
                              label="RFC"
                              disabled={!!props.invoiceDetails}
                              fullWidth={true}
                           />
                        </Box>
                        <InputTextField
                           value={props.invoiceDetails ? props.invoiceDetails.email : propsFormik.values.email}
                           sx={{ my: 0.5, flex: 1 }}
                           variant="outlined"
                           size="small"
                           name="email"
                           id="email"
                           type="text"
                           disabled={!!props.invoiceDetails}
                           label="Correo electrónico"
                        />
                        {!props.amountForGroup ? (
                           <Typography fontWeight={600} variant="h6">
                              Domicilio fiscal
                           </Typography>
                        ) : null}
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={props.invoiceDetails ? props.invoiceDetails.city : propsFormik.values.city}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="city"
                              id="city"
                              type="text"
                              disabled={!!props.invoiceDetails}
                              label="Ciudad"
                              fullWidth={true}
                           />
                           <InputTextField
                              value={props.invoiceDetails ? props.invoiceDetails.state : propsFormik.values.state}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="state"
                              id="state"
                              type="text"
                              disabled={!!props.invoiceDetails}
                              label="Estado"
                              fullWidth={true}
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={props.invoiceDetails ? props.invoiceDetails.address : propsFormik.values.address}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="address"
                              id="address"
                              type="text"
                              disabled={!!props.invoiceDetails}
                              label="Calle/Avenida"
                              fullWidth={true}
                           />
                           <InputTextField
                              value={props.invoiceDetails ? props.invoiceDetails.suburb : propsFormik.values.suburb}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="suburb"
                              id="suburb"
                              type="text"
                              disabled={!!props.invoiceDetails}
                              label="Colonia"
                              fullWidth={true}
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={props.invoiceDetails ? props.invoiceDetails.phone : propsFormik.values.phone}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="phone"
                              id="phone"
                              type="text"
                              disabled={!!props.invoiceDetails}
                              label="Teléfono"
                              fullWidth={true}
                           />
                           <InputTextField
                              value={props.invoiceDetails ? props.invoiceDetails.zipCode : propsFormik.values.zipCode}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="zipCode"
                              id="zipCode"
                              type="text"
                              disabled={!!props.invoiceDetails}
                              label="Código postal"
                              fullWidth={true}
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={props.invoiceDetails ? props.invoiceDetails.regime : propsFormik.values.regime}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="regime"
                              id="regime"
                              disabled={!!props.invoiceDetails}
                              type="text"
                              label="Régimen fiscal"
                              fullWidth={true}
                           />
                           <Stack direction={"row"} display={"flex"} width={"100%"}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="standard"
                                 size="small"
                                 name="amount"
                                 id="amount"
                                 type="text"
                                 label="Monto a facturar"
                                 startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              />
                              <Typography variant="body2" alignSelf={"baseline"} width={"40%"} mx={2} marginTop={3}>
                                 antes de I.V.A.
                              </Typography>
                           </Stack>
                        </Box>
                     </Box>
                  </Box>
                  <Box>
                     {props.invoiceDetails ? null : (
                        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                           <Button
                              sx={{ height: "100%" }}
                              variant="outlined"
                              onClick={() => {
                                 group ? props.handleCancel() : props.setSelectedClient(0);
                              }}
                           >
                              Cancelar
                           </Button>
                           <LoadingButton
                              label={clientInfo?.invoice_details ? "Guardar cambios" : "Continuar"}
                              isLoading={isLoadingUpdate || isLoadingCreate}
                           />
                        </Box>
                     )}{" "}
                  </Box>
               </Form>
            )}
         </Formik>
      </Box>
   );
};
