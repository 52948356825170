import React, { useContext, useEffect, useState } from "react";
import {
   addUserRole,
   addUserRoleGroup,
   GetAllUserRoles,
   getImplementationUsers,
   removeRoleFromUser,
   removeUserFromCompany,
   removeUserFromGroup,
   useCreateUser,
} from "../../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { ClientConfigContext } from "../../../../../context/client/clientConfigContext";
import { Box, Button, IconButton, ListSubheader, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { HeadCell, TableComponent } from "../../../../TableComponent";
import { LoadingButton } from "../../../../LoadingButton";
import { createUserSchema } from "../../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { ConfirmationModal } from "../../../../ConfirmationModal";
import { Clear, Delete } from "@mui/icons-material";
import _ from "lodash";

const GroupCoordinatorComponent = ({ beneficiary = false }) => {
   const { mutate, isLoading: isLoadingCreate } = useCreateUser();
   const { showSnackBar } = useContext(SnackBarContext);
   const { data: roles } = GetAllUserRoles();
   const { refreshGroupInfo } = useContext(ClientConfigContext);
   const [selectedUser, setSelectedUser] = useState("");
   const [isLoadingAdd, setIsLoadingAdd] = useState(false);
   const [createMode, setCreateMode] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState(false);
   const [userToDelete, setUserToDelete] = useState("");

   const roleName = beneficiary ? "Coordinador de beneficiario controlador" : "Coordinador de gobierno corporativo";

   const UsefindCoordinatorsUser = () => {
      const { group } = useContext(ClientConfigContext);
      const [isLoadingUser, setIsLoadingUser] = useState(false);
      const [implementationUsers, setImplementationUsers] = useState([]);
      const [groupUsers, setGroupUsers] = useState([]);
      const [users, setUsers] = useState([]);

      useEffect(() => {
         const getUsers = async () => {
            setIsLoadingUser(true);

            // Obtener los usuarios de implementación
            const implementationUsersData = await getImplementationUsers();
            setImplementationUsers(implementationUsersData);

            // Procesar y establecer los usuarios del grupo
            let allUsers: any[] = group?.users || [];
            if (group?.admin && !allUsers.includes(group.admin)) {
               allUsers = [group.admin, ...allUsers];
            }
            const uniqueUsers = _.uniqBy(allUsers, "_id");
            setGroupUsers(uniqueUsers);

            // Filtrar y mapear coordinadores
            const coordinatorUsers = uniqueUsers
               .filter((user) => user?.role?.some((role) => role.group === group._id))
               .map((user) => {
                  const isCoordinator = user.role.some(
                     (role) => role.group === group._id && role.roles.some((r) => r.name === roleName)
                  );
                  return isCoordinator
                     ? {
                          uid: user.uid,
                          id: user._id,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          phoneNumber: user.phoneNumber,
                          email: user.email,
                          delete: (
                             <IconButton
                                onClick={(e) => {
                                   e.stopPropagation();
                                   setUserToDelete(user._id);
                                   setOpenConfirmation(true);
                                }}
                             >
                                <Delete sx={{ color: "#162c44" }} />
                             </IconButton>
                          ),
                       }
                     : null;
               })
               .filter(Boolean);
            setUsers(coordinatorUsers);
            setIsLoadingUser(false);
         };
         if (group?.users) getUsers();
      }, [group]);
      return { implementationUsers, users, groupUsers, isLoadingUser };
   };

   const { users, isLoadingUser, groupUsers, implementationUsers } = UsefindCoordinatorsUser();
   const { group } = useContext(ClientConfigContext);

   const handleRemove = async () => {
      if (!userToDelete) return showSnackBar("Error al eliminar usuario.", true);
      setIsLoadingAdd(true);
      try {
         const index = implementationUsers.findIndex((u) => u._id.toString() === userToDelete);
         if (index >= 0) await removeUserFromGroup(userToDelete, group._id, roles.find((r) => r.name === roleName)._id);
         else await removeRoleFromUser(userToDelete, group._id, roles.find((r) => r.name === roleName)._id, true);
         showSnackBar("El usuario fue eliminado correctamente.", false);
         setOpenConfirmation(false);
         await refreshGroupInfo();
      } catch (error) {
         showSnackBar("Error al eliminar usuario.", true);
      }
      setIsLoadingAdd(false);
   };

   const handleSubmitCreate = async (values) => {
      mutate(
         {
            userData: {
               firstName: values.firstName,
               lastName: values.lastName,
               email: values.email,
               phoneNumber: values.phone,
               role: [
                  {
                     group: group._id,
                     roles: roles.find((r) => r.name === roleName)._id,
                  },
               ],
            },
            additionalData: {
               admin: false,
               group: group._id,
               implementationUser: true,
            },
         },
         {
            onError: (error: any) => {
               error.response.data.message === "email already in use"
                  ? showSnackBar("Ya existe un usuario con ese correo", true)
                  : showSnackBar("Error al agregar usuario.", true);
            },
            onSuccess: async () => {
               showSnackBar("El usuario fue agregado correctamente.", false);
               await refreshGroupInfo();
               setCreateMode(false);
               setIsLoadingAdd(false);
            },
         }
      );
   };

   const handleSubmit = async () => {
      setIsLoadingAdd(true);
      try {
         if (selectedUser) {
            const data = {
               group: group._id,
               roles: [roles.find((r) => r.name === roleName)._id],
            };
            await addUserRoleGroup(data, selectedUser);
            await refreshGroupInfo();
            setCreateMode(false);
            showSnackBar("El usuario fue agregado correctamente", false);
         } else showSnackBar("Favor de seleccionar a un usuario existente", true);
      } catch (error) {
         showSnackBar("Error al agregar usuario.", true);
      }
      setIsLoadingAdd(false);
   };

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();
      useEffect(() => {
         if (selectedUser) {
            const allUsers = [...groupUsers, ...implementationUsers];
            const userFound = allUsers.find((user) => user._id === selectedUser);
            setFieldValue("firstName", userFound.firstName);
            setFieldValue("lastName", userFound.lastName);
            setFieldValue("phone", userFound.phoneNumber);
            setFieldValue("email", userFound.email);
         } else if (!selectedUser) {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
            setFieldValue("phone", "");
            setFieldValue("email", "");
         }
         // eslint-disable-next-line
      }, [selectedUser]);
      return null;
   };

   const headCells: HeadCell[] = [
      { field: "firstName", headerName: "Nombre(s)", type: "string" },
      { field: "lastName", headerName: "Apellidos", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "phoneNumber", headerName: "Teléfono", type: "string" },
      { field: "delete", headerName: "Eliminar", type: "string" },
   ];

   return (
      <Box key={users.length || ""} sx={{ py: 2 }}>
         {!createMode ? (
            <>
               <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, pb: 1 }}>
                  <LoadingButton
                     isLoading={isLoadingUser}
                     label="Agregar"
                     sx={{ m: "0 !important" }}
                     onClick={() => {
                        setCreateMode(true);
                     }}
                  />
               </Box>
               <TableComponent
                  defaultColumnToOrder=""
                  defaultOrder="asc"
                  defaultRowsPerPage={3}
                  emptyDataText="No se han registrado coordinadores"
                  rowsPerPageOptions={[3]}
                  loader={isLoadingUser}
                  headers={headCells}
                  rows={users}
                  onClick={() => {}}
                  disableBorders
                  dense
               />
            </>
         ) : (
            !isLoadingUser && (
               <Stack spacing={2} sx={{ px: 2 }}>
                  {implementationUsers?.length > 0 && (
                     <Stack spacing={2}>
                        <Typography>Seleccionar usuario existente:</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                           <Select size="small" sx={{ minWidth: 300 }} value={selectedUser}>
                              <ListSubheader sx={{ p: 0 }}>
                                 <Typography fontWeight={600} sx={{ py: 1, px: 2, bgcolor: "#d9d9d9" }}>
                                    Usuarios de implementación
                                 </Typography>
                              </ListSubheader>
                              {implementationUsers
                                 .filter((u) => !users.some((user) => user.id === u._id))
                                 .map((option, index) => (
                                    <MenuItem
                                       key={`user-${index}`}
                                       value={option._id}
                                       onClick={() => {
                                          setSelectedUser(option._id);
                                       }}
                                    >
                                       {`${option.firstName} ${option.lastName}`}
                                    </MenuItem>
                                 ))}
                              <ListSubheader sx={{ p: 0 }}>
                                 <Typography fontWeight={600} sx={{ p: 1, px: 2, bgcolor: "#d9d9d9" }}>
                                    Usuarios del grupo
                                 </Typography>
                              </ListSubheader>
                              {groupUsers
                                 .filter((u) => !users.some((user) => user.id === u._id))
                                 .map((option, index) => (
                                    <MenuItem
                                       key={`user-${index}`}
                                       value={option._id}
                                       onClick={() => {
                                          setSelectedUser(option._id);
                                       }}
                                    >
                                       {`${option.firstName} ${option.lastName}`}
                                    </MenuItem>
                                 ))}
                           </Select>
                           {selectedUser && (
                              <IconButton
                                 onClick={() => {
                                    setSelectedUser("");
                                 }}
                              >
                                 <Clear sx={{ fontSize: 20 }} />
                              </IconButton>
                           )}
                        </Box>
                     </Stack>
                  )}
                  <Typography>{`Crear nuevo coordinador de ${
                     beneficiary ? "beneficiario controlador" : "gobierno corporativo"
                  }:`}</Typography>
                  <Formik
                     initialValues={{
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                     }}
                     validationSchema={createUserSchema}
                     onSubmit={selectedUser ? handleSubmit : handleSubmitCreate}
                  >
                     <Form>
                        <FormObserver />
                        <Box
                           sx={{
                              borderColor: "#E0E0E0",
                              display: "flex",
                              rowGap: 2,
                              flexDirection: "column",
                              maxHeight: "420px",
                              overflowY: "auto",
                           }}
                        >
                           <Box sx={{ display: "flex", columnGap: 2, my: 0.5 }}>
                              <InputTextField
                                 variant="outlined"
                                 size="small"
                                 name="firstName"
                                 id="firstName"
                                 type="text"
                                 label="Nombre"
                                 fullWidth={true}
                                 disabled={selectedUser ? true : false}
                              />
                              <InputTextField
                                 variant="outlined"
                                 size="small"
                                 name="lastName"
                                 id="lastName"
                                 type="text"
                                 label="Apellido"
                                 fullWidth={true}
                                 disabled={selectedUser ? true : false}
                              />
                           </Box>
                           <Box sx={{ display: "flex", columnGap: 2 }}>
                              <InputTextField
                                 variant="outlined"
                                 size="small"
                                 name="phone"
                                 id="phone"
                                 type="text"
                                 label="Teléfono"
                                 fullWidth={true}
                                 disabled={selectedUser ? true : false}
                              />
                              <InputTextField
                                 variant="outlined"
                                 size="small"
                                 name="email"
                                 id="email"
                                 type="text"
                                 label="Correo electrónico"
                                 fullWidth={true}
                                 disabled={selectedUser ? true : false}
                              />
                           </Box>
                        </Box>
                        <Box
                           sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                           }}
                        >
                           <Button
                              variant={"outlined"}
                              onClick={() => {
                                 setCreateMode(false);
                                 setSelectedUser("");
                              }}
                           >
                              Cancelar
                           </Button>
                           <LoadingButton isLoading={isLoadingCreate || isLoadingAdd} label="Guardar" />
                        </Box>
                     </Form>
                  </Formik>
               </Stack>
            )
         )}
         <ConfirmationModal
            open={openConfirmation}
            setOpen={setOpenConfirmation}
            generic
            onConfirm={() => {
               handleRemove();
            }}
            onCancel={() => setUserToDelete("")}
         />
      </Box>
   );
};

export default GroupCoordinatorComponent;
