import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useMemo, useState } from "react";
import { ConsultiveGroupInfo } from "./ConsultiveGroup/ConsultiveGroupInfo";
import ConsultiveGroup from "./ConsultiveGroup/ConsultiveGroup";
import { GroupServiceCompaniesTable } from "./GroupServiceCompaniesTable";

import DenounceChannelGroupLicences from "./DenounceChannelGroup/DenounceChannelGroupLicences";
import { GroupUsersByModule } from "./GroupUsersByModule";
import { BeneficiaryGroupTab } from "./BeneficiaryGroup/BeneficiaryGroupTab";

const services = [
   {
      id: "64e784dd978b71bd4f011ee3",
      service: "Canal de denuncias",
      component: <DenounceChannelGroupLicences />,
      subcomponent: (
         <>
            <GroupUsersByModule serviceCode="CD" />
            <GroupServiceCompaniesTable serviceId="64e784dd978b71bd4f011ee3" />
         </>
      ),
   },
   {
      id: "64e7851d978b71bd4f011ee4",
      service: "Gobierno corporativo",
      component: <ConsultiveGroupInfo />,
      subcomponent: <ConsultiveGroup />,
   },
   {
      id: "66d75b0724169eb3399c631b",
      service: "Beneficiario controlador",
      component: <ConsultiveGroupInfo serviceId={"66d75b0724169eb3399c631b"} />,
      subcomponent: <BeneficiaryGroupTab />,
   },
];

const GroupServices = () => {
   const [value, setValue] = useState(services[0]?.id || "");

   const handleChange = (_event: React.SyntheticEvent, newValue: string) => setValue(newValue);

   const selectedService = useMemo(
      () => services.find((service) => service.id === value) || services[0],
      [value, services]
   );

   return (
      <Stack direction="column" spacing={2}>
         <Stack sx={{ borderRadius: 1, bgcolor: "white", boxShadow: 2 }}>
            <Box display="flex" sx={{ borderBottom: 1, borderColor: "divider" }}>
               <Tabs sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} value={value} onChange={handleChange}>
                  {services.map((tab) => (
                     <Tab key={tab.id} value={tab.id} label={tab.service} sx={{ fontSize: 14, fontWeight: 600 }} />
                  ))}
               </Tabs>
            </Box>
            <Box>{selectedService?.component}</Box>
         </Stack>
         {selectedService?.subcomponent}
      </Stack>
   );
};

export default GroupServices;
