import React, { useContext } from "react";
import { Box, DialogTitle, IconButton, Modal, Stack, Typography } from "@mui/material";
import { getDateAndTimeFromISODate } from "../../const/globalConst";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import CloseIcon from "@mui/icons-material/Close";

interface ConfirmationModalProps {
   open: boolean;
   setOpen: Function;
   title: string;
   subtitle?: string;
   body: React.ReactNode;
   isOnGroup?: boolean;
}

export const ClientTypeModal = (props: ConfirmationModalProps) => {
   const { setGroup, setClientInfo } = useContext(ClientConfigContext);
   const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") {
         return;
      }
      props.setOpen(false);
      setClientInfo(null);
      if (!props.isOnGroup) {
         setGroup(null);
      }
   };
   const handleClose2 = () => {
      props.setOpen(false);
      setClientInfo(null);
      if (!props.isOnGroup) {
         setGroup(null);
      }
   };

   return (
      <Modal
         open={props.open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         sx={{ display: "flex" }}
      >
         <Box sx={{ m: "auto" }}>
            <Box bgcolor={"#162c44"} px={1} sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
               <DialogTitle display={"flex"} justifyContent={"space-between"}>
                  <Stack>
                     <Typography fontSize={20} color={"white"} fontWeight={600}>
                        {props.title}
                     </Typography>
                     {props.subtitle && (
                        <Typography color={"white"} fontWeight={600}>
                           {getDateAndTimeFromISODate(props.subtitle)}
                        </Typography>
                     )}
                  </Stack>
                  <IconButton onClick={() => handleClose2()}>
                     <CloseIcon sx={{ color: "white" }} />
                  </IconButton>
               </DialogTitle>
            </Box>
            <Box
               sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: "thin 0px",
                  bgcolor: "white",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
               }}
            >
               {props.body}
            </Box>
         </Box>
      </Modal>
   );
};
