import { useState } from "react";
import { CustomModalComponent } from "../../../../../CustomModalComponent";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";

import CompanyImplementationUserComponent from "../CompanyImplementationUserComponent";
import CompanyCoordinatorUserComponent from "../CompanyCoordinatorUserComponent";
import GroupCoordinatorComponent from "../../../../../ClientInformation/Group/ConsultiveGroup/GovernanceCoordination/GroupCoordinatorComponent";
import GroupImplementationComponent from "../../../../../ClientInformation/Group/ConsultiveGroup/GovernanceCoordination/GroupImplementationComponent";
import { TabPanel } from "../../../../../TabPanel";

const CompanyCoordination = (props: { group?: boolean; beneficiary?: boolean }) => {
   const { group, beneficiary } = props;
   const [openModal, setOpenModal] = useState(false);
   const [value, setValue] = useState(0);
   const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   return (
      <>
         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography fontWeight={600} sx={{ px: 1 }}>
               {`Coordinación de ${!beneficiary ? "gobierno corporativo" : "beneficiario controlador"}`}
            </Typography>
            <Button
               variant="contained"
               onClick={() => {
                  setOpenModal(true);
               }}
            >
               Abrir
            </Button>
         </Box>
         <CustomModalComponent
            open={openModal}
            setOpen={setOpenModal}
            title={`Coordinación de ${!beneficiary ? "gobierno corporativo" : "beneficiario controlador"}`}
         >
            <Box sx={{ minWidth: 800 }}>
               {!beneficiary && (
                  <Tabs value={value} onChange={handleChange} centered>
                     <Tab label="Usuario de implementación" sx={{ flex: 1, p: 2.5 }} />
                     <Tab
                        label={`Coordinador de ${!beneficiary ? "gobierno corporativo" : "beneficiario controlador"}`}
                        sx={{ flex: 1, p: 2.5 }}
                     />
                  </Tabs>
               )}
               {!beneficiary && (
                  <TabPanel value={value} index={0}>
                     <Box sx={{ px: 2 }}>
                        {group ? <GroupImplementationComponent /> : <CompanyImplementationUserComponent />}
                     </Box>
                  </TabPanel>
               )}
               <TabPanel value={value} index={beneficiary ? 0 : 1}>
                  <Box sx={{ px: 2 }}>
                     {group ? (
                        <GroupCoordinatorComponent beneficiary={beneficiary} />
                     ) : (
                        <CompanyCoordinatorUserComponent beneficiary={beneficiary} />
                     )}
                  </Box>
               </TabPanel>
            </Box>
         </CustomModalComponent>
      </>
   );
};

export default CompanyCoordination;
