import { useState, useEffect, useContext } from "react";
import { Box, Typography, Grid, Button, CircularProgress, Drawer, Divider, Tooltip } from "@mui/material";
import { getDateFromISODate } from "../../const/globalConst";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { getUrlS3, UseBranchesByCompanyId } from "../../lib/usersBEClient";
import { AddModifyCompanyDrawer } from "./Drawer/AddModifyCompanyDrawer";
import { IPlatformInfo } from "../../types/BaseTypes";
import { useParams } from "react-router";
import { UserContext } from "../../context/UserContext";

export const usePlatformIdValues = () => {
   const [platformInfo, setPlatformInfo] = useState<IPlatformInfo>();
   const { clientId } = useParams();
   const { clientInfo } = useContext(ClientConfigContext);

   useEffect(() => {
      if (clientInfo?.company_details?.servicesDetails) {
         const servicesDetails = clientInfo.company_details.servicesDetails;
         const platformValues: IPlatformInfo = {
            subName: "",
            color: "",
            message: "",
            privacy_policies: "",
            flyers: [],
         };
         servicesDetails.forEach((service) => {
            if (!service.details?.platformId) return;
            const platformId = service.details.platformId;

            platformValues.subName = platformId.subName;
            platformValues.color = platformId.color;
            platformValues.message = platformId.message;
            platformValues.privacy_policies = platformId.privacy_policies;
            platformValues.flyers = platformId.flyers;
         });
         setPlatformInfo(platformValues);
      }
   }, [clientInfo, clientId]);

   return platformInfo;
};

export const ClientInformationHome = () => {
   const { clientId } = useParams();
   const { clientInfo } = useContext(ClientConfigContext);
   const [services, setServices] = useState([]);
   const [openEditCompanyDrawer, setOpenEditCompanyDrawer] = useState(false);
   const [branches, setBranches] = useState<any[]>([]);
   const platformInfo = usePlatformIdValues();
   const [contractFile, setContractFile] = useState("");
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Clientes");

   useEffect(() => {
      const loadBranches = async () => {
         const load = await UseBranchesByCompanyId(clientId);
         setBranches(load);
      };
      loadBranches();
      // eslint-disable-next-line
   }, []);

   const getDataS3 = async (clientId: string) => {
      const contractUrl: string = await getUrlS3("files-lecosy", { folder: `contracts/${clientId}` }, "contract.pdf");
      setContractFile(contractUrl);
   };

   async function fetchInfo() {
      try {
         const servicesData = clientInfo.company_details.servicesDetails.map((service) => service.serviceId);
         setServices(servicesData);
      } catch (error) {
         console.log(error);
      }
   }
   useEffect(() => {
      if (!clientInfo) return;
      fetchInfo();
      getDataS3(clientId);
      // eslint-disable-next-line
   }, [clientInfo]);

   return (
      <>
         <Box bgcolor="white" sx={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }} boxShadow={2} p={1}>
            {branches && clientInfo ? (
               <Box m={1} display={"flex"} flexDirection={"column"}>
                  <Grid container rowSpacing={4} columnSpacing={6} px={1}>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Denominación o razón social:</Typography>
                        <Typography>{clientInfo.person_details.businessName}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Actividad y/o giro:</Typography>
                        <Typography>
                           {clientInfo.person_details.activity
                              ? clientInfo.person_details.activity
                              : "No tiene actividad registrada"}
                        </Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Tipo de persona:</Typography>
                        <Typography>{clientInfo.type === "natural" ? `Fisica` : `Moral`}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Teléfono:</Typography>
                        <Typography>
                           {clientInfo.invoice_details
                              ? clientInfo.invoice_details.phone ?? "No tiene número registrado"
                              : "No tiene detalles"}
                        </Typography>
                     </Grid>

                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Módulos contratados:</Typography>
                        <Typography>{services.length}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Color corporativo:</Typography>
                        <Box display={"flex"} sx={{ columnGap: 2, mt: 0.5 }}>
                           <Tooltip title={"Color primario"}>
                              <Box display={"flex"} sx={{ columnGap: 1 }}>
                                 <Box
                                    bgcolor={clientInfo.company_details.primaryColor}
                                    sx={{ borderRadius: 1, width: 50, height: 20 }}
                                 />
                                 <Typography>{clientInfo.company_details.primaryColor}</Typography>
                              </Box>
                           </Tooltip>

                           <Tooltip title={"Color secundario"}>
                              <Box display={"flex"} sx={{ columnGap: 1 }}>
                                 <Box
                                    bgcolor={clientInfo.company_details.secondaryColor}
                                    sx={{ borderRadius: 1, width: 50, height: 20 }}
                                 />
                                 <Typography>{clientInfo.company_details.secondaryColor}</Typography>
                              </Box>
                           </Tooltip>
                        </Box>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Fecha de creación:</Typography>
                        <Typography>{getDateFromISODate(clientInfo.createdAt)}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Estatus:</Typography>
                        <Typography>{clientInfo.disabled ? "Inhabilitado" : "Habilitado"}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} mb={1}>
                           Contrato:
                        </Typography>
                        {platformInfo ? (
                           <Button
                              variant="outlined"
                              onClick={async () => {
                                 window.open(contractFile, "_blank", "noopener,noreferrer");
                              }}
                           >
                              <Typography>Ver contrato</Typography>
                              <PictureAsPdfIcon sx={{ mx: 1 }} />
                           </Button>
                        ) : (
                           "No tiene politicas"
                        )}
                     </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                     <Box display={"flex"}>
                        <Typography fontSize={14}>
                           Ultima modificación: {getDateFromISODate(clientInfo.updatedAt)}
                        </Typography>
                     </Box>
                     {!access ? (
                        <Box>
                           <Button
                              variant="contained"
                              onClick={() => {
                                 setOpenEditCompanyDrawer(true);
                              }}
                           >
                              Modificar información
                           </Button>
                        </Box>
                     ) : null}
                  </Box>
               </Box>
            ) : (
               <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
                  <CircularProgress sx={{ alignSelf: "center" }} />
               </Box>
            )}
         </Box>
         <Drawer
            anchor="right"
            open={openEditCompanyDrawer}
            hideBackdrop
            onClose={() => {
               setOpenEditCompanyDrawer(false);
            }}
            sx={{ maxWidth: 450 }}
         >
            {openEditCompanyDrawer ? (
               <AddModifyCompanyDrawer
                  setOpen={setOpenEditCompanyDrawer}
                  open={openEditCompanyDrawer}
                  companyInfo={clientInfo}
               />
            ) : (
               <></>
            )}
         </Drawer>
      </>
   );
};
