import { useEffect, useContext, useState, useCallback, useMemo } from "react";
import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container/Container";
import { TopHeader } from "../components/TopHeader";
import { getAllCompanies, getAllGroups, getAllOffices } from "../lib/usersBEClient";
import { ClientConfigContext } from "../context/client/clientConfigContext";
import { HeadCell, TableComponent } from "../components/TableComponent";
import { ServiceInfoProvider } from "../context/serviceInfoContext";
import { getDateAndTimeFromISODate } from "../const/globalConst";
import { Companies, Group } from "../types/BaseTypes";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import CreateClientModal from "../components/Clients/ClientCreation/CreateClientModal";
import { FilterComponent } from "../components/FilterComponent";
import { Error, PersonAdd } from "@mui/icons-material";

const getActiveServices = (company_details) =>
   company_details?.servicesDetails
      ?.filter((service) => !service.disable)
      .map((service) => service.serviceId?.service) || [];

const companyColums: HeadCell[] = [
   { field: "comercialName", headerName: "Nombre comercial", type: "string", width: 50, align: "left" },
   { field: "businessName", headerName: "Razón social", type: "string", width: 50 },
   { field: "createdAt", headerName: "Fecha de creación", type: "string", width: 50, align: "center" },
];

const groupColums: HeadCell[] = [
   {
      field: "name",
      headerName: "Nombre del grupo empresarial",
      type: "string",
      align: "left",
   },
   {
      field: "abrir",
      headerName: "",
      type: "collapsible",
      align: "right",
   },
];

export function ClientsManagementScreen() {
   const { GetPermission } = useContext(UserContext);
   const { getClientInfo, setClientInfo, setGroup, getGroupInfo } = useContext(ClientConfigContext);

   const navigate = useNavigate();
   const access = GetPermission("Clientes");

   const [openModal, setOpenModal] = useState<boolean>(false);
   const [opened, setOpened] = useState<string>();

   const [isLoading, setIsLoading] = useState(false);
   const [clientsRow, setClientsRow] = useState([]);
   const [filteredClients, setFilteredClients] = useState([]);

   const fetchCompanies = useCallback(async () => {
      try {
         setIsLoading(true);
         const [companyResponseObtained, officeResponse, groupsResponse] = await Promise.all([
            getAllCompanies(),
            getAllOffices(),
            getAllGroups(),
         ]);
         const formatResponse = (response) =>
            response.map(({ _id, person_details, createdAt, company_details }) => ({
               id: _id,
               comercialName: person_details?.comercialName,
               businessName: person_details?.businessName || "",
               createdAt: getDateAndTimeFromISODate(createdAt),
               unformatedDate: new Date(createdAt.split("T")[0]).getTime(),
               type: company_details?.office ? "Despacho" : "Empresa",
            }));

         const groupsData = groupsResponse
            .filter((g) => !g.owner)
            .map(({ _id, name, users, companies, createdAt }: Group) => ({
               id: _id,
               name,
               users: users.length,
               type: "Grupo empresarial",
               createdAt: getDateAndTimeFromISODate(createdAt),
               unformatedDate: new Date(createdAt.split("T")[0]).getTime(),
               companies: companies.map(({ _id, person_details, createdAt, company_details }: Companies) => ({
                  id: _id,
                  comercialName: person_details?.comercialName,
                  createdAt: getDateAndTimeFromISODate(createdAt),
                  unformatedDate: new Date(createdAt.split("T")[0]).getTime(),
                  services: getActiveServices(company_details),
                  users: company_details?.users?.length || 0,
                  type: company_details?.office ? "Despacho" : "Empresa",
               })),
            }));

         const formattedCompanies = formatResponse(companyResponseObtained);
         const officesFound = formatResponse(officeResponse);
         setClientsRow([...groupsData, ...formattedCompanies, ...officesFound]);
         setFilteredClients([...groupsData, ...formattedCompanies, ...officesFound]);
      } catch (error) {
         console.error("Error fetching clients:", error);
      } finally {
         setIsLoading(false);
      }
   }, []);

   useEffect(() => {
      fetchCompanies();
   }, [fetchCompanies]);

   const filteredData = useMemo(
      () => ({
         companies: filteredClients.filter((c) => c.type === "Empresa"),
         groups: filteredClients.filter((c) => c.type === "Grupo empresarial"),
         offices: filteredClients.filter((c) => c.type === "Despacho"),
      }),
      [filteredClients]
   );

   const handleTableRowClickNew = async (_e, row) => {
      setGroup(undefined);
      if (row.name) {
         const screen = "grupo/" + row.id;
         await getGroupInfo(row.id);
         return navigate(screen);
      } else {
         const screen = row.id;
         await getClientInfo(row.id);
         return navigate(screen);
      }
   };

   return (
      <Box sx={{ padding: 2, display: "flex", flexDirection: "column", backgroundColor: "#F5F5F5" }}>
         <TopHeader head="Gestión de clientes" content={<></>} />
         <Container maxWidth="xl">
            {!isLoading ? (
               <>
                  <FilterComponent
                     originaltableOneRows={clientsRow}
                     tableOneFilteredRows={filteredClients}
                     setTableOneFilteredRows={setFilteredClients}
                     opened={opened}
                     disableRefresh
                     setOpened={setOpened}
                     filters={["daterange"]}
                     searchBar={
                        !access ? (
                           <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
                              <Button
                                 onClick={() => {
                                    setClientInfo(undefined);
                                    setGroup(undefined);
                                    setOpenModal(!openModal);
                                 }}
                                 size="small"
                                 variant="contained"
                                 sx={{ maxHeight: "100%" }}
                                 fullWidth
                              >
                                 <Typography variant="button" sx={{ mr: 1 }}>
                                    Agregar cliente
                                 </Typography>
                                 <PersonAdd />
                              </Button>
                           </Box>
                        ) : (
                           <></>
                        )
                     }
                  />
                  <Stack spacing={2}>
                     {filteredData.companies.length > 0 && (
                        <Box sx={{ boxShadow: 2, bgcolor: "white", borderRadius: 2 }}>
                           <Typography
                              fontSize={20}
                              p={2}
                              sx={{
                                 color: "white",
                                 bgcolor: "#162c44",
                                 borderTopLeftRadius: 5,
                                 borderTopRightRadius: 5,
                              }}
                           >
                              {"Empresas"}
                           </Typography>
                           <Divider />
                           <TableComponent
                              defaultColumnToOrder=""
                              defaultOrder="asc"
                              defaultRowsPerPage={5}
                              rowsPerPageOptions={[5, 10, 15]}
                              loader={isLoading}
                              headers={companyColums}
                              onClick={handleTableRowClickNew}
                              disableBorders
                              rows={filteredData.companies}
                              emptyDataText={
                                 filteredData.companies.length > 0
                                    ? "No se encontró una empresa con ese nombre"
                                    : "No existen empresas registradas"
                              }
                           />
                        </Box>
                     )}
                     {filteredData.groups.length > 0 && (
                        <Box sx={{ boxShadow: 2, bgcolor: "white", borderRadius: 2 }}>
                           <Typography
                              fontSize={20}
                              p={2}
                              sx={{
                                 color: "white",
                                 bgcolor: "#162c44",
                                 borderTopLeftRadius: 5,
                                 borderTopRightRadius: 5,
                              }}
                           >
                              {"Grupos empresariales"}
                           </Typography>
                           <Divider />
                           <TableComponent
                              defaultColumnToOrder=""
                              defaultOrder="asc"
                              defaultRowsPerPage={5}
                              rowsPerPageOptions={[5, 10, 15]}
                              loader={isLoading}
                              headers={groupColums}
                              onClick={handleTableRowClickNew}
                              onClickCollapsible={handleTableRowClickNew}
                              collapsible
                              disableBorders
                              rows={filteredData.groups}
                              opened={opened}
                              setOpened={setOpened}
                              emptyDataText={
                                 filteredData.groups.length > 0
                                    ? "No se encontró un grupo empresarial con ese nombre"
                                    : "No existen grupos empresariales registrados"
                              }
                           />
                        </Box>
                     )}

                     {filteredData.offices.length > 0 && (
                        <Box sx={{ boxShadow: 2, bgcolor: "white", borderRadius: 2 }}>
                           <Typography
                              fontSize={20}
                              p={2}
                              sx={{
                                 color: "white",
                                 bgcolor: "#162c44",
                                 borderTopLeftRadius: 5,
                                 borderTopRightRadius: 5,
                              }}
                           >
                              {"Despachos"}
                           </Typography>
                           <Divider />
                           <TableComponent
                              defaultColumnToOrder=""
                              defaultOrder="asc"
                              defaultRowsPerPage={5}
                              rowsPerPageOptions={[5, 10, 15]}
                              loader={isLoading}
                              headers={companyColums}
                              onClick={handleTableRowClickNew}
                              disableBorders
                              rows={filteredData.offices}
                              emptyDataText={
                                 filteredData.offices.length > 0
                                    ? "No se encontró un despacho con ese nombre"
                                    : "No existen despachos registradas"
                              }
                           />
                        </Box>
                     )}
                     {!filteredData.companies.length && !filteredData.groups.length && !filteredData.offices.length && (
                        <Box
                           display="flex"
                           sx={{ p: 4, justifyContent: "center", alignItems: "center", columnGap: 2, opacity: 0.3 }}
                        >
                           <Typography>No se encontró cliente</Typography>
                           <Error sx={{ width: "35px", height: "35px", color: "#162c44" }} />
                        </Box>
                     )}
                  </Stack>
               </>
            ) : (
               <Box
                  sx={{
                     height: "70vh",
                     width: "100%",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <CircularProgress />
               </Box>
            )}
         </Container>
         <ServiceInfoProvider>
            <CreateClientModal state={openModal} setState={setOpenModal} />
         </ServiceInfoProvider>
      </Box>
   );
}
