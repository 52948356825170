import { Companies, Group } from "../../types/BaseTypes";
import { createContext, useCallback, useEffect, useReducer, useState } from "react";
import {
   GetCompanyById,
   getGroupById,
   getInCompletedCompanies,
   getInCompletedGroups,
   getInCompletedOffices,
} from "../../lib/usersBEClient";

interface ClientCreationContextType {
   incompletedCompanies: Companies[];
   incompletedGroups: Group[];
   incompletedOffices: Companies[];
   refetch: Function;
   isLoading: boolean;
   selectedClient: { type: "natural" | "legal" | "group" | "office"; data: Companies | Group };
   setSelectedClient: Function;
}

interface ClientCreationState {
   incompletedCompanies: Companies[];
   incompletedGroups: Group[];
   incompletedOffices: Companies[];
   selectedClient: { type: "natural" | "legal" | "group" | "office"; data: Companies | Group };
}

const initialState: ClientCreationState = {
   incompletedCompanies: null,
   incompletedGroups: null,
   incompletedOffices: null,
   selectedClient: null,
};

function userReducer(state: ClientCreationState, action) {
   switch (action.type) {
      case "SET_INCOMPLETED_COMPANIES":
         return { ...state, incompletedCompanies: action.payload };
      case "SET_INCOMPLETED_GROUPS":
         return { ...state, incompletedGroups: action.payload };
      case "SET_INCOMPLETED_OFFICES":
         return { ...state, incompletedOffices: action.payload };
      case "SET_SELECTED_CLIENT":
         return { ...state, selectedClient: action.payload };
      default:
         return state;
   }
}

export const ClientCreationContext = createContext<ClientCreationContextType | undefined>(undefined);

export const ClientCreationProvider = ({ children }) => {
   const [state, dispatch] = useReducer(userReducer, initialState);

   //#region Dispatch
   const setIncompletedCompanies = useCallback((state: ClientCreationState) => {
      dispatch({ type: "SET_INCOMPLETED_COMPANIES", payload: state });
   }, []);

   const setIncompletedGroups = useCallback((state: ClientCreationState) => {
      dispatch({ type: "SET_INCOMPLETED_GROUPS", payload: state });
   }, []);

   const setIncompletedOffices = useCallback((state: ClientCreationState) => {
      dispatch({ type: "SET_INCOMPLETED_OFFICES", payload: state });
   }, []);

   const setSelectedClient = useCallback((state: ClientCreationState) => {
      dispatch({ type: "SET_SELECTED_CLIENT", payload: state });
   }, []);
   //#end region

   const [isLoading, setIsLoading] = useState(false);
   const [fetchKey, setFetchKey] = useState(0);

   const refetch = async () => {
      setFetchKey((prevKey) => prevKey + 1);

      const { selectedClient } = state;

      if (selectedClient?.data) {
         const client =
            selectedClient.type === "group"
               ? await getGroupById(selectedClient.data._id)
               : await GetCompanyById(selectedClient.data._id);

         const clientObject: any = { type: state.selectedClient.type, data: client };
         setSelectedClient(clientObject);
      }
   };

   const fetchIncompletedData = useCallback(async () => {
      setIsLoading(true);
      const [companies, groups, offices] = await Promise.all([
         getInCompletedCompanies(),
         getInCompletedGroups(),
         getInCompletedOffices(),
      ]);
      setIncompletedCompanies(companies);
      setIncompletedGroups(groups);
      setIncompletedOffices(offices);
      setIsLoading(false);
   }, [fetchKey]);

   useEffect(() => {
      fetchIncompletedData();
   }, [fetchIncompletedData]);

   return (
      <ClientCreationContext.Provider
         value={{
            ...state,
            refetch,
            isLoading,
            setSelectedClient,
         }}
      >
         {children}
      </ClientCreationContext.Provider>
   );
};
