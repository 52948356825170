import { Box } from "@mui/material";
import { ClientCreationTabsOld } from "./ClientCreationTabsOld";
import { TabPanel } from "../../../../TabPanel";
import { CompanyDetailsComponentOld } from "./CompanyDetailsComponent";
import { UserDetailsComponentOld } from "./UserDetailsComponent";
import { InvoicesDetailsComponentOld } from "./InvoicesDetailsComponent";
import { ServiceAssignComponentOld } from "./ServiceAssignComponent";

const ClientCreationTabsContainerOld = ({
   clientSelected,
   activeStep,
   setActiveStep,
   setCompletedCompany,
   handleCancel,
   completedCompany,
   setClientSelected,
}) => {
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            p: 2,
            minWidth: 1000,
            maxWidth: 1000,
         }}
      >
         <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1, px: 2 }}>
               <ClientCreationTabsOld value={activeStep} setValue={setActiveStep} completed={completedCompany} />
            </Box>
            <Box
               sx={{
                  flex: 3,
                  display: "flex",
                  px: 2,
                  flexDirection: "column",
                  maxHeight: 570,
                  overflowY: "auto",
               }}
            >
               <TabPanel value={activeStep} index={0}>
                  <CompanyDetailsComponentOld
                     setCompletedCompany={setCompletedCompany}
                     handleCancel={handleCancel}
                     selectedClient={clientSelected}
                     setValue={setActiveStep}
                     setClientSelected={setClientSelected}
                  />
               </TabPanel>
               <TabPanel value={activeStep} index={1}>
                  <UserDetailsComponentOld
                     setSelectedClient={setClientSelected}
                     handleCancel={handleCancel}
                     setValue={setActiveStep}
                     value={activeStep}
                  />
               </TabPanel>
               <TabPanel value={activeStep} index={2}>
                  <InvoicesDetailsComponentOld
                     handleCancel={handleCancel}
                     setValue={setActiveStep}
                     setSelectedClient={setClientSelected}
                  />
               </TabPanel>
               <TabPanel value={activeStep} index={3}>
                  <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                     <UserDetailsComponentOld
                        setSelectedClient={setClientSelected}
                        handleCancel={handleCancel}
                        setValue={setActiveStep}
                        value={activeStep}
                     />
                  </Box>
               </TabPanel>
               <TabPanel value={activeStep} index={4}>
                  <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                     <ServiceAssignComponentOld
                        handleCancel={handleCancel}
                        setValue={setActiveStep}
                        value={activeStep}
                        setSelectedClient={setClientSelected}
                     />
                  </Box>
               </TabPanel>
            </Box>
         </Box>
      </Box>
   );
};

export default ClientCreationTabsContainerOld;
