import { useContext } from "react";
import { ClientCreationContext } from "../../../../context/client/clientCreationContext";
import ClientCreationTabsContainer from "../Company/ClientCreationTabsContainer";
import GroupClienCreationTabsContainer from "../Group/GroupClienCreationTabsContainer";
import OfficeCreationTabs from "../Office/OfficeCreationTabs";

const ClientCreationForms = () => {
   const { selectedClient } = useContext(ClientCreationContext);

   const ClientForm = () => {
      switch (selectedClient?.type) {
         case "natural":
            return <ClientCreationTabsContainer />;
         case "legal":
            return <ClientCreationTabsContainer />;
         case "group":
            return <GroupClienCreationTabsContainer />;
         case "office":
            return <OfficeCreationTabs />;
         default:
            <></>;
      }
   };

   return <ClientForm />;
};

export default ClientCreationForms;
