import { Box, Tab, Tabs } from "@mui/material";
import { CompanyDetailsComponent } from "../Company/CompanyDetailsComponent";
import { UserDetailsComponent } from "../../SubComponents/UserDetailsComponent";
import { InvoicesDetailsComponent } from "../../SubComponents/InvoicesDetailsComponent";
import { TabPanel } from "../../../TabPanel";
import { ClientCreationContext } from "../../../../context/client/clientCreationContext";
import { useContext, useState } from "react";
import OfficeLicences from "./Subcomponents/OfficeLicences";
import { ServiceAssignComponent } from "../../SubComponents/ServiceAssignComponent";

const OfficeCreationTabs = () => {
   const { selectedClient, setSelectedClient } = useContext(ClientCreationContext);
   const [activeStep, setActiveStep] = useState(0);

   const handleChange = (_event: React.SyntheticEvent, newValue: number) => setActiveStep(newValue);
   const handleNext = () => setActiveStep((a) => a + 1);
   const handleComplete = () => setSelectedClient(null);

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            p: 2,
            minWidth: 1000,
            maxWidth: 1000,
         }}
      >
         <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1, px: 2 }}>
               <Box
                  sx={{
                     border: 1,
                     borderColor: "#E0E0E0",
                  }}
               >
                  <Tabs orientation="vertical" value={activeStep} onChange={handleChange}>
                     {["Despacho", "Administrador del sistema", "Facturas", "Usuarios", "Módulos"].map(
                        (step, index) => (
                           <Tab key={index} label={step} disabled={!selectedClient?.data && index !== 0} />
                        )
                     )}
                  </Tabs>
               </Box>
            </Box>
            <Box
               sx={{
                  flex: 3,
                  display: "flex",
                  px: 2,
                  flexDirection: "column",
                  maxHeight: 570,
                  overflowY: "auto",
               }}
            >
               <TabPanel value={activeStep} index={0}>
                  <CompanyDetailsComponent onComplete={handleNext} />
               </TabPanel>
               <TabPanel value={activeStep} index={1}>
                  <UserDetailsComponent onComplete={handleNext} value={activeStep} />
               </TabPanel>
               <TabPanel value={activeStep} index={2}>
                  <InvoicesDetailsComponent onComplete={handleNext} />
               </TabPanel>
               <TabPanel value={activeStep} index={3}>
                  <OfficeLicences onComplete={handleNext} />
               </TabPanel>
               <TabPanel value={activeStep} index={4}>
                  <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                     <ServiceAssignComponent onComplete={handleComplete} />
                  </Box>
               </TabPanel>
            </Box>
         </Box>
      </Box>
   );
};

export default OfficeCreationTabs;
