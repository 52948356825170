import React, { useContext, useEffect, useState } from "react";
import {
   Box,
   Button,
   Collapse,
   Stack,
   Typography,
   Toolbar,
   IconButton,
   Tooltip,
   Autocomplete,
   TextField,
   FormHelperText,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { masterUserModifyeSchema, userCreateSchema } from "../../lib/Validations/inputSchemas";
import { catchAxiosError } from "../../lib/lecosyBackendClient";
import { SnackBarContext } from "../../context/snackBarContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AxiosError } from "axios";
import { MoreVert, Close } from "@mui/icons-material";
import { ConfirmationModal } from "../ConfirmationModal";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import {
   getAllMasterRoles,
   createMasterUser,
   updateMasterUser,
   deleteMasterUser,
   resendEmailVerification,
} from "../../lib/usersBEClient";
import { LoadingButton } from "../LoadingButton";

export interface AddModifyMasterUserDrawerProps {
   isModify: boolean;
   setOpen: Function;
   masterUserData?: {
      createdAt: string;
      email: string;
      firstName: string;
      lastName: string;
      role: RoleInterface[];
      uid: string;
      updatedAt: string;
      validPwd: boolean;
   };
   updateMasterInfo?: Function;
}
interface RoleInterface {
   _id: string;
   name: string;
   description: string;
}

export const AddModifyMasterUserDrawer = (props: AddModifyMasterUserDrawerProps) => {
   const navigate = useNavigate();
   const { showSnackBar } = useContext(SnackBarContext);
   const [showPassword, setShowPassword] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [openUserDeleteCollapse, setOpenUserDeleteCollapse] = useState(false);
   const [openDeleteConfirmatinModal, setOpenDeleteConfirmatinModal] = useState(false);
   const [rolesList, setRolesList] = useState<RoleInterface[]>([]);
   const [selectedRoles, setSelectedRoles] = useState<RoleInterface[]>(props.masterUserData?.role || []);
   const [rolesSelectedError, setRolesSelectedError] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
   const [openVerification, setOpenVerification] = useState<boolean>(false);

   const handleClickShowPassword = () => setShowPassword(!showPassword);

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();

   useEffect(() => {
      getRoles();
   }, []);

   async function getRoles() {
      setIsLoading(true);
      try {
         const response = await getAllMasterRoles();
         setRolesList(response.data);
         setIsLoading(false);
      } catch (error) {
         console.error("Error fetching roles:", error);
         setIsLoading(false);
      }
   }

   const handleMasterUserDelete = async () => {
      try {
         await deleteMasterUser(props.masterUserData.uid);
         setOpenDeleteConfirmatinModal(false);
         navigate(-1);
      } catch (error) {
         console.log(error);
      }
   };

   const handleSendVerification = async () => {
      const response = await resendEmailVerification(props.masterUserData.email);
      if (response?.status === 200) showSnackBar("Se ha enviado el correo de verificación", false);
      else showSnackBar("Error al validar el correo, intentelo de nuevo más tarde", true);
      return setOpenVerification(false);
   };

   const addNewMasterUser = async (values, actions) => {
      try {
         setIsLoading(true);
         const userResponse = await createMasterUser(values.firstName, values.lastName, values.email, values.role);
         if (userResponse.status === 201) {
            showSnackBar("Usuario añadido exitosamente", false);
            props.setOpen(false);
            actions.resetForm();
            setIsLoading(false);
         }
         showSnackBar("Usuario añadido exitosamente", false);
         props.setOpen(false);
         actions.resetForm();
         setIsLoading(false);
      } catch (error: any) {
         if (
            error instanceof AxiosError &&
            (error.response.data.message === "Email already in use." ||
               error.response.data.message.includes("auth/email-already-exists"))
         ) {
            showSnackBar("Este correo electrónico ya existe", true);
         }
         if (error.response.status === 400) {
            showSnackBar("Error, intente de nuevo", true);
            return null;
         }
         setIsLoading(false);
      }
   };

   const modifyMasterUser = async (values, actions) => {
      try {
         setIsLoading(true);
         if (
            values.firstName === props.masterUserData.firstName &&
            values.lastName === props.masterUserData.lastName &&
            values.email === props.masterUserData.email &&
            selectedRoles === props.masterUserData.role
         ) {
            showSnackBar("No hay cambios que efectuar", true);
            actions.resetForm();
            props.setOpen(false);
            setIsLoading(false);
            return;
         }
         if (selectedRoles.length > 0) {
            setRolesSelectedError(false);
            const response = await updateMasterUser(
               props.masterUserData["uid"],
               values.firstName,
               values.lastName,
               values.email,
               values.role
            );
            if (response.data) {
               showSnackBar("Usuario modificado exitosamente", false);
               actions.resetForm();
               props.updateMasterInfo();
               props.setOpen(false);
               setIsLoading(false);
            }
         } else {
            setRolesSelectedError(true);
         }

         setIsLoading(false);
      } catch (error) {
         const errorMessage = catchAxiosError(error);
         if (errorMessage.message.includes("no user found")) {
            showSnackBar("Error, intente de nuevo", true);
         }
         if (errorMessage.message.includes("email-already-exists")) {
            showSnackBar("Correo electrónico en uso", true);
         }
         setIsLoading(false);
      }
   };
   return (
      <>
         <Toolbar />
         <Box display="flex" height={"100%"} maxWidth={450} width={420}>
            <Box
               height="100%"
               display="flex"
               alignItems="center"
               onClick={() => props.setOpen(false)}
               sx={{
                  "&:hover": {
                     bgcolor: "#2d435710",
                  },
               }}
            >
               <ChevronRightIcon fontSize="small" />
            </Box>
            <Box flex={1} mt={4} mr={1}>
               <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography variant="h5">
                     {!props.isModify ? "Agregar usuario interno" : "Modificar usuario interno"}
                  </Typography>
                  {!props.isModify ? (
                     <></>
                  ) : (
                     <IconButton onClick={() => setOpenUserDeleteCollapse(!openUserDeleteCollapse)}>
                        {!openUserDeleteCollapse ? <MoreVert /> : <Close />}
                     </IconButton>
                  )}
               </Box>
               {props.isModify ? (
                  <Collapse in={openUserDeleteCollapse}>
                     <Box display={"flex"} justifyContent={"flex-end"} p={1}>
                        <Button
                           variant="outlined"
                           color="error"
                           onClick={() => {
                              setOpenDeleteConfirmatinModal(true);
                           }}
                        >
                           Eliminar usuario
                        </Button>
                     </Box>
                  </Collapse>
               ) : (
                  <></>
               )}
               <Box pr={1} py={1} pl={0.5} mt={4}>
                  <Formik
                     initialValues={{
                        firstName: props.isModify ? props.masterUserData["firstName"] : "",
                        lastName: props.isModify ? props.masterUserData["lastName"] : "",
                        email: props.isModify ? props.masterUserData["email"] : "",
                        password: props.isModify ? null : "",
                        passwordConfirmation: props.isModify ? null : "",
                        role: props.isModify ? props.masterUserData["role"] : "",
                     }}
                     validationSchema={props.isModify ? masterUserModifyeSchema : userCreateSchema}
                     onSubmit={(values, actions) =>
                        props.isModify
                           ? modifyMasterUser({ ...values, role: selectedRoles }, actions)
                           : addNewMasterUser({ ...values, role: selectedRoles }, actions)
                     }
                  >
                     {(formProps: FormikProps<any>) => (
                        <Form>
                           <Stack spacing={2}>
                              <InputTextField
                                 variant="standard"
                                 label="Nombre(s)"
                                 id="firstName"
                                 name="firstName"
                                 type="text"
                                 value={formProps.values.firstName}
                                 fullWidth={true}
                              />
                              <InputTextField
                                 variant="standard"
                                 label="Apellidos"
                                 id="lastName"
                                 name="lastName"
                                 type="text"
                                 value={formProps.values.lastName}
                                 fullWidth={true}
                              />
                              <InputTextField
                                 variant="standard"
                                 label="Correo electrónico"
                                 id="email"
                                 name="email"
                                 type="email"
                                 value={formProps.values.email}
                                 fullWidth={true}
                              />
                              <Box>
                                 <Typography
                                    onClick={() => {
                                       setOpenVerification(true);
                                    }}
                                    variant="caption"
                                    sx={{ ":hover": { cursor: "pointer", color: "#162c4485" } }}
                                 >
                                    Verificar correo
                                 </Typography>
                              </Box>

                              {props.isModify === true ? (
                                 <></>
                              ) : (
                                 <>
                                    <InputTextField
                                       fullWidth
                                       variant="standard"
                                       label="Contraseña"
                                       id="password"
                                       name="password"
                                       type={showPassword ? "text" : "password"}
                                       endAdornment={
                                          <>
                                             <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                             >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                             </IconButton>
                                             <Tooltip
                                                title={
                                                   "Se requiere al menos una mayúscula, una minúscula, un dígito, un carácter especial y no espacios en blanco."
                                                }
                                             >
                                                <InfoIcon fontSize="small" color="action" />
                                             </Tooltip>
                                          </>
                                       }
                                    />
                                    <InputTextField
                                       fullWidth
                                       variant="standard"
                                       type={showPassword ? "text" : "password"}
                                       id="passwordConfirmation"
                                       name="passwordConfirmation"
                                       label="Confirmar contraseña"
                                    />
                                 </>
                              )}
                              <Box
                                 display={"flex"}
                                 justifyContent={"space-between"}
                                 sx={{ columnGap: 2, alignItems: "center" }}
                              >
                                 <Autocomplete
                                    size="small"
                                    multiple
                                    limitTags={2}
                                    id="role"
                                    options={rolesList}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={rolesList}
                                    //inputValue = {formProps.values.role}
                                    value={selectedRoles}
                                    onChange={(event, newValue) => {
                                       setSelectedRoles(newValue.filter((item) => typeof item !== "string"));
                                    }}
                                    renderInput={(params) => (
                                       <TextField
                                          {...params}
                                          label="Roles"
                                          name="role"
                                          sx={{
                                             border: rolesSelectedError ? 2 : "",
                                             borderColor: rolesSelectedError ? "red" : "",
                                          }}
                                       />
                                    )}
                                    sx={{ width: 270 }}
                                 />
                                 <Button
                                    variant="contained"
                                    sx={{ height: 40, backgroundColor: "#142c44", flex: 1 }}
                                    onClick={() => {
                                       setOpenConfirmation(true);
                                    }}
                                 >
                                    Crear rol
                                 </Button>
                              </Box>
                              {rolesSelectedError ? (
                                 <FormHelperText id="my-helper-text" error>
                                    Selecciona como minimo un rol.
                                 </FormHelperText>
                              ) : null}

                              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                 <LoadingButton label={"Guardar"} isLoading={isLoading} />
                              </Box>
                           </Stack>
                        </Form>
                     )}
                  </Formik>
               </Box>
            </Box>
         </Box>
         <ConfirmationModal
            title="Confirmación"
            body={
               <div>
                  <Typography sx={{ fontSize: "14px" }}>¿Esta seguro que deseas continuar?</Typography>
                  <br />
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                     Una vez confirmado, la información registrada no podrá ser recuperada
                  </Typography>
               </div>
            }
            onConfirm={() => {
               navigate("/usuarios/registro/crear-rol");
            }}
            open={openConfirmation}
            setOpen={setOpenConfirmation}
         />
         <ConfirmationModal
            title="Enviar verificación"
            body={"Al confirmar, se reestablecerá la contraseña del usuario "}
            onConfirm={handleSendVerification}
            open={openVerification}
            setOpen={setOpenVerification}
         />
         {props.isModify ? (
            <ConfirmationModal
               open={openDeleteConfirmatinModal}
               setOpen={setOpenDeleteConfirmatinModal}
               title="Eliminar usuario interno"
               body={
                  <Typography>
                     {`¿Estás seguro de eliminar a ${props.masterUserData.firstName} ${props.masterUserData.lastName}?`}
                     <Typography>Sus datos serán borrados permanentemente.</Typography>
                  </Typography>
               }
               onConfirm={handleMasterUserDelete}
            />
         ) : (
            <></>
         )}
      </>
   );
};
