import { Box, Button, CircularProgress, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
   addUserRoleGroup,
   GetAllUserRoles,
   getImplementationUsers,
   removeRoleFromUser,
   removeUserFromCompany,
   removeUserFromGroup,
   updateCompanyAdminUser,
   updateUserEmail,
   useCreateUser,
} from "../../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { ClientConfigContext } from "../../../../../context/client/clientConfigContext";
import { catchAxiosError } from "../../../../../lib/lecosyBackendClient";
import { Form, Formik, useFormikContext } from "formik";
import { Clear } from "@mui/icons-material";
import { createUserSchema } from "../../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { LoadingButton } from "../../../../LoadingButton";
import { ConfirmationModal } from "../../../../ConfirmationModal";

const GroupImplementationComponent = () => {
   const { mutate, isLoading: isLoadingCreate } = useCreateUser();
   const { showSnackBar } = useContext(SnackBarContext);
   const { data: roles } = GetAllUserRoles();
   const { refreshGroupInfo } = useContext(ClientConfigContext);
   const [selectedUser, setSelectedUser] = useState("");
   const [isLoadingAdd, setIsLoadingAdd] = useState(false);
   const [editMode, setEditMode] = useState(false);
   const [replaceMode, setReplaceMode] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState(false);

   const UsefindImplementationUser = () => {
      const { group } = useContext(ClientConfigContext);
      const [isLoadingUser, setIsLoadingUser] = useState(false);
      const [user, setUser] = useState({
         uid: "",
         id: "",
         firstName: "",
         lastName: "",
         phoneNumber: "",
         email: "",
      });
      const [implementationUsers, setImplementationUsers] = useState([]);

      useEffect(() => {
         const getUsers = async () => {
            setIsLoadingUser(true);
            const users = await getImplementationUsers();
            setImplementationUsers(users);
            let userTemp = {
               uid: "",
               id: "",
               firstName: "",
               lastName: "",
               phoneNumber: "",
               email: "",
            };
            for (const user of group.users as any) {
               if (user?.role?.length === 0 || !user?.role) continue;
               user.role.forEach((role) => {
                  if (role.group === group._id)
                     role.roles.forEach((r) => {
                        if (r.name === "Usuario de implementación") {
                           userTemp = {
                              uid: user.uid,
                              id: user._id,
                              firstName: user.firstName,
                              lastName: user.lastName,
                              phoneNumber: user.phoneNumber,
                              email: user.email,
                           };
                        }
                     });
               });
            }
            setUser(userTemp);
            setIsLoadingUser(false);
         };

         if (group?.users) getUsers();
      }, [group]);
      return { implementationUsers, user, isLoadingUser };
   };

   const { user, isLoadingUser, implementationUsers } = UsefindImplementationUser();
   const { group } = useContext(ClientConfigContext);

   const handleSubmitCreate = async (values) => {
      if (editMode && !replaceMode) {
         setIsLoadingAdd(true);
         try {
            if (
               values.firstName === user.firstName &&
               values.lastName === user.lastName &&
               values.phone === user.phoneNumber &&
               values.email === user.email
            ) {
               showSnackBar("No hay cambios que efectuar", true);
               return setIsLoadingAdd(false);
            }
            if (values.email !== user.email) {
               await updateUserEmail(values.email, user.uid);
            }
            const response = await updateCompanyAdminUser(
               user.uid,
               values.firstName,
               values.lastName,
               values.email,
               values.phone
            );
            if (response.data) {
               showSnackBar("Usuario actualizado con exito.", false);
               await refreshGroupInfo();
               setReplaceMode(false);
               setEditMode(false);
               setIsLoadingAdd(false);
            }
         } catch (error) {
            const errorMessage = catchAxiosError(error);
            if (errorMessage.message.includes("no user found")) {
               showSnackBar("Error, intente de nuevo", true);
            }
            if (errorMessage.message.includes("email-already-exists")) {
               showSnackBar("Correo electrónico en uso", true);
            }
         }
      } else
         mutate(
            {
               userData: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phoneNumber: values.phone,
                  role: [{ group: group._id, roles: roles.find((r) => r.name === "Usuario de implementación")._id }],
               },
               additionalData: {
                  admin: false,
                  group: group._id,
                  implementationUser: true,
               },
            },
            {
               onError: (error: any) => {
                  error.response.data.message === "email already in use"
                     ? showSnackBar("Ya existe un usuario con ese correo", true)
                     : showSnackBar("Error al agregar usuario.", true);
               },
               onSuccess: async () => {
                  showSnackBar("El usuario fue agregado correctamente.", false);
                  if (replaceMode) {
                     await removeUserFromCompany(
                        user.id,
                        group._id,
                        roles.find((r) => r.name === "Usuario de implementación")._id
                     );
                  }
                  await refreshGroupInfo();
                  setReplaceMode(false);
                  setEditMode(false);
                  setIsLoadingAdd(false);
               },
            }
         );
   };

   const handleSubmit = async () => {
      setIsLoadingAdd(true);
      try {
         if (selectedUser) {
            const data = {
               group: group._id,
               roles: roles.find((r) => r.name === "Usuario de implementación")._id,
            };
            await addUserRoleGroup(data, selectedUser);
            if (replaceMode) {
               await removeUserFromGroup(
                  user.id,
                  group._id,
                  roles.find((r) => r.name === "Usuario de implementación")._id
               );
            }
            await refreshGroupInfo();
            setReplaceMode(false);
            setEditMode(false);
            showSnackBar("El usuario fue agregado correctamente", false);
         } else showSnackBar("Favor de seleccionar a un usuario existente", true);
      } catch (error) {
         showSnackBar("Error al agregar usuario.", true);
      }
      setIsLoadingAdd(false);
   };

   const handleRemove = async () => {
      if (!user) return showSnackBar("Error al eliminar usuario.", true);
      setIsLoadingAdd(true);
      try {
         const index = implementationUsers.findIndex((u) => u._id.toString() === user.id);
         if (index >= 0)
            await removeUserFromGroup(
               user.id,
               group._id,
               roles.find((r) => r.name === "Usuario de implementación")._id
            );
         else
            await removeRoleFromUser(
               user.id,
               group._id,
               roles.find((r) => r.name === "Usuario de implementación")._id,
               true
            );
         showSnackBar("El usuario fue eliminado correctamente.", false);
         setOpenConfirmation(false);
         await refreshGroupInfo();
      } catch (error) {
         showSnackBar("Error al eliminar usuario.", true);
      }
      setSelectedUser("");
      setIsLoadingAdd(false);
   };

   const FormObserver: React.FC = () => {
      const { setFieldValue, resetForm, values } = useFormikContext();
      useEffect(() => {
         if (
            (values as any).firstName === user.firstName &&
            (values as any).lastName === user.lastName &&
            (values as any).phone === user.phoneNumber &&
            (values as any).email === user.email &&
            replaceMode
         ) {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
            setFieldValue("phone", "");
            setFieldValue("email", "");
         } else if (!editMode) {
            resetForm();
         }

         if (selectedUser) {
            const userFound = implementationUsers.find((user) => user._id === selectedUser);
            setFieldValue("firstName", userFound.firstName);
            setFieldValue("lastName", userFound.lastName);
            setFieldValue("phone", userFound.phoneNumber);
            setFieldValue("email", userFound.email);
         } else if (!selectedUser && replaceMode) {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
            setFieldValue("phone", "");
            setFieldValue("email", "");
         }
         // eslint-disable-next-line
      }, [replaceMode]);
      return null;
   };

   return (
      <Box key={user.id.length} sx={{ p: 2 }}>
         {!isLoadingUser ? (
            <Stack spacing={2}>
               {(!user.firstName || replaceMode) && implementationUsers?.length > 0 && (
                  <Stack spacing={2}>
                     <Typography>Seleccionar usuario existente:</Typography>
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Select size="small" sx={{ minWidth: 300 }} value={selectedUser}>
                           {implementationUsers
                              .filter((u) => u._id !== user.id)
                              .map((option, index) => (
                                 <MenuItem
                                    key={`user-${index}`}
                                    value={option._id}
                                    onClick={() => {
                                       setSelectedUser(option._id);
                                    }}
                                 >
                                    {`${option.firstName} ${option.lastName}`}
                                 </MenuItem>
                              ))}
                        </Select>
                        {selectedUser && (
                           <IconButton
                              onClick={() => {
                                 setSelectedUser("");
                              }}
                           >
                              <Clear sx={{ fontSize: 20 }} />
                           </IconButton>
                        )}
                     </Box>
                  </Stack>
               )}
               {(!user.firstName || replaceMode) && <Typography>Crear nuevo usuario de implementación:</Typography>}
               <Formik
                  initialValues={{
                     firstName: user.firstName || "",
                     lastName: user.lastName || "",
                     phone: user.phoneNumber || "",
                     email: user.email || "",
                  }}
                  validationSchema={createUserSchema}
                  onSubmit={selectedUser ? handleSubmit : handleSubmitCreate}
               >
                  <Form>
                     <FormObserver />
                     <Box
                        sx={{
                           borderColor: "#E0E0E0",
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                           maxHeight: "420px",
                           overflowY: "auto",
                        }}
                     >
                        {editMode && !replaceMode && (
                           <Typography
                              onClick={() => setReplaceMode(!replaceMode)}
                              sx={{ fontSize: 12, cursor: "pointer", ":hover": { color: "#c4c4c4" } }}
                           >
                              Reemplazar usuario
                           </Typography>
                        )}
                        <Box sx={{ display: "flex", columnGap: 2, my: 0.5 }}>
                           <InputTextField
                              variant="outlined"
                              size="small"
                              name="firstName"
                              id="firstName"
                              type="text"
                              label="Nombre"
                              fullWidth={true}
                              disabled={(!editMode && user.firstName.length > 0) || selectedUser ? true : false}
                           />
                           <InputTextField
                              variant="outlined"
                              size="small"
                              name="lastName"
                              id="lastName"
                              type="text"
                              label="Apellido"
                              fullWidth={true}
                              disabled={(!editMode && user.firstName.length > 0) || selectedUser ? true : false}
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              variant="outlined"
                              size="small"
                              name="phone"
                              id="phone"
                              type="text"
                              label="Teléfono"
                              fullWidth={true}
                              disabled={(!editMode && user.firstName.length > 0) || selectedUser ? true : false}
                           />
                           <InputTextField
                              variant="outlined"
                              size="small"
                              name="email"
                              id="email"
                              type="text"
                              label="Correo electrónico"
                              fullWidth={true}
                              disabled={(!editMode && user.firstName.length > 0) || selectedUser ? true : false}
                           />
                        </Box>
                     </Box>
                     {(!user.firstName || editMode) && (
                        <Box
                           sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: !editMode && !user.firstName ? "flex-end" : "space-between",
                              alignItems: "center",
                           }}
                        >
                           {editMode && user.firstName && (
                              <Button
                                 variant={"outlined"}
                                 onClick={() => {
                                    setEditMode(!editMode);
                                    setSelectedUser("");
                                    setReplaceMode(false);
                                 }}
                              >
                                 {editMode ? "Cancelar" : "Editar"}
                              </Button>
                           )}
                           <LoadingButton
                              isLoading={isLoadingCreate || isLoadingAdd}
                              label="Guardar"
                              sx={{ m: "0 !important" }}
                           />
                        </Box>
                     )}
                  </Form>
               </Formik>
               {user.firstName && !editMode && (
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                     <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                           setOpenConfirmation(true);
                        }}
                     >
                        Eliminar
                     </Button>
                     <LoadingButton
                        isLoading={false}
                        label="Editar"
                        sx={{ m: "0 !important" }}
                        onClick={() => {
                           setEditMode(!editMode);
                           setSelectedUser("");
                           setReplaceMode(false);
                        }}
                     />
                  </Box>
               )}
               <ConfirmationModal
                  open={openConfirmation}
                  setOpen={setOpenConfirmation}
                  generic
                  onConfirm={() => {
                     handleRemove();
                  }}
               />
            </Stack>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               <CircularProgress />
            </Box>
         )}
      </Box>
   );
};

export default GroupImplementationComponent;
