import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import InfoIcon from "@mui/icons-material/Info";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { getMasterAccessById } from "../lib/usersBEClient";
import { ConfirmationModal } from "./ConfirmationModal";
import { getDateFromISODate } from "../const/globalConst";
import { HeadCell, TableComponent } from "./TableComponent";

export interface RoleAccordionInterface {
   _id: string;
   name: string;
   description: string;
   updatedAt: string;
   access: ModulesInterface[];
}
interface RoleAccordionProps {
   roles: RoleAccordionInterface[];
}
export interface ModulesInterface {
   _id: string;
   name: string;
   description: string;
}

const headers: HeadCell[] = [
   { field: "resource", headerName: "Nombre de sección", type: "string", width: 50, align: "center" },
   { field: "permission", headerName: "Permiso", type: "string", width: 50, align: "center" },
];

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
   ({ theme }) => ({
      display: "flex",
      alignContent: "center",
      flexDirection: "column",
      border: `1px solid ${theme.palette.divider}`,
      "&:not(:last-child)": {
         borderBottom: 0,
      },
      "&:before": {
         display: "none",
      },
   })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
   backgroundColor: "#505D6F",
   color: "white",
   display: "flex",
   flexDirection: "row-reverse",
   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
   },
   "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
   },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

export default function RoleAccordion({ roles }: Readonly<RoleAccordionProps>) {
   const [expanded, setExpanded] = React.useState<string | false>();
   const [isLoading, setIsLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [bodyModal, setBodyModal] = useState<React.ReactNode>(<></>);
   const [roleName, setRoleName] = useState("");

   const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
   };

   function getPermissionText(permission) {
      switch (permission) {
         case 0:
            return "Sin acceso";
         case 1:
            return "Solo visualizar";
         case 2:
            return "Acceso completo";
         default:
            return "Permiso desconocido";
      }
   }

   async function handleTextClick(text: string, rolename: string) {
      setIsLoading(true);
      setRoleName(rolename);
      try {
         const response = await getMasterAccessById(text);
         setIsLoading(false);
         const data = response.data;
         const formatedResources = [];
         data.access.resources.map((r) =>
            formatedResources.push({ resource: r.resource.name, permission: getPermissionText(r.permission) })
         );
         const newContent = (
            <Box overflow={"auto"}>
               <Box sx={{ marginBottom: "20px" }}>
                  <Typography fontWeight={600}> Nombre del acceso </Typography>
                  <Typography> {data.access.name} </Typography>
               </Box>
               <Typography sx={{ my: 1 }} fontWeight={600}>
                  {data.service}
               </Typography>
               <TableComponent
                  defaultColumnToOrder=""
                  defaultOrder="asc"
                  defaultRowsPerPage={5}
                  emptyDataText=""
                  rowsPerPageOptions={[]}
                  loader={isLoading}
                  headers={headers}
                  rows={formatedResources}
                  onClick={() => {}}
                  disableBorders
                  dense
               />
               <Typography>Fecha de modificación: {getDateFromISODate(data.access.updatedAt)} </Typography>
            </Box>
         );
         setBodyModal(newContent);
         setOpenModal(true);
      } catch (error) {
         console.error("Error fetching roles:", error);
         setIsLoading(false);
      }
   }

   const customAcordion = (data: ModulesInterface[], rolename: string) => {
      return (
         <>
            {data.map((access, index) => (
               <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                     sx={{
                        display: "flex",
                        alingItems: "center",
                        cursor: "pointer",
                        "&:hover": {
                           bgcolor: "#E4E4E7",
                        },
                        py: 3,
                        px: 8,
                        justifyContent: "space-between",
                     }}
                     onClick={() => handleTextClick(access._id, rolename)}
                  >
                     <Typography>{access.name}</Typography>
                     <Typography>
                        {access.description.length > 80
                           ? `${access.description.substring(0, 80)}...`
                           : access.description}
                     </Typography>
                  </Box>
                  <Divider />
               </Box>
            ))}
         </>
      );
   };

   return (
      <div>
         {roles.map((role, index) => (
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
               <AccordionSummary
                  expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "20px", color: "#D4D4D4" }} />}
                  aria-controls={`panel${index}d-content`}
                  id={`panel${index}d-header`}
               >
                  <Box sx={{ display: "flex", alingItems: "center" }}>
                     <Typography margin={"auto"}>{role.name}</Typography>
                     <Tooltip
                        title={
                           role.description.length > 100 ? `${role.description.substring(0, 100)}...` : role.description
                        }
                        placement="right-start"
                     >
                        <IconButton>
                           <InfoIcon sx={{ color: "#D4D4D4" }} />
                        </IconButton>
                     </Tooltip>
                  </Box>
                  <Box
                     sx={{
                        marginLeft: "auto",
                        marginRight: "10px",
                        display: "flex",
                        flexDirection: "column",
                     }}
                  >
                     <Typography margin={"auto"}>Última modificación</Typography>
                     <Typography margin={"auto"}>{getDateFromISODate(role.updatedAt)}</Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails sx={{ p: 0 }}>{customAcordion(role.access, role.name)}</AccordionDetails>
            </Accordion>
         ))}
         <ConfirmationModal
            open={openModal}
            setOpen={setOpenModal}
            title={roleName}
            body={bodyModal}
            onConfirm={() => {}}
            cancelButton={true}
         />
      </div>
   );
}
