import { Box, Button, Collapse, Divider, Tooltip, Typography } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { HeadCell, TableComponent } from "../../TableComponent";
import {
   GetCompanyById,
   getInvoiceById,
   getManyCompaniesById,
   useUpdateGroupInvoiceCompany,
} from "../../../lib/usersBEClient";
import { getDateAndTimeFromISODate } from "../../../const/globalConst";
import { LoadingButton } from "../../LoadingButton";
import { SnackBarContext } from "../../../context/snackBarContext";
import { InvoicesDetailsComponent } from "./InvoicesDetailsComponent";
import { ConfirmationModal } from "../../ConfirmationModal";
import { ClientCreationContext } from "../../../context/client/clientCreationContext";
import { Group } from "../../../types/BaseTypes";

const detailsHeaders: HeadCell[] = [
   { field: "check", headerName: "", type: "check", width: 50 },
   { field: "comercialName", headerName: "Nombre comercial", type: "string", width: 50 },
   { field: "businessName", headerName: "Razón social", type: "string", width: 50 },
   { field: "createdAt", headerName: "Fecha de creación", type: "string", width: 50 },
];

export const InvoicesDetailsGroupComponent = ({ onComplete }) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { selectedClient, setSelectedClient, refetch } = useContext(ClientCreationContext);
   const group = selectedClient.data as Group;

   const [selected, setSelected] = useState<readonly string[]>([]);
   const [selectedCompany, setSelectedCompany] = useState(null);
   const [isLoadingInfo, setIsLoadingInfo] = useState(true);
   const [company, setCompany] = useState([]);
   const { mutate: updateGroup } = useUpdateGroupInvoiceCompany();
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [fetching, setFetching] = useState(false);
   const [amount, setAmount] = useState(0);
   const [amountValue, setAmountValue] = useState("");

   const handleCancel = () => setSelectedClient(null);

   const fetchCompanies = async () => {
      if (group?.companies?.length > 0) {
         const companyResponseObtained = await Promise.all(
            group?.companies.map((companyG) => GetCompanyById(companyG._id))
         );
         const totalAmount = companyResponseObtained.reduce((acc, invoice) => {
            const amount = parseFloat(invoice?.invoice_details?.amount || 0);
            return acc + (isNaN(amount) ? 0 : amount);
         }, 0);
         setAmount(totalAmount);
         const companyInfo = companyResponseObtained.map((comp) => ({
            id: comp._id,
            comercialName: comp?.person_details?.comercialName || "",
            businessName: comp?.person_details?.businessName || "",
            invoice_details: comp?.invoice_details || null,
            createdAt: getDateAndTimeFromISODate(comp.createdAt),
         }));

         setCompany(companyInfo);
      } else {
         setCompany([]);
      }
      setIsLoadingInfo(false);
   };

   useEffect(() => {
      fetchCompanies();
      // eslint-disable-next-line
   }, [fetching]);

   const handleSubmit = async () => {
      if (!amountValue || isNaN(parseFloat(amountValue))) {
         showSnackBar("Valor inválido", true);
         return;
      }
      setIsLoadingInfo(true);
      try {
         await updateGroup(
            {
               invoiceCompany: selected[0],
               groupId: group._id,
               invoiceData: {
                  amount: amountValue,
                  idinvoice: selectedCompany.invoice_details._id,
               },
               multiInvoice: false,
            },
            {
               onSuccess: async () => {
                  await refetch();
                  showSnackBar("El grupo fue modificado", false);
                  setFetching(!fetching);
               },
               onError: (e) => {
                  showSnackBar("Error al modificar el cliente", true);
               },
            }
         );
      } catch (error) {
         console.log({ error });
      } finally {
         setIsLoadingInfo(false);
      }
   };

   const handleOmitir = async () => {
      try {
         updateGroup(
            {
               multiInvoice: true,
               groupId: group._id,
               invoiceCompany: null,
            },
            {
               onSuccess: async () => {
                  showSnackBar("El grupo fue modificado", false);
                  onComplete();
               },
               onError: () => {
                  showSnackBar("Error al modificar el cliente", true);
               },
            }
         );
         setIsLoadingInfo(false);
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <Box>
         <Box
            sx={{
               border: 1,
               borderColor: "#E0E0E0",
               borderRadius: 2,
            }}
         >
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
               <Box sx={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                  <Typography fontWeight={600} variant="h6" sx={{ py: 2, mx: 2 }}>
                     Registro de información para facturación
                  </Typography>
                  <Tooltip title={"Facturación individual"}>
                     <Button
                        onClick={() => setOpenConfirmationModal(true)}
                        sx={{ height: "100%", mx: 2 }}
                        variant="contained"
                        color="primary"
                     >
                        Omitir
                     </Button>
                  </Tooltip>
               </Box>
               <Divider />
            </Box>
            <Box sx={{ maxHeight: "420px", overflowY: "auto" }}>
               <Box sx={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                  <Typography sx={{ px: 2, pt: 2 }}>Selecciona una empresa del grupo empresarial</Typography>
               </Box>
               <TableComponent
                  disableBorders
                  defaultColumnToOrder="name"
                  defaultOrder="asc"
                  defaultRowsPerPage={5}
                  rowsPerPageOptions={[5, 10]}
                  emptyDataText="Este cliente no tiene empresas por el momento"
                  headers={detailsHeaders}
                  rows={company}
                  onClick={(e, row) => setSelectedCompany(row)}
                  loader={isLoadingInfo}
                  checkboxSelection={true}
                  setSelected={setSelected}
                  selectedRows={selected}
                  multiselect={false}
                  filtersInitialState={{ comercialName: "" }}
               />
               {selectedCompany?.invoice_details ? (
                  <Collapse in={selectedCompany.invoice_details}>
                     <InvoicesDetailsComponent
                        amountForGroup={amount}
                        invoiceDetails={selectedCompany.invoice_details !== null ? selectedCompany.invoice_details : {}}
                        changeAmount={setAmountValue}
                        onComplete={() => {}}
                     />
                  </Collapse>
               ) : selectedCompany && !selectedCompany.invoice_details ? (
                  <Typography sx={{ display: "flex", justifyContent: "center", my: 1, color: "red" }}>
                     La compañia seleccionada no tiene su información de factura completa
                  </Typography>
               ) : (
                  ""
               )}

               <ConfirmationModal
                  open={openConfirmationModal}
                  setOpen={setOpenConfirmationModal}
                  title={"¿Estás seguro de este movimiento?"}
                  body={<Box>La información de factura no sera modificada</Box>}
                  onConfirm={async () => {
                     handleOmitir();
                  }}
               />
            </Box>
         </Box>
         <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
               Cancelar
            </Button>
            {selectedCompany ? (
               <LoadingButton
                  label={false ? "Guardar cambios" : "Guardar"}
                  isLoading={isLoadingInfo}
                  onClick={() => {
                     setIsLoadingInfo(true);
                     handleSubmit();
                  }}
                  disabled={selectedCompany && selectedCompany.invoice_details === null}
               />
            ) : (
               <></>
            )}
         </Box>
      </Box>
   );
};
