import React from "react";
import { Box, DialogTitle, IconButton, Modal, Stack, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

interface CustomModalProps {
   open: boolean;
   setOpen: Function;
   title: string;
   timeStamp?: boolean;
   children: React.ReactNode;
   onClose?: Function;
   maxWidth?: string;
}

export const CustomModalComponent = (props: CustomModalProps) => {
   const { open, setOpen, title, timeStamp, children, onClose, maxWidth } = props;

   const handleClose = () => {
      setOpen(false);
      onClose && onClose();
   };

   return (
      <Modal open={open} onClose={handleClose} sx={{ display: "flex" }}>
         <Box sx={{ m: "auto", minWidth: "30%", maxWidth: maxWidth ?? "60%" }}>
            <Box bgcolor={"#162c44"} px={1} sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
               <DialogTitle sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                  <Stack>
                     <Typography fontSize={20} color={"white"} fontWeight={600}>
                        {title}
                     </Typography>
                     {timeStamp && (
                        <Typography color={"white"} fontWeight={600}>
                           {new Date().toLocaleDateString("es-MX", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                           })}
                        </Typography>
                     )}
                  </Stack>
                  <IconButton onClick={() => handleClose()}>
                     <CloseIcon sx={{ color: "white" }} />
                  </IconButton>
               </DialogTitle>
            </Box>
            <Box
               sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: "thin 0px",
                  bgcolor: "white",
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
               }}
            >
               {children}
            </Box>
         </Box>
      </Modal>
   );
};
