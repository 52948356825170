import { useState, useContext, useEffect } from "react";
import { Box, Typography, Divider, Button, Drawer, CircularProgress } from "@mui/material";
import { FilterComponent } from "../../../FilterComponent";
import EditIcon from "@mui/icons-material/Edit";
import { AddCircleOutline } from "@mui/icons-material";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { ClientConfigContext } from "../../../../context/client/clientConfigContext";
import { AddModifyCompanyBranchDrawer } from "../../Drawer/AddModifyCompanyBranchDrawer";
import { UseBranchesByCompanyId } from "../../../../lib/usersBEClient";
import { UserContext } from "../../../../context/UserContext";

interface BranchesTableProps {
   branches: any[];
}

export const BranchesTableClientInfo = (props: BranchesTableProps) => {
   const { clientInfo } = useContext(ClientConfigContext);
   const [openAddBranchDrawer, setOpenAddBranchDrawer] = useState(false);
   const [openEditBranchDrawer, setOpenEditBranchDrawer] = useState(false);
   const [branchSelected, setBranchSelected] = useState(null);
   const [allBranches, setAllBranches] = useState(props.branches);
   const [refetchBranches, setRefetchBranches] = useState<boolean>(false);
   const [newBranchList, setNewBranchList] = useState(props.branches);
   const [refetch, setRefetch] = useState(false);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Clientes");

   useEffect(() => {
      const refetch = async () => {
         setAllBranches(props.branches);
         if (refetchBranches) {
            const branchesList = await UseBranchesByCompanyId(clientInfo._id);
            setNewBranchList(branchesList);
            setAllBranches(branchesList);
            setRefetch(true);
         }
      };
      refetch();
      setRefetchBranches(false);
      // eslint-disable-next-line
   }, [refetchBranches, props.branches]);

   const branchesColumns = (): HeadCell[] => {
      const headers: HeadCell[] = [
         { field: "name", headerName: "Nombre", type: "string", width: 50 },
         { field: "city", headerName: "Ciudad", type: "string", width: 50 },
         { field: "state", headerName: "Estado", type: "string", width: 50 },
         { field: "disabled", headerName: "Estatus", type: "disabled", width: 50 },
         { field: "createdAt", headerName: "Fecha de creación", type: "date", width: 50 },
      ];
      if (!access) {
         headers.push({ field: "icon", headerName: "Modificar", type: "button", width: 50 });
      }
      return headers;
   };

   return (
      <>
         {props.branches ? (
            <Box sx={{ bgcolor: "white", boxShadow: 2, borderRadius: 2, width: "100%" }}>
               <Typography fontSize={16} fontWeight={600} sx={{ p: 2 }}>
                  Sucursales
               </Typography>
               <Divider />
               <Box sx={{ px: 2 }}>
                  <FilterComponent
                     originaltableOneRows={refetch ? newBranchList : props.branches}
                     tableOneFilteredRows={allBranches}
                     setTableOneFilteredRows={setAllBranches}
                     filters={[]}
                     disableRefresh
                     searchBar={
                        !access ? (
                           <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
                              <Button
                                 onClick={() => setOpenAddBranchDrawer(true)}
                                 variant="contained"
                                 sx={{ minHeight: "100%" }}
                                 size="small"
                                 fullWidth
                              >
                                 <Typography variant="button" sx={{ mr: 1 }}>
                                    Agregar sucursal
                                 </Typography>
                                 <AddCircleOutline />
                              </Button>
                           </Box>
                        ) : (
                           <></>
                        )
                     }
                  />
               </Box>
               <Divider />
               <TableComponent
                  defaultColumnToOrder="name"
                  defaultOrder="asc"
                  defaultRowsPerPage={5}
                  emptyDataText="No hay sucursales registradas por el momento"
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  loader={false}
                  headers={branchesColumns()}
                  rows={allBranches}
                  onClick={(e, row) => {
                     setOpenEditBranchDrawer(true);
                     setBranchSelected(row);
                  }}
                  icon={<EditIcon fontSize="small" />}
                  disableBorders
               />
            </Box>
         ) : (
            <Box display={"flex"} flex={1} justifyContent={"center"}>
               <CircularProgress />
            </Box>
         )}
         <Drawer
            anchor="right"
            open={openAddBranchDrawer || openEditBranchDrawer}
            hideBackdrop
            onClose={() => {
               setOpenAddBranchDrawer(false);
               setOpenEditBranchDrawer(false);
            }}
            sx={{ maxWidth: 450 }}
         >
            {openAddBranchDrawer ? (
               <AddModifyCompanyBranchDrawer
                  isModify={false}
                  open={openAddBranchDrawer}
                  setOpen={setOpenAddBranchDrawer}
                  companyId={clientInfo._id}
                  hasCDModule={false}
                  setRefetch={setRefetchBranches}
               />
            ) : openEditBranchDrawer ? (
               <AddModifyCompanyBranchDrawer
                  isModify={true}
                  open={openEditBranchDrawer}
                  setOpen={setOpenEditBranchDrawer}
                  companyId={clientInfo._id}
                  branchData={branchSelected}
                  hasCDModule={false}
                  setRefetch={setRefetchBranches}
               />
            ) : (
               <></>
            )}
         </Drawer>
      </>
   );
};
