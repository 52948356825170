import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../../TabPanel";
import { GroupDetailsComponent } from "../../SubComponents/GroupDetailsComponent";
import { useContext, useState } from "react";
import { ClientCreationContext } from "../../../../context/client/clientCreationContext";
import { CompaniesSelectorComponet } from "../../SubComponents/CompaniesSelectorComponent";
import { UserAdminGroupComponent } from "../../SubComponents/UserAdminGroupComponent";
import { UserDetailsComponent } from "../../SubComponents/UserDetailsComponent";
import { Group } from "../../../../types/BaseTypes";
import { InvoicesDetailsGroupComponent } from "../../SubComponents/InvoicesDetailsGroupComponent";

const GroupClienCreationTabsContainer = () => {
   const { selectedClient, setSelectedClient } = useContext(ClientCreationContext);
   const [activeStep, setActiveStep] = useState(0);

   const handleChange = (_event: React.SyntheticEvent, newValue: number) => setActiveStep(newValue);
   const handleNext = () => setActiveStep((a) => a + 1);
   const handleComplete = () => setSelectedClient(null);

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            p: 2,
            minWidth: 1000,
         }}
      >
         <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1, px: 2 }}>
               <Box
                  sx={{
                     border: 1,
                     borderColor: "#E0E0E0",
                  }}
               >
                  <Tabs
                     orientation="vertical"
                     value={activeStep}
                     onChange={handleChange}
                     aria-label="Vertical tabs example"
                  >
                     {["Grupo", "Empresas", "Administrador del sistema", "Usuarios", "Facturas"].map((step, index) => (
                        <Tab label={step} disabled={!selectedClient?.data && index !== 0} />
                     ))}
                  </Tabs>
               </Box>
            </Box>
            <Box
               sx={{
                  flex: 3,
                  display: "flex",
                  px: 2,
                  flexDirection: "column",
                  maxHeight: 570,
                  overflowY: "auto",
               }}
            >
               <TabPanel value={activeStep} index={0}>
                  <GroupDetailsComponent onComplete={handleNext} />
               </TabPanel>
               <TabPanel value={activeStep} index={1}>
                  <CompaniesSelectorComponet onComplete={handleNext} />
               </TabPanel>
               <TabPanel value={activeStep} index={2}>
                  <UserAdminGroupComponent onComplete={handleNext} value={activeStep} />
               </TabPanel>
               <TabPanel value={activeStep} index={3}>
                  <UserDetailsComponent
                     onComplete={handleNext}
                     value={activeStep}
                     group={selectedClient.data as Group}
                  />
               </TabPanel>
               <TabPanel value={activeStep} index={4}>
                  <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                     <InvoicesDetailsGroupComponent onComplete={handleComplete} />
                  </Box>
               </TabPanel>
            </Box>
         </Box>
      </Box>
   );
};

export default GroupClienCreationTabsContainer;
