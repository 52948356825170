import { Box, Typography } from "@mui/material";
import { TabPanelProps } from "./ClientInformation/ClientInfoTabs";

export function TabPanel(props: Readonly<TabPanelProps>) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && <Box>{children}</Box>}
      </div>
   );
}
