import { Box, Stack } from "@mui/material";
import GovernanceCoordination from "../../../Clients/SubComponents/ServiceInfoForm/Governance/subcomponents/CompanyCoordination";
import { GoverningBodiesComponent } from "../../../Clients/SubComponents/ServiceInfoForm/Governance/GoverningBodiesComponent";
import { GroupServiceCompaniesTable } from "../GroupServiceCompaniesTable";
import { GroupUsersByModule } from "../GroupUsersByModule";

const governanceServiceId = "64e7851d978b71bd4f011ee4";

const ConsultiveGroup = () => {
   return (
      <Stack spacing={2}>
         <Box
            sx={{
               px: 3,
               py: 2,
               borderRadius: 1,
               alignItems: "center",
               boxShadow: 2,
               backgroundColor: "white",
            }}
         >
            <GoverningBodiesComponent group />
         </Box>
         <GroupUsersByModule serviceCode="GC" />
         <Box
            sx={{
               px: 3,
               py: 2,
               borderRadius: 1,
               alignItems: "center",
               boxShadow: 2,
               backgroundColor: "white",
            }}
         >
            <GovernanceCoordination group />
         </Box>
         <GroupServiceCompaniesTable serviceId={governanceServiceId} />
      </Stack>
   );
};

export default ConsultiveGroup;
