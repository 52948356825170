import { Box, Stack, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../../TableComponent";
import {
   getCompaniesAndBranchCountByGroup,
   getManyCompaniesById,
   getManyCorporateData,
} from "../../../lib/usersBEClient";
import { useCallback, useContext, useEffect, useState } from "react";
import { ClientConfigContext } from "../../../context/client/clientConfigContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { useNavigate } from "react-router-dom";

export const GroupServiceCompaniesTable = ({ serviceId }) => {
   const navigate = useNavigate();
   const { group } = useContext(ClientConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [companiesRow, setCompaniesRow] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   let columns: HeadCell[] = [
      { field: "logo", headerName: "Logo", type: "string" },
      { field: "cname", headerName: "Empresa", type: "string" },
      serviceId === "64e784dd978b71bd4f011ee3"
         ? { field: "branches", headerName: "Sucursales", type: "string" }
         : { field: "councilType", headerName: "Tipo de consejo", type: "string" },
      { field: "status", headerName: "Estatus", type: "disabled" },
   ];

   const fetchCompaniesInfo = useCallback(async () => {
      setIsLoading(true);
      try {
         const [groupCompanies, complaintsCompanyGroup, companyCorporateDatas] = await Promise.all([
            getManyCompaniesById(group.companies.map((c) => c._id)),
            getCompaniesAndBranchCountByGroup(group._id),
            getManyCorporateData(group.companies.map((c) => c._id)),
         ]);
         const filteredCompanies = groupCompanies.filter((company) =>
            company.company_details.servicesDetails.some((s) => s.serviceId === serviceId && !s.disable)
         );
         const companyData = filteredCompanies.map((company) => {
            const corporateData = companyCorporateDatas.find((c) => c.companyId === company._id);
            const complaintData = complaintsCompanyGroup.find((c) => c._id === company._id);
            return {
               _id: company._id,
               logo: <img src={company.logo} alt="logo" style={{ maxWidth: "140px", maxHeight: "50px" }} />,
               cname: company.person_details.comercialName,
               status: company.disabled,
               councilType: corporateData?.councilType ?? "---",
               branches: complaintData?.branches ?? "0",
            };
         });

         setCompaniesRow(companyData);
      } catch (error) {
         showSnackBar("Error al conseguir empresas", true);
      } finally {
         setIsLoading(false);
      }
   }, [group, serviceId]);

   useEffect(() => {
      fetchCompaniesInfo();
   }, [fetchCompaniesInfo]);

   const handleClick = async (_e, row) => {
      const screen = "clientes/" + row._id;
      return navigate(`/${screen}`);
   };

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography sx={{ fontWeight: 700 }}>Empresas</Typography>
         </Stack>
         <TableComponent
            defaultColumnToOrder="empresa"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            emptyDataText={
               companiesRow.length > 0 ? "No se encontró una empresa con ese nombre" : "No existen empresas registradas"
            }
            disableBorders
            headers={columns}
            loader={isLoading}
            rows={companiesRow}
            rowsPerPageOptions={[5, 10, 20]}
            onClick={handleClick}
         />
      </Box>
   );
};
