import { ExpandMore, Info, PictureAsPdf, Visibility } from "@mui/icons-material";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   CircularProgress,
   Divider,
   Grid,
   IconButton,
   Popover,
   Stack,
   Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ClientConfigContext } from "../../../../../context/client/clientConfigContext";
import { getCorporateData, getUrlS3 } from "../../../../../lib/usersBEClient";
import StringToHTMLOutput from "../../../../Inputs/TextEditor/StringToHTMLOutput";
import { CustomModalComponent } from "../../../../CustomModalComponent";
import MembersList from "./subcomponents/membersList";
import { getDateFromISODate } from "../../../../../const/globalConst";

interface document {
   name: string;
   size: number;
}

interface corporateData {
   _id: string;
   societyKind: string;
   legalConcept: string;
   legalConceptOther: string;
   physicalAddress: string;
   incorporationYear: Date | string;
   taxRegime: string;
   operatingCountries: [];
   watchman: [];
   socialObject: [];
   administration: string;
   legalRepresentative: string;
   councilType: string;
   administratorFaculties: string;
   filePact: document;
   updatedAt: Date;
   businessFolio: string;
   notaryName: string;
   notaryNumber: number;
   recordType: string;
   actNumber: number;
   grantDate: Date;
}
const useFetchCorporateData = (companyId) => {
   const [corporateData, setCorporateData] = useState<corporateData>(null);
   const [isLoading, setIsLoading] = useState(false);
   useEffect(() => {
      const checkData = async () => {
         setIsLoading(true);
         const data = await getCorporateData(companyId);
         setCorporateData(data);
         setIsLoading(false);
      };
      checkData();
   }, [companyId]);

   return { corporateData, isLoading };
};

const CorporateDataInformation = (props: { checked: boolean; service: any }) => {
   const { clientInfo } = useContext(ClientConfigContext);
   const { corporateData, isLoading } = useFetchCorporateData(clientInfo._id);
   const [openFaculties, setOpenFaculties] = useState(false);
   const [openSocialObject, setOpenSocialObject] = useState(false);
   const [openWatchman, setOpenWatchman] = useState(false);
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const openPopover = Boolean(anchorEl);

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setAnchorEl(null);
   };

   const getURL = async (fileName: string) => {
      const activateURL: string = await getUrlS3(
         "files-lecosy",
         { folder: `gc/companies/${clientInfo._id}/informacion-societaria` },
         fileName
      );
      window.open(activateURL, "_blank", "noopener,noreferrer");
   };
   const handleCloseModals = () => {
      setOpenWatchman(false);
      setOpenFaculties(false);
      setOpenSocialObject(false);
   };
   return (
      <>
         <Stack
            spacing={1}
            sx={{ pt: 1, opacity: props.checked ? 1 : 0.5, pointerEvents: props.checked ? "auto" : "none" }}
         >
            {!isLoading ? (
               <>
                  <Box sx={{ bgcolor: "#EFEFEF", borderRadius: 2.5, p: 1 }}>
                     <Box sx={{ mx: 3, my: 1, display: "flex", justifyContent: "space-between" }}>
                        <Typography fontWeight={600} fontSize={17} alignSelf={"center"}>
                           Información societaria
                        </Typography>
                     </Box>
                     <Grid container sx={{ mx: 3, my: 2, rowGap: 1 }}>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Denominación/Razón social
                           </Typography>
                           <Typography fontSize={14}>{clientInfo?.person_details?.businessName ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Nombre comercial:
                           </Typography>
                           <Typography fontSize={14}>{clientInfo?.person_details?.comercialName ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Tipo de sociedad:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.societyKind ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Figura jurídica:
                           </Typography>
                           <Typography fontSize={14}>
                              {corporateData?.legalConcept === "Otro"
                                 ? corporateData?.legalConceptOther
                                 : corporateData?.legalConcept ?? "---"}
                           </Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Fecha de constitución:
                           </Typography>
                           <Typography
                              fontSize={14}
                              color={
                                 (typeof corporateData?.incorporationYear === "string" &&
                                 corporateData?.incorporationYear?.length < 6
                                    ? "red"
                                    : null) ?? "---"
                              }
                           >
                              {typeof corporateData?.incorporationYear === "string" &&
                              corporateData?.incorporationYear?.length < 6
                                 ? "Actualizar fecha"
                                 : !corporateData
                                 ? "---"
                                 : getDateFromISODate(corporateData?.incorporationYear, true)}
                           </Typography>
                        </Grid>
                        {corporateData?.socialObject && (
                           <Grid item xs={4}>
                              <Typography fontWeight={600} fontSize={14} my={1}>
                                 Órgano de vigilancia:
                              </Typography>
                              <Button
                                 sx={{ bgcolor: "#D9D9D9", borderRadius: 2, height: 30 }}
                                 onClick={() => setOpenWatchman(true)}
                              >
                                 Mostrar todo
                              </Button>
                           </Grid>
                        )}
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Folio mercantil:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.businessFolio ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Nombre del notario:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.notaryName ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Número de notaria:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.notaryNumber ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Tipo de acta:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.recordType ?? "---"}</Typography>
                        </Grid>
                     </Grid>
                  </Box>
                  <Accordion
                     disableGutters
                     elevation={0}
                     sx={{
                        bgcolor: "#EFEFEF",
                        borderRadius: "10px !important",
                        boxShadow: 0,
                        "&:before": { backgroundColor: "transparent !important" },
                     }}
                  >
                     <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography fontWeight={600} fontSize={14} alignSelf={"center"}>
                           Estructura accionaria
                        </Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Stack spacing={1}>
                           <MembersList hideFullInfo />
                        </Stack>
                     </AccordionDetails>
                  </Accordion>
                  <Box
                     sx={{
                        bgcolor: "#EFEFEF",
                        borderRadius: 2.5,
                        p: 1,
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                     }}
                  >
                     <Grid container sx={{ mx: 3, my: 2 }}>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Objeto social:
                           </Typography>
                           {corporateData?.socialObject ? (
                              <Button
                                 sx={{ bgcolor: "#D9D9D9", borderRadius: 2, height: 30 }}
                                 onClick={() => setOpenSocialObject(true)}
                              >
                                 Mostrar todo
                              </Button>
                           ) : (
                              "---"
                           )}
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Pactos de socios:
                           </Typography>
                           {corporateData?.filePact ? (
                              <Button
                                 sx={{ height: 30 }}
                                 variant="outlined"
                                 onClick={async () => {
                                    getURL(corporateData.filePact.name);
                                 }}
                              >
                                 <Typography>Ver PDF</Typography>
                                 <PictureAsPdf sx={{ mx: 1 }} />
                              </Button>
                           ) : (
                              "---"
                           )}
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Régimen fiscal:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.taxRegime ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Órgano de administración:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.administration ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Tipo de consejo:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.councilType ?? "---"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Apoderado legal:
                           </Typography>
                           <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
                              {corporateData?.legalRepresentative ? (
                                 <>
                                    <Typography fontSize={14}>{corporateData?.legalRepresentative ?? "---"}</Typography>

                                    <IconButton onClick={handleClick} size="small">
                                       <>
                                          <Visibility sx={{ color: "gray", fontSize: 20 }} />
                                          <Popover open={openPopover} anchorEl={anchorEl} onClose={handlePopoverClose}>
                                             <Stack
                                                onMouseLeave={handlePopoverClose}
                                                sx={{
                                                   width: 200,
                                                   p: 3,
                                                   bgcolor: "#FFFFFF",
                                                   rowGap: 2,
                                                   borderRadius: 3,
                                                }}
                                             >
                                                <Box>
                                                   <Typography fontWeight={600} fontSize={14}>
                                                      Apoderado legal:
                                                   </Typography>
                                                   <Typography fontSize={14}>
                                                      {corporateData?.legalRepresentative ?? "---"}
                                                   </Typography>
                                                </Box>
                                                <Box>
                                                   <Typography fontWeight={600} fontSize={14}>
                                                      Número de acta:
                                                   </Typography>
                                                   <Typography fontSize={14}>
                                                      {corporateData?.actNumber ?? "---"}
                                                   </Typography>
                                                </Box>
                                                <Box>
                                                   <Typography fontWeight={600} fontSize={14}>
                                                      Fecha de otorgamiento:
                                                   </Typography>
                                                   <Typography fontSize={14}>
                                                      {(corporateData?.grantDate &&
                                                         getDateFromISODate(corporateData?.grantDate, true)) ??
                                                         "---"}
                                                   </Typography>
                                                </Box>
                                             </Stack>
                                             <Box></Box>
                                          </Popover>
                                       </>
                                    </IconButton>
                                 </>
                              ) : (
                                 "---"
                              )}
                           </Box>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Países en los que opera:
                           </Typography>
                           <Typography fontSize={14}>
                              {corporateData?.operatingCountries.join(", ") ?? "---"}
                           </Typography>
                        </Grid>
                        {corporateData?.administratorFaculties && (
                           <Grid item xs={4}>
                              <Typography fontWeight={600} fontSize={14} my={1}>
                                 Poderes y facultades de la administración:
                              </Typography>
                              <Button
                                 sx={{ bgcolor: "#D9D9D9", borderRadius: 2, height: 30 }}
                                 onClick={() => setOpenFaculties(true)}
                              >
                                 Mostrar todo
                              </Button>
                           </Grid>
                        )}
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Domicilio físico:
                           </Typography>
                           <Typography fontSize={14}>{corporateData?.physicalAddress ?? "---"}</Typography>
                        </Grid>
                     </Grid>
                  </Box>

                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center", p: 1 }}>
                     <Typography fontWeight={600} fontSize={14} alignSelf={"center"}>
                        {`Ultima modificación: ${
                           corporateData?.updatedAt ? getDateFromISODate(corporateData?.updatedAt) : "---"
                        }`}
                     </Typography>
                  </Box>
                  <CustomModalComponent open={openFaculties} setOpen={handleCloseModals} title="Poderes y facultades">
                     <Box sx={{ maxHeight: 500, overflowY: "auto", width: 500 }}>
                        <StringToHTMLOutput text={corporateData?.administratorFaculties} sx={{ p: 3 }} />
                     </Box>
                     <Button
                        sx={{
                           display: "flex",
                           width: "100%",
                           bgcolor: "#E0E0E0",
                           borderBottomLeftRadius: 8,
                           borderBottomRightRadius: 8,
                        }}
                        onClick={() => setOpenFaculties(false)}
                     >
                        Cerrar
                     </Button>
                  </CustomModalComponent>
                  <CustomModalComponent open={openSocialObject} setOpen={handleCloseModals} title="Objeto social">
                     <Box sx={{ maxHeight: 500, overflowY: "auto", width: 500, px: 2, py: 2 }}>
                        {corporateData?.socialObject.map((o, i) => (
                           <Typography key={i} fontSize={14} sx={{ p: 0.5 }}>
                              {i + 1}. {o}
                           </Typography>
                        ))}
                     </Box>
                     <Button
                        sx={{
                           display: "flex",
                           width: "100%",
                           bgcolor: "#E0E0E0",
                           borderBottomLeftRadius: 8,
                           borderBottomRightRadius: 8,
                        }}
                        onClick={() => setOpenSocialObject(false)}
                     >
                        Cerrar
                     </Button>
                  </CustomModalComponent>
                  <CustomModalComponent open={openWatchman} setOpen={handleCloseModals} title="Órgano de vigilancia">
                     <Box sx={{ maxHeight: 500, overflowY: "auto", width: 320, px: 2, py: 2 }}>
                        {corporateData?.watchman.map((o, i) => (
                           <Typography key={i} fontSize={14} sx={{ p: 0.5 }}>
                              {i + 1}. {o}
                           </Typography>
                        ))}
                     </Box>
                     <Button
                        sx={{
                           display: "flex",
                           width: "100%",
                           bgcolor: "#E0E0E0",
                           borderBottomLeftRadius: 8,
                           borderBottomRightRadius: 8,
                        }}
                        onClick={() => setOpenWatchman(false)}
                     >
                        Cerrar
                     </Button>
                  </CustomModalComponent>
               </>
            ) : (
               isLoading && (
                  <Box
                     sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignContent: "center",
                     }}
                  >
                     <CircularProgress sx={{ my: 3 }} />
                  </Box>
               )
            )}
         </Stack>
      </>
   );
};

export default CorporateDataInformation;
