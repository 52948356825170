import { Box, Typography, Button, InputAdornment, Stack } from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { useContext, useEffect } from "react";
import { InputTextField } from "../../Inputs/InputTextField";
import { addInvoiceDetailsSchema } from "../../../lib/Validations/inputSchemas";
import { SnackBarContext } from "../../../context/snackBarContext";
import { CreateInvoiceDetails, UpdateInvoiceDetails } from "../../../lib/usersBEClient";
import { LoadingButton } from "../../LoadingButton";
import { Companies, InvoiceDetails } from "../../../types/BaseTypes";
import { ClientCreationContext } from "../../../context/client/clientCreationContext";

interface InvoiceDetailsComponentProps {
   onComplete: Function;
   changeAmount?: Function;
   amountForGroup?: number;
   invoiceDetails?: InvoiceDetails;
}

export const InvoicesDetailsComponent = (props: InvoiceDetailsComponentProps) => {
   const { selectedClient, setSelectedClient, refetch, isLoading } = useContext(ClientCreationContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate, isLoading: isLoadingCreate } = CreateInvoiceDetails();
   const { mutate: updateMutate, isLoading: isLoadingUpdate } = UpdateInvoiceDetails();

   const selectedCompany = selectedClient.data as Companies;
   const invoiceDetails = (props.invoiceDetails || selectedCompany?.invoice_details) as InvoiceDetails;

   const handleCancel = () => setSelectedClient(null);

   const handleSubmit = async (values) => {
      try {
         if (invoiceDetails) {
            updateMutate(
               {
                  invoice_details: {
                     denomination: values.denomination,
                     rfc: values.rfc,
                     city: values.city,
                     state: values.state,
                     address: values.address,
                     suburb: values.suburb,
                     phone: values.phone,
                     zipCode: values.zipCode,
                     regime: values.regime,
                     amount: parseFloat(values.amount).toFixed(2).toString(),
                     email: values.email,
                  },
                  id: invoiceDetails._id,
               },
               {
                  onError: (error: any) => {
                     showSnackBar("Error al actualizar detalles de facturación", true);
                  },
                  onSuccess: async () => {
                     showSnackBar("Los detalles de facturación fueron actualizados correctamente.", false);
                     props.onComplete();
                     refetch();
                  },
               }
            );
         } else {
            mutate(
               {
                  invoice_details: {
                     denomination: values.denomination,
                     rfc: values.rfc,
                     city: values.city,
                     state: values.state,
                     address: values.address,
                     suburb: values.suburb,
                     phone: values.phone,
                     zipCode: values.zipCode,
                     regime: values.regime,
                     amount: parseFloat(values.amount).toFixed(2).toString(),
                     email: values.email,
                  },
                  companyId: selectedCompany._id,
               },
               {
                  onError: (error: any) => {
                     showSnackBar("Error al agregar detalles de facturación", true);
                  },
                  onSuccess: async () => {
                     showSnackBar("Los detalles de facturación fueron agregados correctamente.", false);
                     props.onComplete();
                     refetch();
                  },
               }
            );
         }
      } catch (error) {
         console.log({ error });
      }
   };

   const FormObserver: React.FC = () => {
      const { values } = useFormikContext();
      useEffect(() => {
         const valuess: any = values;
         if (props.changeAmount) {
            props.changeAmount(valuess.amount);
         }
      }, [values]);

      return null;
   };

   const initialValuesFormik = () => {
      if (invoiceDetails) {
         if (props.amountForGroup && props.amountForGroup > 0) {
            return {
               ...invoiceDetails,
               amount: props.amountForGroup,
            };
         } else {
            return {
               ...invoiceDetails,
            };
         }
      } else {
         return {
            denomination:
               !isLoading && selectedCompany?.person_details.businessName
                  ? selectedCompany.person_details.businessName
                  : "",
            rfc: !isLoading && invoiceDetails ? invoiceDetails.rfc : "",
            address: !isLoading && invoiceDetails ? invoiceDetails.address : "",
            suburb: !isLoading && invoiceDetails ? invoiceDetails.suburb : "",
            phone: !isLoading && invoiceDetails ? invoiceDetails.phone : "",
            regime: !isLoading && invoiceDetails ? invoiceDetails.regime : "",
            zipCode: !isLoading && invoiceDetails ? invoiceDetails.zipCode : "",
            state: !isLoading && invoiceDetails ? invoiceDetails.state : "",
            city: !isLoading && invoiceDetails ? invoiceDetails.city : "",
            amount:
               !isLoading && invoiceDetails && !props.amountForGroup
                  ? parseFloat(invoiceDetails.amount).toFixed(2).toString()
                  : "",
            email: !isLoading && invoiceDetails ? invoiceDetails.email : "",
         };
      }
   };
   return (
      <Box flex={1}>
         <Formik
            initialValues={initialValuesFormik()}
            validationSchema={addInvoiceDetailsSchema}
            onSubmit={handleSubmit}
         >
            {(propsFormik) => (
               <Form>
                  <FormObserver />
                  <Box sx={{ border: !props.amountForGroup ? 1 : 0, borderColor: "#E0E0E0", borderRadius: 2, flex: 1 }}>
                     {!props.amountForGroup && (
                        <Box sx={{ p: 2 }}>
                           <Typography fontWeight={600} variant="h6">
                              Registro de Información para facturación
                           </Typography>
                        </Box>
                     )}
                     <Box
                        sx={{
                           p: 4,
                           borderTop: 1,
                           borderColor: "#E0E0E0",
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                           maxHeight: "420px",
                           overflowY: props.amountForGroup ? "hidden" : "auto",
                        }}
                     >
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={propsFormik.values.denomination}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="denomination"
                              id="denomination"
                              type="text"
                              disabled
                              label="Denominación o razón social"
                              fullWidth={true}
                           />
                           <InputTextField
                              value={propsFormik.values.rfc}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="rfc"
                              id="rfc"
                              type="text"
                              label="RFC"
                              disabled={!!props.amountForGroup}
                              fullWidth={true}
                           />
                        </Box>
                        <InputTextField
                           value={propsFormik.values.email}
                           sx={{ my: 0.5, flex: 1 }}
                           variant="outlined"
                           size="small"
                           name="email"
                           id="email"
                           type="text"
                           disabled={!!props.amountForGroup}
                           label="Correo electrónico"
                        />
                        {!props.amountForGroup ? (
                           <Typography fontWeight={600} variant="h6">
                              Domicilio fiscal
                           </Typography>
                        ) : null}
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={propsFormik.values.city}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="city"
                              id="city"
                              type="text"
                              disabled={!!props.amountForGroup}
                              label="Ciudad"
                              fullWidth={true}
                           />
                           <InputTextField
                              value={propsFormik.values.state}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="state"
                              id="state"
                              type="text"
                              disabled={!!props.amountForGroup}
                              label="Estado"
                              fullWidth={true}
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={propsFormik.values.address}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="address"
                              id="address"
                              type="text"
                              disabled={!!props.amountForGroup}
                              label="Calle/Avenida"
                              fullWidth={true}
                           />
                           <InputTextField
                              value={propsFormik.values.suburb}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="suburb"
                              id="suburb"
                              type="text"
                              disabled={!!props.amountForGroup}
                              label="Colonia"
                              fullWidth={true}
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={propsFormik.values.phone}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="phone"
                              id="phone"
                              type="text"
                              disabled={!!props.amountForGroup}
                              label="Teléfono"
                              fullWidth={true}
                           />
                           <InputTextField
                              value={propsFormik.values.zipCode}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="zipCode"
                              id="zipCode"
                              type="text"
                              disabled={!!props.amountForGroup}
                              label="Código postal"
                              fullWidth={true}
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              value={invoiceDetails ? invoiceDetails.regime : propsFormik.values.regime}
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="regime"
                              id="regime"
                              disabled={!!props.amountForGroup}
                              type="text"
                              label="Régimen fiscal"
                              fullWidth={true}
                           />
                           <Stack direction={"row"} display={"flex"} width={"100%"}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="standard"
                                 size="small"
                                 name="amount"
                                 id="amount"
                                 type="text"
                                 label="Monto a facturar"
                                 startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              />
                              <Typography variant="body2" alignSelf={"baseline"} width={"40%"} mx={2} marginTop={3}>
                                 antes de I.V.A.
                              </Typography>
                           </Stack>
                        </Box>
                     </Box>
                  </Box>
                  {!props.amountForGroup && (
                     <Box>
                        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                           <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
                              Cancelar
                           </Button>
                           <LoadingButton
                              label={invoiceDetails ? "Guardar cambios" : "Continuar"}
                              isLoading={isLoadingUpdate || isLoadingCreate}
                           />
                        </Box>
                     </Box>
                  )}
               </Form>
            )}
         </Formik>
      </Box>
   );
};
