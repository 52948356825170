import { useEffect, useState, useContext } from "react";
import { getDateFromISODate } from "../const/globalConst";
import { getAllComplaints } from "../lib/lecosyBackendClient";
import { Box, Typography, CircularProgress, Collapse } from "@mui/material";
import { SingleLineChart, LineChart } from "../components/charts/LineChart";
import { UserContext } from "../context/UserContext";
import { getAllCompanies } from "../lib/usersBEClient";
import { Stack } from "@mui/system";
import { logo_Isotipo } from "../assets/DrawerAppScreenIcons";

export const DashboardScreen = () => {
   const { GetAccess } = useContext(UserContext);
   const CDaccess = GetAccess(["Denuncias externas", "Denuncias internas", "Banco de consultas"]);
   if (!CDaccess)
      return (
         <Stack
            direction={"column"}
            spacing={3}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"80vh"}
            sx={{ opacity: 0.5 }}
         >
            <Box
               sx={{
                  width: "18vw",
                  height: "18vw",
                  backgroundImage: `url(${logo_Isotipo})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
               }}
            />
            <Typography
               fontWeight={600}
               fontSize={"20px"}
               marginLeft={1}
               py={0.5}
               sx={{ borderBottom: 2, borderBottomColor: "#162c44" }}
            >
               "La solución en materia de gestión de riesgo y cumplimiento."
            </Typography>
         </Stack>
      );
   return (
      <>
         <Collapse unmountOnExit in={CDaccess}>
            {CDSection()}
         </Collapse>
      </>
   );
};

const CDSection = () => {
   const [complaintLoader, setComplaintLoader] = useState(true);
   const [companyLoader, setCompanyLoader] = useState(true);

   const [companyChart, setCompanyChart] = useState({
      title: "Empresas",
      chart: <Box />,
      colors: ["#8a95a0", "#162c44"],
   });
   const [complaintChart, setComplaintChart] = useState({
      title: "Denuncias interpuestas",
      chart: <Box />,
   });

   function getDates() {
      const dates: any = [];
      for (let i = 5; i >= 0; i--) {
         const helperDate = new Date();
         helperDate.setMonth(helperDate.getMonth() - i);
         dates.push(helperDate);
      }
      return dates;
   }

   function getEquivalentDates(date) {
      const getDate = date.substring(3, 5);
      switch (getDate) {
         case "01":
            return 1;
         case "02":
            return 2;
         case "03":
            return 3;
         case "04":
            return 4;
         case "05":
            return 5;
         case "06":
            return 6;
         case "07":
            return 7;
         case "08":
            return 8;
         case "09":
            return 9;
         default:
            return parseInt(getDate, 10);
      }
   }
   function complaintCount(count) {
      let isNotNull = false;
      count.forEach((e) => {
         if (e !== 0) isNotNull = true;
      });
      return isNotNull;
   }

   const getEnabledCompanies = (companies, dates, disabled) => {
      const helpercount: number[] = [];
      dates.forEach((month, i) => {
         if (i !== 0) helpercount.push(helpercount[i - 1]);
         else helpercount.push(0);
         companies.forEach((company) => {
            const companyDate = getEquivalentDates(getDateFromISODate(company.createdAt));
            const monthDate = month.getMonth() + 1;
            if (companyDate === monthDate && company.disabled === disabled) {
               helpercount[i]++;
            }
         });
      });
      return helpercount;
   };
   const getComplaintsChart = (eValues, months) => {
      const count2: number[] = [0, 0, 0, 0, 0, 0];
      const stringMonths: string[] = [];
      eValues.forEach((e) => {
         months.forEach((month, index) => {
            if (getEquivalentDates(getDateFromISODate(e.createdAt)) === month.getMonth() + 1) {
               count2[index]++;
            }
         });
      });
      months.forEach((months) => {
         stringMonths.push(months.toLocaleString("default", { month: "long" }).substring(0, 3));
      });
      const interpositionCount = complaintCount(count2);
      setComplaintChart({
         title: "Denuncias interpuestas",
         chart: interpositionCount ? (
            <SingleLineChart scores={count2} labels={stringMonths} labelNames={count2} />
         ) : (
            errorHandlerBox("No existen denuncias interpuestas en el periodo de tiempo")
         ),
      });
   };
   const getCompaniesCharts = (eValues2: any, months) => {
      const count: number[][] = [];
      const stringMonths: string[] = [];
      count.push(getEnabledCompanies(eValues2, getDates(), false));
      count.push(getEnabledCompanies(eValues2, getDates(), true));
      months.forEach((months) => {
         stringMonths.push(months.toLocaleString("default", { month: "long" }).substring(0, 3));
      });
      setCompanyChart({
         title: "Empresas",
         chart:
            count.length > 0 ? (
               <LineChart scores={count} labels={stringMonths} labelNames={["Habilitadas", "Deshabilitadas"]} />
            ) : (
               errorHandlerBox("No existen empresas registradas")
            ),
         colors: companyChart.colors,
      });
   };

   useEffect(() => {
      const fetchInfo = async () => {
         try {
            const complaintResponse = await getAllComplaints();
            getComplaintsChart(complaintResponse, getDates());
         } catch (error: any) {
            if (error.message === "Network Error") {
               setComplaintChart({
                  title: "Denuncias interpuestas",
                  chart: errorHandlerBox("Error al cargar denuncias"),
               });
            }
         }
         setComplaintLoader(false);
         try {
            const companyResponse = await getAllCompanies();
            getCompaniesCharts(companyResponse, getDates());
         } catch (error) {
            setCompanyChart({
               title: "Denuncias interpuestas",
               chart: (
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                     }}
                  >
                     <Typography sx={{ color: "#162c44", fontWeight: "bold" }}>Error al cargar empresas</Typography>
                  </Box>
               ),
               colors: companyChart.colors,
            });
         }
         setCompanyLoader(false);
      };
      fetchInfo();
      // eslint-disable-next-line
   }, []);

   return !complaintLoader && !companyLoader ? (
      <>
         <Typography fontWeight={600} fontSize={18} sx={{ px: 15, pt: 2 }}>
            Canal de denuncias
         </Typography>
         <Box
            width={"100%"}
            sx={{
               display: "flex",
               flexWrap: "wrap",
               flexDirection: "row",
               justifyContent: "space-evenly",
               alignItems: "center",
               p: 5,
            }}
         >
            <Box sx={{ bgcolor: "#ffffff", boxShadow: 1, borderRadius: 2 }}>
               <Typography textAlign={"center"} sx={{ pt: 2, color: "#162c44", fontWeight: "bold" }}>
                  {complaintChart.title}
               </Typography>
               <Box
                  width={500}
                  height={250}
                  sx={{
                     p: 2,
                     display: "flex",
                  }}
               >
                  {complaintChart.chart}
               </Box>
            </Box>

            <Box sx={{ bgcolor: "#ffffff", boxShadow: 1, borderRadius: 2 }}>
               <Typography textAlign={"center"} sx={{ mt: 2, color: "#162c44", fontWeight: "bold" }}>
                  {companyChart.title}
               </Typography>
               <Box
                  width={500}
                  height={250}
                  sx={{
                     p: 2,
                     display: "flex",
                  }}
               >
                  {companyChart.chart}
               </Box>
            </Box>
         </Box>
      </>
   ) : (
      <Box
         width={"100%"}
         sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            p: 5,
         }}
      >
         <CircularProgress size={24} />
      </Box>
   );
};

const errorHandlerBox = (message) => {
   return (
      <Stack width={"100%"} justifyContent={"center"}>
         <Typography textAlign={"center"} sx={{ color: "#162c44", fontWeight: "bold" }}>
            {message}
         </Typography>
      </Stack>
   );
};
