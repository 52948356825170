import {
   Box,
   Typography,
   Button,
   CircularProgress,
   Autocomplete,
   TextField,
   IconButton,
   Tooltip,
   Divider,
} from "@mui/material";
import { ClientConfigContext } from "../../../../../context/client/clientConfigContext";
import { useContext, useEffect, useState } from "react";
import {
   GetAllUserRoles,
   GetCompanyUsers,
   GetGroupUsers,
   updateClientUserRoles,
   updateCompanyAdminUser,
   useCreateUser,
} from "../../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { catchAxiosError } from "../../../../../lib/lecosyBackendClient";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { createUserSchema } from "../../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { Users } from "../../../../../types/BaseTypes";
import { Info } from "@mui/icons-material";
import GetAdminRoles from "../../../../../hooks/getAdminRoles";
import { LoadingButton } from "../../../../LoadingButton";
import { HeadCell, TableComponent } from "../../../../TableComponent";

interface UserDetailsComponentProps {
   setSelectedClient: Function;
   handleCancel: Function;
   setValue: Function;
   value: number;
}

const userColumns: HeadCell[] = [
   { field: "name", headerName: "Nombre", type: "string" },
   { field: "roles", headerName: "Rol", type: "popover", width: 200 },
   { field: "email", headerName: "Correo electrónico ", type: "string" },
];

interface RoleInterface {
   _id: string;
   name: string;
   description: string;
}

export const UserDetailsComponentOld = (props: UserDetailsComponentProps) => {
   const { clientInfo, isLoading, refreshClientInfo, group, refreshGroupInfo, setClientInfo } =
      useContext(ClientConfigContext);
   const { data, isLoading: isLoadingUsers = false } =
      !clientInfo?.group && !group && props.value === 3
         ? GetCompanyUsers(clientInfo)
         : !clientInfo?.group && props.value === 1 && !group
         ? GetCompanyUsers(clientInfo)
         : clientInfo?.group
         ? GetCompanyUsers(clientInfo, { groupId: clientInfo.group })
         : clientInfo?.group && !group && props.value === 1
         ? GetCompanyUsers(clientInfo, { groupId: clientInfo.group })
         : GetGroupUsers(group);
   const { data: roleList, isLoading: isLoadingRoles } = GetAllUserRoles();
   const [selectedRoles, setSelectedRoles] = useState([]);
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate, isLoading: isLoadingCreate } = useCreateUser();
   const [rolesSelectedError, setRolesSelectedError] = useState(false);
   const [presidentExists, setPresidentExists] = useState(false);
   const [directorExists, setDirectorExists] = useState(false);
   const [userSelected, setUserSelected] = useState<Users | null>(null);
   const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
   const [userfromGroup, setUserfromGroup] = useState(false);
   const [usedRoles, setUsedRoles] = useState([]);
   const [userSelectedFlag, setUserSelectedFlag] = useState(false);
   const [companyUsers, setCompanyUsers] = useState([]);

   useEffect(() => {
      if (!isLoadingUsers) {
         if (clientInfo) {
            const rolesSet: string[] = [];
            if (clientInfo.company_details?.users.length > 0) {
               clientInfo.company_details.users.forEach((user: any) => {
                  if (user.role) {
                     for (const userRole of user.role) {
                        if (userRole.company === clientInfo._id) {
                           return userRole.roles.forEach((uniqueRole) => {
                              if (
                                 uniqueRole.name === "Administrador Único / Presidente del Consejo" ||
                                 uniqueRole.name === "Director General"
                              ) {
                                 rolesSet.push(uniqueRole.name);
                              }
                           });
                        }
                     }
                  }
               });
            }

            const rolesFromCompanyArray = new Set(rolesSet);
            if (Array.from(rolesFromCompanyArray).includes("Administrador Único / Presidente del Consejo")) {
               setPresidentExists(true);
            }
            if (Array.from(rolesFromCompanyArray).includes("Director General")) {
               setDirectorExists(true);
            }
            setUsedRoles(Array.from(rolesFromCompanyArray));
            const usersData = (clientInfo.company_details?.users as any[]).map((user) => ({
               _id: user._id,
               uid: user.uid,
               name: user.firstName + " " + user.lastName,
               email: user.email,
               roles: getRoles(user.role).length > 0 ? getRoles(user.role) : ["sin datos"],
               status: false,
            }));
            try {
               setCompanyUsers(usersData);
            } catch (error) {
               console.error("Error fetching roles:", error);
            }
         } else {
            const usersData = (group.users as any[]).map((user) => ({
               _id: user._id,
               name: user.firstName + " " + user.lastName,
               email: user.email,
               roles: getRoles(user.role).length > 0 ? getRoles(user.role) : ["sin datos"],
            }));
            try {
               setCompanyUsers(usersData);
            } catch (error) {
               console.error("Error fetching roles:", error);
            }
         }
      }
   }, [data, clientInfo, group?.users, isLoadingUsers]);

   useEffect(() => {
      if (selectedRoles.length > 0 || props.value === 1) {
         setRolesSelectedError(false);
      }
      // eslint-disable-next-line
   }, [selectedRoles]);

   const handleSubmit = async (values, actions) => {
      try {
         if (!userfromGroup) {
            if (props.value === 1 && clientInfo && clientInfo.company_details.admin) {
               try {
                  const response = await updateCompanyAdminUser(
                     clientInfo.company_details.admin.uid,
                     values.firstName,
                     values.lastName,
                     values.email,
                     values.phoneNumber
                  );
                  if (response.data) {
                     if (group) {
                        refreshGroupInfo();
                     }
                     refreshClientInfo();
                     showSnackBar("Usuario modificado exitosamente", false);
                     setIsLoadingUpdate(false);
                  }
               } catch (error) {
                  const errorMessage = catchAxiosError(error);
                  if (errorMessage.message.includes("no user found")) {
                     showSnackBar("Error, intente de nuevo", true);
                  }
                  if (errorMessage.message.includes("email-already-exists")) {
                     showSnackBar("Correo electrónico en uso", true);
                  }
                  setIsLoadingUpdate(false);
               }
               return;
            } else if (
               selectedRoles.length > 0 ||
               props.value === 1 /*&& values.email !== clientInfo.company_details?.admin.email*/
            ) {
               const roles = selectedRoles.map((role) => role._id);
               const rolesFormat = [{ company: clientInfo._id, roles: roles }];
               mutate(
                  {
                     userData: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phoneNumber: values.phone,
                        role: props.value === 1 ? null : rolesFormat,
                     },
                     additionalData: {
                        admin: props.value === 1,
                        company: clientInfo ? clientInfo._id : null,
                        group: group ? group._id : clientInfo.group ? clientInfo.group : null,
                     },
                  },
                  {
                     onError: (error: any) => {
                        error.response.data.message === "email already in use"
                           ? showSnackBar("Ya existe un usuario con ese correo", true)
                           : showSnackBar("Error al agregar administrador.", true);
                     },
                     onSuccess: async () => {
                        if (group) {
                           refreshGroupInfo();
                        }
                        actions.resetForm();
                        setSelectedRoles([]);
                        props.value === 1
                           ? showSnackBar("El administrador fue agregado correctamente.", false)
                           : showSnackBar("El usuario fue agregado correctamente.", false);
                        if (props.value === 1) props.setValue(2);
                        await refreshClientInfo();
                        if (props.value === 3 && usedRoles.length + selectedRoles.length === 2) {
                           props.setValue(4);
                        }
                     },
                  }
               );
               return;
            } else if (props.value === 3 && !clientInfo) {
               mutate(
                  {
                     userData: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phoneNumber: values.phone,
                        role: null,
                     },
                     additionalData: {
                        admin: false,
                        group: group._id,
                     },
                  },
                  {
                     onError: (error: any) => {
                        error.response.data.message === "email already in use"
                           ? showSnackBar("Ya existe un usuario con ese correo", true)
                           : showSnackBar("Error al agregar administrador.", true);
                     },
                     onSuccess: async () => {
                        refreshGroupInfo();
                        actions.resetForm();
                        setSelectedRoles([]);
                        props.value === 1
                           ? showSnackBar("El administrador fue agregado correctamente.", false)
                           : showSnackBar("El usuario fue agregado correctamente.", false);
                     },
                  }
               );
            } else {
               setRolesSelectedError(true);
               return;
            }
         } else if (props.value === 1 && clientInfo.company_details /* && clientInfo.company_details.admin*/) {
            const roles = selectedRoles.map((role) => role._id);
            const rolesFormat = [{ company: clientInfo._id, roles: roles }];
            const response = await updateClientUserRoles(
               userSelected._id,
               userfromGroup,
               true,
               clientInfo ? clientInfo._id : group._id,
               rolesFormat
            );
            if (response.data) {
               if (group) {
                  refreshGroupInfo();
               }
               if (clientInfo) {
                  refreshClientInfo();
               }
               showSnackBar("Usuario modificado exitosamente", false);
               actions.resetForm();
               setIsLoadingUpdate(false);
            }
         } else if (props.value === 3 && clientInfo.company_details /* && clientInfo.company_details.admin*/) {
            const roles = selectedRoles.map((role) => role._id);
            const rolesFormat = [{ company: clientInfo._id, roles: roles }];
            const response = await updateClientUserRoles(
               userSelected._id,
               userfromGroup,
               false,
               clientInfo ? clientInfo._id : group._id,
               rolesFormat
            );
            if (response.data) {
               if (group) {
                  refreshGroupInfo();
               }
               if (clientInfo) {
                  refreshClientInfo();
               }
               setSelectedRoles([]);
               showSnackBar("Usuario modificado exitosamente", false);
               actions.resetForm();
               setIsLoadingUpdate(false);
               if (props.value === 3 && usedRoles.length + selectedRoles.length === 2) {
                  props.setValue(4);
               }
            }
         }
      } catch (error) {
         console.log({ error });
         return;
      }
   };

   function getRoles(roles: any[]) {
      const roleNames = roles ? roles.map((role) => role) : [];
      let rolesArray = [];
      for (const role of roleNames) {
         rolesArray.push(
            role.roles.map((role) => (role.name === "AdminDelSistema" ? "Administrador del Sistema" : role.name))
         );
      }
      rolesArray = rolesArray.flat();
      return rolesArray.filter((item, index) => rolesArray.indexOf(item) === index);
   }

   const usersGroupList =
      group && props.value === 1 && data
         ? data.map((user) => user)
         : group && props.value === 3 && clientInfo
         ? group.users.map((user) => user)
         : clientInfo?.group && !group && data
         ? data.map((user) => user)
         : [];

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();

      useEffect(() => {
         if (userSelected) {
            setUserSelectedFlag(true);
         } else {
            setUserSelectedFlag(false);
         }
         if (userSelected === null && userSelectedFlag) {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
            setFieldValue("phone", "");
            setFieldValue("email", "");
            setUserfromGroup(false);
         } else if (userSelected) {
            setFieldValue("firstName", userSelected?.firstName);
            setFieldValue("lastName", userSelected?.lastName);
            setFieldValue("phone", userSelected?.phoneNumber);
            setFieldValue("email", userSelected?.email);
            setUserfromGroup(true);
         }
         // eslint-disable-next-line
      }, [userSelected]);

      return null;
   };
   return (
      <Box flex={1}>
         <Formik
            initialValues={{
               firstName:
                  !isLoading && props.value === 1 && clientInfo?.company_details
                     ? clientInfo?.company_details.admin
                        ? clientInfo?.company_details?.admin.firstName
                        : ""
                     : "",
               lastName:
                  !isLoading && props.value === 1 && clientInfo?.company_details
                     ? clientInfo?.company_details?.admin
                        ? clientInfo?.company_details?.admin.lastName
                        : ""
                     : "",
               phone:
                  !isLoading && props.value === 1 && clientInfo?.company_details
                     ? clientInfo?.company_details?.admin
                        ? clientInfo?.company_details?.admin.phoneNumber
                        : ""
                     : "",
               email:
                  !isLoading && props.value === 1 && clientInfo?.company_details
                     ? clientInfo?.company_details?.admin
                        ? clientInfo?.company_details?.admin.email
                        : ""
                     : "",
               role: [],
            }}
            validationSchema={createUserSchema}
            onSubmit={handleSubmit}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <FormObserver />
                  <Box
                     sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                        borderRadius: 2,
                        flex: 1,
                        maxHeight: "420px",
                        overflowY: "auto",
                     }}
                  >
                     {clientInfo?.group &&
                     (props.value === 1 || props.value === 3) &&
                     data &&
                     !isLoading &&
                     !isLoadingUsers &&
                     data.length > 0 ? (
                        <>
                           <Box sx={{ p: 2 }}>
                              <Typography fontWeight={600} variant="h6">
                                 {props.value === 1
                                    ? `Administrador de sistema existente`
                                    : `Usuarios del grupo empresarial`}
                              </Typography>
                              <Autocomplete
                                 limitTags={2}
                                 id="role"
                                 options={group && (props.value === 1 || props.value === 3) ? usersGroupList : data}
                                 getOptionLabel={(option) => `${(option as any).firstName} ${(option as any).lastName}`}
                                 onChange={(event, newValue) => {
                                    setUserSelected(newValue as Users);
                                    //hacer cambio aqui
                                 }}
                                 renderInput={(params) => (
                                    <TextField {...params} label="" placeholder="Selecciona un usuario" name="role" />
                                 )}
                                 sx={{ width: "auto", py: 1 }}
                              />
                           </Box>
                           <Divider />
                        </>
                     ) : clientInfo?.group && props.value === 1 && (isLoading || isLoadingUsers) ? (
                        <>
                           <Box
                              sx={{
                                 justifyContent: "center",
                                 display: "flex",
                                 alignItems: "center",
                                 p: 2,
                              }}
                           >
                              <CircularProgress size={24} />
                           </Box>
                           <Divider />
                        </>
                     ) : null}
                     <Box
                        sx={{
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                        }}
                     >
                        <Box
                           sx={{
                              px: 3,
                              pt: 2,
                              pb: props.value === 1 ? 3 : 0,
                              display: "flex",
                              rowGap: 2,
                              flexDirection: "column",
                           }}
                        >
                           <Typography fontWeight={600} variant="h6">
                              {props.value === 1 ? "Registro de administrador de sistema" : "Registro de usuarios"}
                           </Typography>
                           <Box sx={{ display: "flex", columnGap: 2 }}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="firstName"
                                 id="firstName"
                                 type="text"
                                 label="Nombre"
                                 fullWidth={true}
                                 disabled={userfromGroup}
                              />
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="lastName"
                                 id="lastName"
                                 type="text"
                                 label="Apellido"
                                 fullWidth={true}
                                 disabled={userfromGroup}
                              />
                           </Box>
                           <Box sx={{ display: "flex", columnGap: 2 }}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="phone"
                                 id="phone"
                                 type="text"
                                 label="Teléfono"
                                 fullWidth={true}
                                 disabled={userfromGroup}
                              />
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="email"
                                 id="email"
                                 type="text"
                                 label="Correo electrónico"
                                 fullWidth={true}
                                 disabled={userfromGroup}
                              />
                           </Box>
                           <Box sx={{ display: "flex", columnGap: 1 }}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 value="DefaultPassword"
                                 name="comercialName"
                                 id="comercialName"
                                 type="password"
                                 label="Contraseña"
                                 disabled
                                 fullWidth={true}
                              />
                              <Tooltip
                                 title={"La contraseña se genera automáticamente y se enviara al usuario registrado"}
                                 placement="right-start"
                              >
                                 <IconButton>
                                    <Info />
                                 </IconButton>
                              </Tooltip>
                           </Box>
                           {props.value === 1 ||
                           (!clientInfo?.company_details?.users && props.value !== 3) ||
                           (props.value === 3 && group && !clientInfo) ? (
                              <></>
                           ) : (
                              <Autocomplete
                                 sx={{ width: "auto" }}
                                 multiple
                                 limitTags={2}
                                 id="role"
                                 value={selectedRoles}
                                 options={
                                    !isLoadingRoles && data
                                       ? GetAdminRoles(roleList).filter((r) => {
                                            return !usedRoles.some((role) => role === r.name);
                                         })
                                       : []
                                 }
                                 getOptionLabel={(option) => (option as RoleInterface).name}
                                 onChange={(event, newValue) => {
                                    setSelectedRoles(newValue);
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Roles"
                                       placeholder="Seleccionar roles"
                                       name="role"
                                       error={rolesSelectedError && props.value === 3}
                                       helperText={
                                          rolesSelectedError && props.value === 3
                                             ? "Seleccionar como minimo un rol."
                                             : null
                                       }
                                       onClick={() => setRolesSelectedError(false)}
                                    />
                                 )}
                              />
                           )}
                           {props.value === 1 ? (
                              <></>
                           ) : (
                              <Box sx={{ display: "flex", justifyContent: "right" }}>
                                 <LoadingButton
                                    disabled={directorExists && presidentExists}
                                    label={"Agregar usuario"}
                                    isLoading={isLoadingCreate}
                                 />
                              </Box>
                           )}
                        </Box>
                        {props.value === 1 || (!clientInfo?.company_details?.users && !group) ? (
                           <></>
                        ) : clientInfo?.company_details?.users && !isLoadingUsers ? (
                           <>
                              <Typography
                                 sx={{ borderTop: 1, borderColor: "#E0E0E0", pt: 3, px: 2 }}
                                 fontWeight={600}
                                 variant="h6"
                              >
                                 Usuarios registrados
                              </Typography>

                              <TableComponent
                                 defaultColumnToOrder=""
                                 defaultOrder="asc"
                                 defaultRowsPerPage={2}
                                 emptyDataText="No se han agregado usuarios"
                                 rowsPerPageOptions={[]}
                                 loader={isLoadingUsers}
                                 headers={userColumns}
                                 rows={companyUsers}
                                 onClick={() => {}}
                                 disableBorders
                              />
                           </>
                        ) : group && !isLoadingUsers ? (
                           <>
                              <Typography
                                 sx={{ borderTop: 1, borderColor: "#E0E0E0", pt: 3, px: 2 }}
                                 fontWeight={600}
                                 variant="h6"
                              >
                                 Usuarios registrados
                              </Typography>

                              <TableComponent
                                 defaultColumnToOrder=""
                                 defaultOrder="asc"
                                 defaultRowsPerPage={2}
                                 emptyDataText="No se han agregado usuarios"
                                 rowsPerPageOptions={[5]}
                                 loader={false}
                                 headers={userColumns}
                                 rows={companyUsers || []}
                                 onClick={() => {}}
                                 disableBorders
                              />
                           </>
                        ) : (
                           <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                              <CircularProgress size={24} />
                           </Box>
                        )}
                     </Box>
                  </Box>

                  <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                     <Button
                        sx={{ height: "100%" }}
                        variant="outlined"
                        onClick={() => {
                           if (group && clientInfo) {
                              props.handleCancel();
                              setClientInfo(null);
                           } else if (group) {
                              props.setSelectedClient(0);
                           } else {
                              props.setSelectedClient(0);
                           }
                        }}
                     >
                        Cancelar
                     </Button>
                     {props.value === 1 ? (
                        <LoadingButton label={"Continuar"} isLoading={isLoadingCreate && !isLoadingUpdate} />
                     ) : (
                        <Button
                           sx={{
                              height: "100%",
                              bgcolor: "#142c44",
                              color: "white",
                              mb: 2,
                              "&:hover": {
                                 bgcolor: "#142c4490",
                              },
                           }}
                           variant="outlined"
                           onClick={() => props.setValue(4)}
                        >
                           Continuar
                        </Button>
                     )}
                  </Box>
               </Form>
            )}
         </Formik>
      </Box>
   );
};
