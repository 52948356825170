import { useRef, useContext } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { IconButton, Box, Button, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

interface InputFileProps {
   setFile: Function;
   file: File;
   onChange?: Function;
   type?: string;
   keyName: string;
}

export const InputFile = (props: InputFileProps) => {
   const inputRef: any = useRef<any>();
   const { showSnackBar } = useContext(SnackBarContext);

   const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files.length === 0) {
         inputRef.current.value = null;
         return showSnackBar("Favor de adjuntar un documento", true);
      }
      if (event.target.files[0].size > 3000000) {
         inputRef.current.value = null;
         return showSnackBar("El archivo no puede pesar más de 3MB", true);
      }
      props.setFile({ file: event.target.files[0], lastDateModified: new Date() });
      if (props.onChange) props.onChange({ file: event.target.files[0], fileType: "pdf", name: props.keyName });
   };

   return (
      <Button
         variant="outlined"
         component="span"
         sx={{ p: 1, ml: 1, maxHeight: "35px", maxWidth: "400px", borderColor: "#C4C4C4" }}
      >
         {!props.file ? (
            <input
               accept={props.type || "application/pdf"}
               id="policies-button-file"
               ref={inputRef}
               type="file"
               onChange={(e) => {
                  handleUploadFile(e);
               }}
            />
         ) : (
            <>
               <Box
                  sx={{ ml: 1 }}
                  onClick={() => {
                     window.open(URL.createObjectURL(props.file), "_blank", "noopener,noreferrer");
                  }}
               >
                  <Typography fontSize={13}>
                     {props.file.name.length > 30 ? `${props.file.name.substring(0, 25)}...` : `${props.file.name}`}
                  </Typography>
               </Box>
               <IconButton
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => {
                     props.setFile(null);
                  }}
               >
                  <GridCloseIcon sx={{ color: "#162c44", fontSize: 15 }} />
               </IconButton>
            </>
         )}
      </Button>
   );
};
