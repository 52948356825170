import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { UserProvider } from "./context/UserContext";
import { SessionProvider } from "./context/SessionContext";
import { ClientConfigProvider } from "./context/client/clientConfigContext";
import { SnackBarProvider } from "./context/snackBarContext";
import { SupportDrawerProvider } from "./context/supportContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ComplaintProvider } from "./context/complaintsExternContext/complaintContext";
import { ComplaintInternProvider } from "./context/complaintsInternContext/complaintInternContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
   <React.StrictMode>
      <QueryClientProvider client={queryClient}>
         <UserProvider>
            <SessionProvider>
               <SnackBarProvider>
                  <SupportDrawerProvider>
                     <ComplaintProvider>
                        <ComplaintInternProvider>
                           <App />
                        </ComplaintInternProvider>
                     </ComplaintProvider>
                  </SupportDrawerProvider>
               </SnackBarProvider>
            </SessionProvider>
         </UserProvider>
      </QueryClientProvider>
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
