import { useState, useContext, useEffect, useCallback } from "react";
import { Button, Drawer, Typography, Box } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AddModifyMasterUserDrawer } from "../../components/MasterUser/AddModifyMasterUserDrawer";
import { useNavigate } from "react-router-dom";
import { HeadCell, TableComponent } from "../../components/TableComponent";
import GetAllMasterUsers from "../../hooks/GetAllMasterUsers";
import VerifyUserSession from "../../hooks/verifyUserSession";
import { UserContext } from "../../context/UserContext";
import { FilterComponent } from "../../components/FilterComponent";

const headCells: HeadCell[] = [
   { field: "firstName", headerName: "Nombre(s)", type: "string" },
   { field: "lastName", headerName: "Apellidos", type: "string" },
   { field: "email", headerName: "Correo electrónico", type: "string" },
   { field: "role", headerName: "Rol", type: "popover" },
];

export const MasterUsersTable = () => {
   VerifyUserSession();
   const navigate = useNavigate();
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const [openCreateMasterUserDrawer, setOpenCreateMasterUserDrawer] = useState(false);

   const { data: masterUsers, isLoading } = GetAllMasterUsers();
   const [userRows, setUserRows] = useState([]);
   const [filteredUserRows, setFilteredUserRows] = useState([]);

   const handleOpenCreateDrawer = () => {
      navigate("registro");
   };

   const getRows = useCallback(() => {
      if (!masterUsers) return;
      const rows = masterUsers.map((u) => ({
         uid: u.uid,
         firstName: u.firstName,
         lastName: u.lastName,
         email: u.email,
         role: u.role.length > 0 ? u.role.map((role) => role.name) : ["sin datos"],
      }));
      setUserRows(rows);
      setFilteredUserRows(rows);
   }, [isLoading]);

   useEffect(() => {
      getRows();
   }, [getRows]);

   return (
      <Box sx={{ width: "100%" }}>
         <FilterComponent
            originaltableOneRows={userRows}
            tableOneFilteredRows={filteredUserRows}
            setTableOneFilteredRows={setFilteredUserRows}
            filters={[]}
            disableRefresh
            width="100%"
            searchBar={
               <>
                  <Button
                     variant="contained"
                     sx={{ width: "150px" }}
                     onClick={() => {
                        navigate("/usuarios/roles");
                     }}
                  >
                     Editar roles
                  </Button>
                  <Button
                     onClick={handleOpenCreateDrawer}
                     size="small"
                     variant="contained"
                     sx={{ height: "40px", mb: 2, maxWidth: "200px" }}
                     disabled={access}
                     fullWidth
                  >
                     <Typography variant="button" sx={{ mr: 1 }}>
                        Agregar usuario
                     </Typography>
                     <PersonAddIcon />
                  </Button>
               </>
            }
         />
         <TableComponent
            headerColor
            defaultColumnToOrder="name"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={headCells}
            rows={userRows}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados"
            onClick={(e, row) => {
               navigate(row.uid);
            }}
         />
         <Drawer
            anchor={"right"}
            open={openCreateMasterUserDrawer}
            hideBackdrop={true}
            onClose={() => setOpenCreateMasterUserDrawer(false)}
            sx={{ maxWidth: 450 }}
         >
            <AddModifyMasterUserDrawer setOpen={setOpenCreateMasterUserDrawer} isModify={false} />
         </Drawer>
      </Box>
   );
};
