import { Box, Typography, CircularProgress, Button, Stack } from "@mui/material";
import { GetAllServices } from "../../../lib/usersBEClient";
import { useContext } from "react";
import { ServiceLicencesComponent } from "./ServiceLicencesComponent";
import { ClientCreationContext } from "../../../context/client/clientCreationContext";
import { Companies } from "../../../types/BaseTypes";

export const ServiceAssignComponent = ({ onComplete }) => {
   const { data: servicesList, isLoading: isLoadingService } = GetAllServices();
   const { selectedClient, setSelectedClient } = useContext(ClientCreationContext);
   const selectedCompany = selectedClient.data as Companies;

   const handleCancel = () => setSelectedClient(null);

   const servicesOrder = [
      "Canal de denuncias",
      "Programa de cumplimiento",
      "Gobierno corporativo",
      "Beneficiario controlador",
   ];

   return (
      <Box flex={1}>
         <Box
            sx={{
               border: 1,
               borderColor: "#E0E0E0",
               borderRadius: 2,
               flex: 1,
            }}
         >
            <Box sx={{ p: 2 }}>
               <Typography fontWeight={600} variant="h6">
                  Selección de módulos contratados
               </Typography>
            </Box>
            <Stack
               sx={{
                  px: 4,
                  py: 3,
                  borderTop: 1,
                  borderColor: "#E0E0E0",
                  rowGap: 2,
                  maxHeight: "400px",
                  minHeight: "400px",
                  overflowY: "auto",
               }}
            >
               {servicesList && !isLoadingService ? (
                  servicesList
                     .filter((s) => s.service !== "Gestión interna" && s.service !== "Programa de cumplimiento")
                     .sort((a, b) => {
                        const aKey = a.service;
                        const bKey = b.service;
                        return servicesOrder.indexOf(aKey) - servicesOrder.indexOf(bKey);
                     })
                     .map((service, index) => (
                        <ServiceLicencesComponent key={`service-${index}`} modify={false} service={service} />
                     ))
               ) : (
                  <Box
                     sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     <CircularProgress size={24} />
                  </Box>
               )}
            </Stack>
         </Box>
         <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
               Cancelar
            </Button>
            <Button
               sx={{
                  bgcolor: "#142c44",
                  mb: 2,
                  "&:hover": {
                     bgcolor: "#142c4490",
                  },
               }}
               disabled={selectedCompany?.company_details?.servicesDetails?.length > 0 ? false : true}
               type="submit"
               variant="contained"
               onClick={onComplete}
               style={{ textTransform: "none" }}
            >
               Continuar
            </Button>
         </Box>
      </Box>
   );
};
