import { Box } from "@mui/material";
import { GroupServiceCompaniesTable } from "../GroupServiceCompaniesTable";
import { GroupUsersByModule } from "../GroupUsersByModule";
import GovernanceCoordination from "../../../Clients/SubComponents/ServiceInfoForm/Governance/subcomponents/CompanyCoordination";

const beneficiaryServiceId = "66d75b0724169eb3399c631b";

export const BeneficiaryGroupTab = () => {
   return (
      <>
         <GroupUsersByModule serviceCode="BC" />
         <Box
            sx={{
               px: 3,
               py: 2,
               borderRadius: 1,
               alignItems: "center",
               boxShadow: 2,
               backgroundColor: "white",
            }}
         >
            <GovernanceCoordination group beneficiary />
         </Box>
         <GroupServiceCompaniesTable serviceId={beneficiaryServiceId} />
      </>
   );
};
