import { useEffect, useState, useContext, useCallback } from "react";
import { Box } from "@mui/material";
import { HeadCell, TableComponent } from "../TableComponent";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { getCompaniesAndBranchCountByGroup, getManyCompaniesById, getManyCorporateData } from "../../lib/usersBEClient";
import { useNavigate } from "react-router-dom";

const headCells: HeadCell[] = [
   { field: "logo", headerName: "Logo", type: "string" },
   { field: "company", headerName: "Empresa", type: "string" },
   { field: "councilType", headerName: "Tipo de consejo", type: "string" },
   { field: "branches", headerName: "Sucursales", type: "number" },
   { field: "status", headerName: "Estatus", type: "disabled" },
];

export const GroupCompaniesTable = () => {
   const navigate = useNavigate();
   const { group } = useContext(ClientConfigContext);
   const [companies, setCompanies] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   const fetchGroupCompanies = useCallback(async () => {
      setIsLoading(true);
      try {
         const [groupCompanies, complaintsCompanyGroup, companyCorporateDatas] = await Promise.all([
            getManyCompaniesById(group.companies.map((c) => c._id)),
            getCompaniesAndBranchCountByGroup(group._id),
            getManyCorporateData(group.companies.map((c) => c._id)),
         ]);

         const companyData = groupCompanies.map((company) => {
            const corporateData = companyCorporateDatas.find((c) => c.companyId === company._id);
            const complaintData = complaintsCompanyGroup.find((c) => c._id === company._id);
            return {
               id: company._id,
               logo: <img src={company.logo} alt="logo" style={{ maxWidth: "140px", maxHeight: "50px" }} />,
               company: company.person_details.comercialName,
               status: company.disabled,
               councilType: corporateData?.councilType ?? "---",
               branches: complaintData?.branches ?? "0",
            };
         });
         setCompanies(companyData);
         setIsLoading(false);
      } catch (error) {
         console.error("Error fetching roles:", error);
         setIsLoading(false);
      }
   }, [group]);

   useEffect(() => {
      fetchGroupCompanies();
   }, [fetchGroupCompanies]);

   const handleTableRowClickCompanie = async (_e, row) => {
      const screen = "clientes/" + row.id;
      return navigate(`/${screen}`);
   };

   return (
      <Box sx={{ width: "100%" }}>
         <TableComponent
            defaultColumnToOrder="company"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={headCells}
            rows={companies}
            loader={isLoading}
            onClick={handleTableRowClickCompanie}
            emptyDataText="No existen compañias registradas"
         />
      </Box>
   );
};
