import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { ClientCreationContext } from "../../../../../context/client/clientCreationContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { Companies } from "../../../../../types/BaseTypes";
import { LoadingButton } from "../../../../LoadingButton";
import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";
import { getManyCompaniesById, updateOfficeLicences } from "../../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { ClientConfigContext } from "../../../../../context/client/clientConfigContext";

const OfficeLicences = ({ onComplete }) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { selectedClient, setSelectedClient } = useContext(ClientCreationContext);
   const { clientInfo } = useContext(ClientConfigContext);

   const selectedCompany = clientInfo || (selectedClient.data as Companies);
   const [totalLicenses, setTotalLicenses] = useState<number>(selectedCompany?.company_details?.office?.userLimit || 5);
   const [licensesUsed, setLicensesUsed] = useState<number>(0);

   const loadInformation = useCallback(async () => {
      setTotalLicenses(selectedCompany?.company_details?.office?.userLimit || 5);
      const adminCount = selectedCompany?.company_details?.admin ? 1 : 0;
      const userCount = selectedCompany?.company_details?.users?.length || 0;

      let clientCount: number = 0;
      const clientCompaniesIds = selectedCompany?.company_details?.office?.companies || [];
      if (clientCompaniesIds.length > 0) {
         const companiesFound: Companies[] = await getManyCompaniesById(clientCompaniesIds);
         for (const company of companiesFound) {
            const clientUserCount = company?.company_details?.users?.length;
            const adminClientCount = company?.company_details?.admin ? 1 : 0;
            clientCount += clientUserCount + adminClientCount;
         }
      }

      setLicensesUsed(adminCount + userCount + clientCount);
   }, [selectedCompany]);

   useEffect(() => {
      loadInformation();
   }, [loadInformation]);

   const handleCancel = () => setSelectedClient(null);
   const handleSubmit = async () => {
      try {
         await updateOfficeLicences(selectedCompany._id, totalLicenses);
         showSnackBar("Licencias actualizadas correctamente", false);
         onComplete();
      } catch (error) {
         showSnackBar("Error al actualizar licencias", false);
      }
   };

   return (
      <>
         <Box
            sx={{
               border: 1,
               borderColor: "#E0E0E0",
               borderRadius: 2,
            }}
         >
            <Box sx={{ p: 2 }}>
               <Typography fontWeight={600} variant="h6">
                  Registro de usuarios
               </Typography>
            </Box>

            <Box
               sx={{
                  p: 3,
                  borderTop: 1,
                  borderColor: "#E0E0E0",
                  display: "flex",
                  rowGap: 2,
                  flexDirection: "column",
                  maxHeight: "400px",
                  overflowY: "auto",
               }}
            >
               <Stack
                  direction={"row"}
                  sx={{
                     bgcolor: "#EFEFEF",
                     borderRadius: 2,
                     px: 2,
                     py: 1,
                     my: 1,
                     justifyContent: "space-between",
                  }}
               >
                  <Stack>
                     <Typography fontSize={14} fontWeight={600}>
                        Licencias en uso
                     </Typography>
                     <Typography fontSize={14} textAlign={"center"}>
                        {licensesUsed}
                     </Typography>
                  </Stack>
                  <Stack>
                     <Typography fontSize={14} fontWeight={600}>
                        Licencias totales
                     </Typography>
                     <Typography fontSize={14} textAlign={"center"}>
                        {totalLicenses}
                     </Typography>
                  </Stack>
                  <Stack>
                     <Typography fontSize={14} fontWeight={600}>
                        Licencias Extra
                     </Typography>
                     <Typography fontSize={14} textAlign={"center"}>
                        <IconButton
                           size="small"
                           onClick={() => {
                              if (totalLicenses > 5) setTotalLicenses(totalLicenses - 1);
                           }}
                        >
                           <RemoveCircleRounded sx={{ color: "#162c44" }} />
                        </IconButton>
                        <IconButton
                           size="small"
                           onClick={() => {
                              setTotalLicenses(totalLicenses + 1);
                           }}
                        >
                           <AddCircleRounded sx={{ color: "#162c44" }} />
                        </IconButton>
                     </Typography>
                  </Stack>
               </Stack>
            </Box>
         </Box>

         <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
               Cancelar
            </Button>
            <LoadingButton onClick={handleSubmit} label={"Guardar"} isLoading={false} />
         </Box>
      </>
   );
};

export default OfficeLicences;
