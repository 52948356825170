import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useContext, useEffect, useReducer, useState } from "react";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ClientConfigContext } from "../../../../context/client/clientConfigContext";
import { getLicencesByGroupAndModule, updateGroupModuleLicence } from "../../../../lib/usersBEClient";

const DenounceChannelGroupLicences = () => {
   const [initialLicencesValues, setInitialLicencesValues] = useState({ totalLicences: 0, usedLicences: 0 });
   const { showSnackBar } = useContext(SnackBarContext);
   const { group } = useContext(ClientConfigContext);

   const getInitialValues = async () => {
      const licencesFound = await getLicencesByGroupAndModule(group._id, "cd");
      if (!licencesFound) return { totalLicences: 0, usedLicences: 0 };
      return licencesFound;
   };

   useEffect(() => {
      const getLicences = async () => {
         const fetchedLicences = await getInitialValues();
         dispatch({ type: "initial", values: fetchedLicences });
         setInitialLicencesValues(fetchedLicences);
      };
      if (group) getLicences();
   }, [group]);

   const reducer = (state, action) => {
      switch (action.type) {
         case "initial":
            return action.values;
         case "reset":
            return initialLicencesValues;
         case "addLicence":
            return {
               ...state,
               totalLicences: state.totalLicences + 1,
            };
         case "removeLicence":
            if (state.totalLicences < 4) {
               showSnackBar("No es posible reducir las licencias", true);
               return state;
            }
            return {
               ...state,
               totalLicences: state.totalLicences - 1,
            };
      }
   };
   const [state, dispatch] = useReducer(reducer, initialLicencesValues);

   const handleRemoveLicence = () => dispatch({ type: "removeLicence" });
   const handleAddLicence = () => dispatch({ type: "addLicence" });
   const handleResetLicences = () => dispatch({ type: "reset" });

   const handleSubmit = async () => {
      await updateGroupModuleLicence(group._id, "cd", state.totalLicences);
      setInitialLicencesValues((s) => {
         return { ...s, totalLicences: state.totalLicences };
      });
      return showSnackBar("Licencias actualizadas correctamente.", false);
   };

   return (
      <Box sx={{ px: 3, py: 3 }}>
         <Grid container rowGap={5}>
            <Grid item xs={4} display={"flex"} justifyContent={"center"}>
               <Stack direction={"column"}>
                  <Typography fontWeight={600}>Licencias en uso</Typography>
                  <Typography textAlign={"center"}>{state.usedLicences}</Typography>
               </Stack>
            </Grid>
            <Grid item xs={4} display={"flex"} justifyContent={"center"}>
               <Stack direction={"column"}>
                  <Typography fontWeight={600}>Licencias totales</Typography>
                  <Typography textAlign={"center"}>{state.totalLicences}</Typography>
               </Stack>
            </Grid>
            <Grid item xs={4} display={"flex"} justifyContent={"center"}>
               <Stack direction={"column"}>
                  <Typography fontWeight={600}>Licencias extra</Typography>
                  <Box width={"100%"} display={"flex"} justifyContent={"center"} columnGap={2}>
                     <IconButton sx={{ p: 0 }} onClick={handleRemoveLicence}>
                        <RemoveCircleIcon sx={{ color: "#162c44", fontSize: "25px" }} />
                     </IconButton>
                     <IconButton sx={{ p: 0 }} onClick={handleAddLicence}>
                        <AddCircleIcon sx={{ color: "#162c44", fontSize: "25px" }} />
                     </IconButton>
                  </Box>
               </Stack>
            </Grid>
            <Grid item xs={12} sx={{ px: 0 }}>
               <Stack display={"flex"} direction={"row"} width={"100%"} justifyContent={"space-between"}>
                  <Button onClick={handleResetLicences} variant="contained">
                     Cancelar
                  </Button>
                  <Button onClick={handleSubmit} variant="contained">
                     Guardar
                  </Button>
               </Stack>
            </Grid>
         </Grid>
      </Box>
   );
};

export default DenounceChannelGroupLicences;
