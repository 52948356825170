import { useContext, useMemo, useState } from "react";
import { ClientCreationContext } from "../../../../context/client/clientCreationContext";
import { getDateAndTimeFromISODate } from "../../../../const/globalConst";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../../../TableComponent";

const incompletedGroupsColums: HeadCell[] = [
   { field: "name", headerName: "Nombre comercial", type: "string", width: 50, align: "left" },
   { field: "createdAt", headerName: "Fecha de creación", type: "string", width: 50, align: "right" },
];

const incompletedCompanyColums: HeadCell[] = [
   { field: "comercialName", headerName: "Nombre comercial", type: "string", width: 50, align: "left" },
   { field: "businessName", headerName: "Razón social", type: "string", width: 50 },
   { field: "createdAt", headerName: "Fecha de creación", type: "string", width: 50, align: "right" },
];

const IncompletedClientsTables = ({ onClose, setClientSelection }) => {
   const { incompletedCompanies, incompletedGroups, incompletedOffices, isLoading, setSelectedClient } =
      useContext(ClientCreationContext);
   const [selectedTab, setSelectedTab] = useState(0);

   const handleTableTabChange = (_event: React.SyntheticEvent, newValue: number) => setSelectedTab(newValue);

   const companyRows = useMemo(
      () =>
         (incompletedCompanies &&
            incompletedCompanies
               .filter(({ owner }) => !owner)
               .map((row) => ({
                  ...row,
                  comercialName: row.person_details.comercialName,
                  businessName: row.person_details.businessName,
                  createdAt: getDateAndTimeFromISODate(row.createdAt),
               }))) ??
         [],

      [incompletedCompanies]
   );

   const groupRows = useMemo(
      () =>
         (incompletedGroups &&
            incompletedGroups.map((row) => ({
               ...row,
               createdAt: getDateAndTimeFromISODate(row.createdAt),
            }))) ??
         [],
      [incompletedGroups]
   );

   const officeRows = useMemo(
      () =>
         (incompletedOffices &&
            incompletedOffices
               .filter(({ company_details }) => company_details?.office)
               .map((row) => ({
                  ...row,
                  office: true,
                  comercialName: row.person_details.comercialName,
                  businessName: row.person_details.businessName,
                  createdAt: getDateAndTimeFromISODate(row.createdAt),
               }))) ??
         [],

      [incompletedOffices]
   );

   const handleTableRowClick = async (_e, row) => {
      setClientSelection(false);
      setSelectedClient({
         type: row.office ? "office" : row.type || "group",
         data: row,
      });

      // setCompletedCompany(true);
   };

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minWidth: 800,
            p: 4,
         }}
      >
         <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Typography sx={{ textAlign: "center" }}>INFORMACIÓN PENDIENTE</Typography>
            <Box sx={{ marginTop: 1, marginBottom: 1 }}>
               <Tabs
                  value={selectedTab}
                  onChange={handleTableTabChange}
                  variant="fullWidth"
                  sx={{
                     ".MuiTabs-indicator": {
                        marginLeft: `calc((33% - 100px) / 2)`,
                        width: "100px !important",
                        backgroundColor: "#162c44",
                        height: "2px",
                     },
                  }}
               >
                  {["Empresas", "Grupos empresariales", "Despachos"].map((label, index) => (
                     <Tab
                        key={label}
                        label={label}
                        sx={{
                           width: index === 0 ? "10px" : "auto",
                           color: selectedTab === index ? "#162c44" : "inherit",
                           fontWeight: selectedTab === index ? "bold" : "normal",
                        }}
                     />
                  ))}
               </Tabs>
            </Box>
         </Box>

         {selectedTab === 0 && (
            <TableComponent
               defaultColumnToOrder=""
               defaultOrder="asc"
               defaultRowsPerPage={4}
               emptyDataText="No hay empresas pendientes por el momento"
               rowsPerPageOptions={[4]}
               loader={isLoading}
               headers={incompletedCompanyColums}
               rows={companyRows}
               onClick={handleTableRowClick}
               disableBorders
            />
         )}

         {selectedTab === 1 && (
            <TableComponent
               defaultColumnToOrder=""
               defaultOrder="asc"
               defaultRowsPerPage={4}
               emptyDataText="No hay grupos empresariales pendientes por el momento"
               rowsPerPageOptions={[4]}
               loader={isLoading}
               headers={incompletedGroupsColums}
               rows={groupRows}
               onClick={handleTableRowClick}
               disableBorders
            />
         )}

         {selectedTab === 2 && (
            <TableComponent
               defaultColumnToOrder=""
               defaultOrder="asc"
               defaultRowsPerPage={4}
               emptyDataText="No hay despachos pendientes por el momento"
               rowsPerPageOptions={[4]}
               loader={isLoading}
               headers={incompletedCompanyColums}
               rows={officeRows}
               onClick={handleTableRowClick}
               disableBorders
            />
         )}

         <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button sx={{ height: "100%" }} variant="outlined" onClick={onClose}>
               Cancelar
            </Button>
            <Button
               sx={{ height: "100%" }}
               variant="contained"
               color="primary"
               onClick={() => setClientSelection(true)}
            >
               Crear nuevo
            </Button>
         </Box>
      </Box>
   );
};

export default IncompletedClientsTables;
