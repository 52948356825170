import * as React from "react";
import Accordion from "@mui/material/Accordion";
import { Box } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputTextField } from "../Inputs/InputTextField";
import { Form, Formik } from "formik";
import { UseCreateCompanyInvoice, UseInvoicesByCompanyId, UseLastCompanyInvoice } from "../../lib/usersBEClient";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { SnackBarContext } from "../../context/snackBarContext";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../lib/s3Client";
import { uploadInvoice } from "../../lib/Validations/inputSchemas";
import { CustomInputFiles } from "../Inputs/CustomInputFiles";
import { LoadingButton } from "../LoadingButton";

interface UploadInvoiceAccordionProps {
   setRefetch: Function;
}

export const UploadInvoiceAccordion = (props: UploadInvoiceAccordionProps) => {
   const [uploadedInvoiceBlob1, setUploadedInvoiceBlob1] = React.useState<File>();
   const [uploadedInvoiceBlob2, setUploadedInvoiceBlob2] = React.useState<File>();
   const [isLoading, setIsLoading] = React.useState(false);
   const { clientInfo, group } = React.useContext(ClientConfigContext);
   const { showSnackBar } = React.useContext(SnackBarContext);
   const [seed, setSeed] = React.useState(1);

   const handleUploadFiles = async (values) => {
      const urlInvoice1 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `invoices/${clientInfo ? clientInfo._id : group.invoiceCompany._id}/${
            values.startPeriod.split("-")[0]
         }/${values.startPeriod.split("-")[1]}`,
         name: uploadedInvoiceBlob1.name,
      });
      const urlInvoice2 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `invoices/${clientInfo ? clientInfo._id : group.invoiceCompany._id}/${
            values.startPeriod.split("-")[0]
         }/${values.startPeriod.split("-")[1]}`,
         name: uploadedInvoiceBlob2.name,
      });
      await uploadFileToS3(urlInvoice1.data, uploadedInvoiceBlob1);
      await uploadFileToS3(urlInvoice2.data, uploadedInvoiceBlob2);
   };

   const handleSubmit = async (values) => {
      setIsLoading(true);
      if (!uploadedInvoiceBlob1 || !uploadedInvoiceBlob2) {
         showSnackBar("Selecciona los archivos correspondientes", true);
         setIsLoading(false);
         return;
      }
      if (values.startPeriod >= values.endPeriod) {
         showSnackBar("La fecha de inicio no puede ser mayor o igual a la fecha de cierre", true);
         setIsLoading(false);
         return;
      }
      try {
         await UseCreateCompanyInvoice(clientInfo ? clientInfo._id : group.invoiceCompany._id, {
            name: values.name,
            startPeriod: values.startPeriod,
            endPeriod: values.endPeriod,
            file1: uploadedInvoiceBlob1.name,
            file2: uploadedInvoiceBlob2.name,
         });

         await handleUploadFiles(values);
         setIsLoading(false);
         showSnackBar("Factura registrada con exito", false);
         await UseInvoicesByCompanyId(clientInfo ? clientInfo._id : group.invoiceCompany._id);
         props.setRefetch(true);
         await UseLastCompanyInvoice(clientInfo ? clientInfo._id : group.invoiceCompany._id);
         values.name = "";
         values.startPeriod = "";
         values.endPeriod = "";
         setUploadedInvoiceBlob1(null);
         setUploadedInvoiceBlob2(null);
         setSeed(Math.random());
      } catch (error) {
         setIsLoading(false);
         showSnackBar("Error al registrar la factura", true);
      }
   };
   return (
      <Box pt={2} minHeight={"100%"}>
         <Accordion>
            <AccordionSummary
               sx={{ width: "100%", height: "70px" }}
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography fontWeight={800}>Subir facturas</Typography>
            </AccordionSummary>
            <Formik
               initialValues={{ name: "", startPeriod: "", endPeriod: "" }}
               onSubmit={async (values) => {
                  try {
                     await handleSubmit(values);
                  } catch (error) {
                     console.log(error);
                  }
               }}
               validationSchema={uploadInvoice}
            >
               <Form>
                  <AccordionDetails key={seed}>
                     <Box display={"flex"} alignItems={"baseline"} justifyContent={"space-between"} mx={11}>
                        <InputTextField
                           id="name"
                           name="name"
                           label="Nombre de la factura"
                           type="text"
                           size="small"
                           sx={{ width: "250px" }}
                        />

                        <Box display={"flex"} columnGap={1} alignItems={"center"}>
                           <Typography>Fecha de inicio</Typography>
                           <InputTextField size="small" name="startPeriod" id="startPeriod" type="date" />
                        </Box>
                        <Box display={"flex"} columnGap={1} alignItems={"center"}>
                           <Typography>Fecha de cierre</Typography>
                           <InputTextField size="small" name="endPeriod" id="endPeriod" type="date" />
                        </Box>
                     </Box>
                     <Box display="flex" alignItems="center" minHeight={"100%"} mt={4} justifyContent={"center"}>
                        <CustomInputFiles
                           file1={uploadedInvoiceBlob1}
                           setFile1={setUploadedInvoiceBlob1}
                           file2={uploadedInvoiceBlob2}
                           setFile2={setUploadedInvoiceBlob2}
                        />
                     </Box>
                     <Box display={"flex"} justifyContent={"flex-end"} mx={11}>
                        <LoadingButton label={"Guardar"} isLoading={isLoading} />
                     </Box>
                  </AccordionDetails>
               </Form>
            </Formik>
         </Accordion>
      </Box>
   );
};
